import { FormattedStreamValue } from '@squaredup/data-streams';
import Tooltip from 'components/tooltip/Tooltip';
import { getLinkInfo } from 'lib/getLinkInfo';
import { toString } from 'lodash';
import { NavLink } from 'react-router-dom';

export const URLCell: React.FC<FormattedStreamValue> = ({ value, raw, formatted }) => {
    const rawString = toString(raw);
    const linkInfo = getLinkInfo(toString(value));

    return (
        <Tooltip disabled={false} className='truncate text-textLink' title={rawString}>
            {!linkInfo.isExternal && !linkInfo.isOpenAccessUrl ? (
                <NavLink
                    to={linkInfo.relativeURL}
                    className='inline-block w-full h-full underline'
                    onClick={(e) => e.stopPropagation()}
                >
                    {formatted}
                </NavLink>
            ) : (
                <a
                    className='inline-block w-full h-full underline text-textLink'
                    target='_blank'
                    rel='noopener noreferrer'
                    href={value as string}
                    onClick={(e) => e.stopPropagation()}
                >
                    {formatted}
                </a>
            )}
        </Tooltip>
    );
};
