import { faBell, faBellSlash, faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertingRuleChannel, WorkspaceMonitorSelector } from '@squaredup/monitoring';
import { ColumnDef } from '@tanstack/react-table';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { FC } from 'react';
import { AlertingRuleWithKey, DashboardMonitors } from './Monitoring';

interface Channel {
    id: string;
    name: string;
    icon: JSX.Element;
    tooltip: string;
    enabled: boolean;
}

interface NotificationTableProps {
    monitors: DashboardMonitors[];
    rules: AlertingRuleWithKey[];
    destinations: Channel[];
    onEdit: (rule: AlertingRuleWithKey) => void;
    onDelete: (ruleId: string) => void;
}

interface ProjectedRow {
    key: string;
    status: string;
    trigger: string;
    destinations: string;
    includePreviewImage: boolean;
    rule: AlertingRuleWithKey;
    channels: AlertingRuleChannel[];
}

const NotificationRulesApplicationTable = ApplicationTable<ProjectedRow, string | boolean>();

export const getTriggerText = (ruleMonitors: WorkspaceMonitorSelector, monitors: DashboardMonitors[]): string => {
    if (ruleMonitors.rollupHealth) {
        return 'This workspace state only';
    }

    if (ruleMonitors.includeAllTiles) {
        return 'All monitors in this workspace';
    }

    const tileIds = Object.keys(ruleMonitors.dashboards ?? {}).reduce((acc: string[], dashboardId) => {
        const ruleDashboard = ruleMonitors.dashboards?.[dashboardId] ?? {};
        const dashboard = monitors.find((d) => d.dashId === dashboardId);

        // Ignore dashboards that no longer exist
        if (!dashboard) {
            return acc;
        }

        // Filter out tiles that no longer exist, or monitors that have been overridden
        const ids = Object.keys(ruleDashboard.tiles ?? {}).filter(
            (tileId) =>
                ruleDashboard.tiles?.[tileId].include && dashboard.monitoredTiles.find((m) => m.tileId === tileId)
        );

        return acc.concat(ids);
    }, []);

    const count = tileIds.length;

    return count === 0 ? 'No monitors selected' : `${count} monitor${count === 1 ? '' : 's'} in this workspace`;
};

export const NotificationRulesTable: FC<NotificationTableProps> = ({
    monitors,
    rules,
    destinations,
    onEdit,
    onDelete
}) => {
    const noDestinationsMessage = 'No destination selected';
    const destinationsById = destinations.reduce((acc, d) => acc.set(d.id, d), new Map<string, Channel>());
    const projectedRows: ProjectedRow[] = rules.map((rule) => {
        const channels = rule.channels.filter((c) => destinationsById.has(c.id));

        return {
            key: rule.key,
            channels,
            status: rule.channels.some((c) => destinationsById.get(c.id)?.enabled) ? 'Active' : 'Disabled',
            trigger: getTriggerText(rule.conditions.monitors, monitors),
            destinations:
                channels.length > 0
                    ? channels.map((c) => destinationsById.get(c.id)?.name).join(' ')
                    : noDestinationsMessage,
            includePreviewImage: Boolean(rule.channels.some((c) => c.includePreviewImage)),
            rule: rule
        };
    });

    const columns: ColumnDef<ProjectedRow>[] = [
        {
            accessorKey: 'status',
            id: 'status',
            header: 'Status',
            cell: ({ row }) => {
                const status = row.original.status;
                return (
                    <span>
                        <FontAwesomeIcon
                            icon={status === 'Active' ? faBell : faBellSlash}
                            fixedWidth
                            className='mr-3'
                        />
                        {status}
                    </span>
                );
            },
            size: 150
        },
        {
            accessorKey: 'trigger',
            id: 'trigger',
            header: 'Trigger',
            cell: ({ row }) => {
                const trigger = row.original.trigger;
                return <span>{trigger}</span>;
            },
            size: 400
        },
        {
            accessorKey: 'destinations',
            id: 'destination',
            header: 'Destination',
            cell: ({ row }) => {
                return row.original.channels.length > 0 ? (
                    <div>
                        {row.original.channels.map((c) => (
                            <div key={c.id} className='flex items-center'>
                                {destinationsById.get(c.id)?.icon}
                                <span className='ml-2'>{destinationsById.get(c.id)?.name}</span>
                            </div>
                        ))}
                    </div>
                ) : (
                    <span>{noDestinationsMessage}</span>
                );
            },
            size: 400
        },
        {
            accessorKey: 'includePreviewImage',
            id: 'tilePreview',
            header: 'Tile Preview',
            cell: ({ row }) => {
                const tilePreview = row.original.includePreviewImage;
                return <span>{tilePreview === true ? 'On' : ''}</span>;
            },
            size: 200
        }
    ];

    const actions = [
        {
            action: (row: ProjectedRow) => onEdit(row.rule),
            icon: faPencil,
            dataTestId: 'editNotificationRule',
            tooltip: 'Edit notification rule'
        },
        {
            action: (row: ProjectedRow) => onDelete(row.key),
            icon: faTrash,
            dataTestId: 'deleteNotificationRule',
            tooltip: 'Delete notification rule'
        }
    ];

    return (
        <NotificationRulesApplicationTable
            config={{
                actions,
                noDataMessage: 'There are no notification rules configured.'
            }}
            columns={columns}
            data={projectedRows}
        />
    );
};
