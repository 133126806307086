import { HealthState, healthStates } from '@squaredup/monitoring';
import DropdownMenu from 'components/dropdownMenu';
import { ComponentProps, useState } from 'react';

export function toggleArray<T extends string | HealthState>(arr: T[], value: T): T[] {
    if (arr.includes(value)) {
        return arr.filter((v) => v !== value);
    }
    return [...arr, value];
}

export interface StateFilterProps {
    states?: HealthState[];
    menuProps?: Omit<ComponentProps<typeof DropdownMenu.Menu>, 'children'>;
    setStates: (states: HealthState[]) => void;
}

export default function StateFilter({ states = [], setStates, menuProps }: StateFilterProps) {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    return (
        <DropdownMenu onOpenChange={setIsDropdownOpened}>
            <DropdownMenu.Button active={states.length > 0} className='capitalize whitespace-nowrap' dropdownOpen={isDropdownOpened} showChevron useFilterStyle>
                State
                {states.length > 0 && (
                    <span className='px-2 ml-2 rounded-full bg-textDisabled' aria-label='numFiltersApplied'>{states.length}</span>
                )}
            </DropdownMenu.Button>
            <DropdownMenu.Menu align='start' {...menuProps}>
                <DropdownMenu.Group>
                    {healthStates.map((state) => (
                        <DropdownMenu.CheckboxItem
                            key={state}
                            className='capitalize'
                            checked={states.includes(state)}
                            onCheckedChange={() => setStates(toggleArray(states, state))}
                        >
                            {state}
                        </DropdownMenu.CheckboxItem>
                    ))}
                </DropdownMenu.Group>
                <DropdownMenu.Separator />
                <DropdownMenu.SecondaryItem disabled={states.length === 0} onSelect={() => setStates([])}>
                    Clear filter
                </DropdownMenu.SecondaryItem>
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
}
