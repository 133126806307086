import { cn } from '@/lib/cn';
import { stateStrings } from '@squaredup/monitoring';
import { useNetworkBackground } from 'components/map/context/NetworkMapStoreContext';
import { PinnableNodeData } from 'components/map/data/types';
import { useStoreHealthStateNode } from 'components/map/hooks/useStoreHealthStateNode';
import { TruncatedText } from 'components/TruncatedText';
import { mapBackgroundColors, mapFillColors } from 'dashboard-engine/visualisations/Network/utils/mapColors';
import { useWorkspaceHealthStates } from 'queries/hooks/useWorkspaceHealthStates';
import { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { useHover } from 'ui/hooks/useHover';
import { NodeToolbar } from '../../nodeToolbar/NodeToolbar';
import { WORKSPACE_NODE_SIZE } from '../WorkspaceNode';
import { WorkspaceAvatar } from './common/Avatar';

const healthStateStyles = {
    [stateStrings.error]: 'stroke-statusErrorPrimary',
    [stateStrings.warning]: 'stroke-statusWarningPrimary',
    [stateStrings.success]: 'stroke-statusHealthyPrimary',
    [stateStrings.unknown]: 'stroke-statusUnknownPrimary'
};

interface WorkspaceNodeInternalProps extends PinnableNodeData {
    id: string;
}

export const WorkspaceNodeInternal: React.FC<WorkspaceNodeInternalProps> = memo(
    ({ id, label, pinned, expanded, workspaceId, hiddenConnections, fixedPosition }) => {
        const background = useNetworkBackground();
        const { isHovered, ...hoverProps } = useHover();

        const { data: workspaceHealthStates = [] } = useWorkspaceHealthStates();

        const healthState = workspaceHealthStates.find(
            ({ id: workspaceHealthStateId }) => workspaceId === workspaceHealthStateId
        )?.state;

        useStoreHealthStateNode(id, healthState);

        return (
            <div
                className={cn(
                    'inline-flex items-center justify-center relative rounded-full',
                    healthState && mapBackgroundColors[background]
                )}
                style={{ width: WORKSPACE_NODE_SIZE, height: WORKSPACE_NODE_SIZE }}
                {...hoverProps}
            >
                <NodeToolbar
                    id={id}
                    isVisible={isHovered}
                    isPinned={pinned}
                    isFixed={fixedPosition}
                    isExpanded={expanded}
                    label={label}
                    hiddenConnections={hiddenConnections}
                    url={`/workspace/${workspaceId}`}
                />

                <svg viewBox='0 0 100 100' className={cn('absolute inset-0 w-full', mapFillColors[background])}>
                    <circle
                        cx='50%'
                        cy='50%'
                        r='40'
                        vectorEffect='non-scaling-stroke'
                        strokeWidth={5}
                        className={cn(
                            'stroke-filterActive',
                            mapFillColors[background],
                            healthState && healthState !== stateStrings.unknown && healthStateStyles[healthState]
                        )}
                        {...(!pinned && { strokeDasharray: '2 2' })}
                    />
                </svg>

                <WorkspaceAvatar workspaceId={workspaceId} label={label} />

                <Handle
                    type='target'
                    position={Position.Top}
                    className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
                />
                <Handle
                    type='source'
                    position={Position.Bottom}
                    className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
                />

                {label && (
                    <>
                        <span className='sr-only'>{label}</span>
                        <div
                            className={cn(
                                'text-primary rounded-sm text-[8px] font-bold px-1 py-0.5 leading-tight absolute top-[calc(100%+4px)] max-w-[160px] left-1/2 -translate-x-1/2 text-center',
                                mapBackgroundColors[background]
                            )}
                        >
                            <TruncatedText title={label}>{label}</TruncatedText>
                        </div>
                    </>
                )}
            </div>
        );
    }
);
