import { useAccordionControls } from '@/components/Accordion';
import { Divider } from '@/components/Divider';
import * as Accordion from '@radix-ui/react-accordion';
import Button from 'components/button/Button';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import { visualisationOptionsRepo } from 'dashboard-engine/repositories/visualisationsRepo';
import { VisualisationOptionAction } from 'dashboard-engine/types/Visualisation';
import { VisualisationConfigAccordion } from 'dashboard-engine/visualisations/VisualisationConfigAccordion';
import stringify from 'fast-json-stable-stringify';
import { useCallback, useMemo, useState } from 'react';
import { useTileEditorContext } from '../contexts/TileEditorContext';
import { useDatasetContext } from '../contexts/DatasetContext';

export const DataStreamTileEditorVisualizationConfigPanel = () => {
    const { tileConfig, setTileConfig } = useTileEditorContext();
    const { data } = useDataStreamConfig(tileConfig, { keepPreviousData: true });
    const { setActivePreviewTab } = useDatasetContext();

    const columns = data.metadata.columns;
    const visualisationType = tileConfig.visualisation?.type;
    const visualisationOptions = visualisationOptionsRepo.get(visualisationType || '');

    const {
        initialPanels,
        dataMappingComponent: DataMappingOptions,
        configurationComponent: ConfigurationOptions,
        getDefaultConfig
    } = visualisationOptions;

    const visualizationConfig = 
        tileConfig?.visualisation?.config?.[visualisationType!] || getDefaultConfig?.(data)|| {};

    const currentVisualizationConfig = visualisationOptions.validate(columns, visualizationConfig);
    const originalVisualizationConfig = useState(currentVisualizationConfig);

    // Counter allows us to remount the options component when the config changes,
    // this is needed to reset the state of the options
    const [counter, setCounter] = useState(0);

    const handleReset = () => {
        // Forcibly update the `Options` component to respect the reset behaviour
        // In future we may want reset behaviour to be handled by the individual advanced options
        setTileConfig((currentConfig) => ({
            ...currentConfig,
            visualisation: {
                ...currentConfig.visualisation,
                config: {
                    ...currentConfig.visualisation?.config,
                    [visualisationType as string]: originalVisualizationConfig
                }
            }
        }));
        setCounter((curr) => curr + 1);
    };

    const handleChange = useCallback(
        (action: VisualisationOptionAction) => {
            const newData =
                visualisationOptions.handlers[action.action]?.(currentVisualizationConfig, action.data) || {};

            const newVisualizationConfig = visualisationOptions.validate(columns, newData);

            if (stringify(currentVisualizationConfig) !== stringify(newVisualizationConfig)) {
                setTileConfig((existingConfig) => ({
                    ...existingConfig,
                    visualisation: {
                        ...existingConfig.visualisation,
                        config: {
                            ...existingConfig.visualisation?.config,
                            [visualisationType as string]: newVisualizationConfig
                        }
                    }
                }));

                setActivePreviewTab(0);
            }
        },
        [
            visualisationOptions, 
            currentVisualizationConfig, 
            columns, 
            visualisationType, 
            setTileConfig, 
            setActivePreviewTab
        ]
    );

    const initialPanelState = useMemo(() => 
        initialPanels?.(visualizationConfig) ?? [{ name: 'mapping', isOpen: true }],
        [initialPanels] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const accordionControls = useAccordionControls({
        type: 'multiple',
        initialPanelState
    });

    return (
        <div className='flex flex-col w-full outline-none focus:outline-none'>
            <Accordion.Root 
                className='flex flex-col items-center w-full'
                data-testid='visualisationConfigAccordion'
                {...accordionControls} 
            >
                {DataMappingOptions && (
                    <VisualisationConfigAccordion
                        value='mapping'
                        label='Mapping'
                        accordionControls={accordionControls}
                    >
                        <DataMappingOptions
                            key={`data-mapping-${counter}`}
                            columns={columns}
                            config={currentVisualizationConfig}
                            onChange={handleChange}
                            tileData={data}
                        />
                    </VisualisationConfigAccordion>
                )}
                
                {ConfigurationOptions && (
                    <>
                        {DataMappingOptions && <Divider />}
                        <ConfigurationOptions
                            key={`config-${counter}`}
                            columns={columns}
                            config={currentVisualizationConfig}
                            onChange={handleChange}
                            tileData={data}
                            accordionControls={accordionControls}
                        />
                    </>
                )}
            </Accordion.Root>

            <div className='flex justify-end px-5 mt-4 space-x-3'>
                <Button onClick={handleReset} variant='secondary'>
                    Reset
                </Button>
            </div>
        </div>
    );
};
