import { DashboardMonitorSelector } from '@squaredup/monitoring';
import Input from 'components/forms/input/Input';
import { FormToggle } from 'components/forms/toggle/FormToggle';
import { TruncatedTextWithPrefix } from 'components/TruncatedTextWithPrefix';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AddEditNotificationRulePanelProps } from '../AddEditNotificationRuleModal';
import { DashboardMonitors } from '../Monitoring';

type triggerOn = 'workspaceState' | 'allMonitors' | 'selectedMonitors';

interface triggerSettings {
    triggerType: triggerOn;
    monitors: Record<string, boolean>;
}

export interface NotificationRuleTriggerProps extends AddEditNotificationRulePanelProps {
    dashboards: DashboardMonitors[];
}

interface DashboardMonitorTogglesProps {
    dashId: string;
    name: string;
    enabled?: boolean;
    tiles: {
        tileId: string;
        tileDisplayName: string | undefined;
    }[];
    folderPath?: string[];
}

export const DashboardMonitorToggles: React.FC<DashboardMonitorTogglesProps> = ({
    dashId,
    name,
    tiles,
    enabled = true,
    folderPath
}) => {
    // Suppress dashboards without tiles until the UX supports dashboard state rollup and/or
    // automatically including all contained tiles.
    if (tiles.length < 1) {
        return <></>;
    }

    return (
        <div className='mt-6'>
            <TruncatedTextWithPrefix
                prefix={folderPath?.join(' / ')}
                text={name}
                className='mb-2'
                prefixClasses='opacity-75 text-textSecondary'
                textClasses='text-textSecondary'
            />
            {tiles.map((tile) => (
                <div key={tile.tileId} className='border-b last:border-b-0 border-b-dividerPrimary'>
                    <FormToggle
                        name={`monitors.${dashId}//${tile.tileId}`}
                        disabled={!enabled}
                        label={tile.tileDisplayName ?? 'Unnamed Monitor'}
                    />
                </div>
            ))}
        </div>
    );
};

export const NotificationRuleTrigger: React.FC<NotificationRuleTriggerProps> = ({
    dashboards,
    rule,
    setRule,
    setActivePanel,
    children
}) => {
    const {
        conditions: { monitors }
    } = rule;

    const defaultValues = useMemo<triggerSettings>(() => {
        // filter list to only those dashboards still present
        const dashboardIds = Object.keys(monitors?.dashboards ?? {}).filter((key) =>
            dashboards.some((dashboard) => dashboard.dashId === key)
        );

        // defaults for all dashboards/monitors added since originally last configured
        const allMonitors = dashboards.reduce((defaults: Record<string, boolean>, dashboard) => {
            for (const tile of dashboard.monitoredTiles) {
                // Once the UX supports the additional trigger options, this should
                // instead follow the dashboard.includeAllTiles setting for the dashboard
                defaults[`${dashboard.dashId}//${tile.tileId}`] = false;
            }

            return defaults;
        }, {});

        return {
            triggerType: monitors.rollupHealth
                ? 'workspaceState'
                : monitors.includeAllTiles
                ? 'allMonitors'
                : 'selectedMonitors',
            monitors: dashboardIds.reduce((acc: Record<string, boolean>, dashboardId) => {
                const dashboardSettings = monitors.dashboards?.[dashboardId];
                const dashboardTiles =
                    dashboards.find((dashboard) => dashboard.dashId === dashboardId)?.monitoredTiles ?? [];

                // filter this list to only include tiles still present
                const tiles = Object.keys(dashboardSettings?.tiles ?? {}).filter((key) =>
                    dashboardTiles.some((tile) => tile.tileId === key)
                );

                for (const tileId of tiles) {
                    if (dashboardSettings?.tiles?.[tileId]?.include) {
                        acc[`${dashboardId}//${tileId}`] = true;
                    }
                }

                return acc;
            }, allMonitors)
        };
    }, [monitors, dashboards]);

    const persistTrigger = (settings: triggerSettings) => {
        const newRule = {
            ...rule,
            conditions: {
                monitors: {
                    rollupHealth: settings.triggerType === 'workspaceState',
                    includeAllTiles: settings.triggerType === 'allMonitors',
                    dashboardRollupHealth: false, // not available in the UX for now
                    dashboards:
                        settings.triggerType === 'selectedMonitors'
                            ? Object.keys(settings.monitors).reduce(
                                  (acc: Record<string, DashboardMonitorSelector>, key) => {
                                      // Assume that the toggle in the off position means no monitoring, rather than
                                      // overriding 'off'. This would be fine for now, but could cause problems
                                      // in the future when we add override UX support
                                      if (settings.monitors[key]) {
                                          const [dashboardId, tileId] = key.split('//');

                                          acc[dashboardId] = acc[dashboardId] ?? {};
                                          const dashboard = acc[dashboardId];

                                          dashboard.tiles = dashboard.tiles ?? {};
                                          dashboard.tiles[tileId] = { include: true };
                                      }

                                      return acc;
                                  },
                                  {}
                              )
                            : undefined
                }
            }
        };
        setRule(newRule);
        setActivePanel('summary');
    };

    const triggerOptions: triggerOn[] = ['workspaceState', 'allMonitors', 'selectedMonitors'];
    const triggerOptionLabels: Record<triggerOn, string> = {
        workspaceState: 'This workspace state only',
        allMonitors: 'All monitors in this workspace',
        selectedMonitors: 'Selected monitors in this workspace'
    };

    const formProps = useForm({ defaultValues: defaultValues });
    const { handleSubmit, watch } = formProps;

    const chosenTriggerType = watch('triggerType');

    return (
        <FormProvider {...formProps}>
            <form
                className='flex flex-col overflow-hidden'
                data-testid='rule-trigger-panel'
                onSubmit={handleSubmit(persistTrigger)}
            >
                <div className='flex flex-col overflow-hidden px-7'>
                    <p className='mb-2 text-textSecondary'>
                        Notify on any state change (error, warning, success, unknown) for:
                    </p>
                    <div className='flex-shrink-0 overflow-hidden rounded-input'>
                        {/* Radio controlling trigger condition(s) */}
                        {triggerOptions.map((o) => (
                            <div
                                key={o}
                                className='border-b py-radioWrapper px-md leading-input last:border-b-0 border-b-dividerPrimary bg-componentBackgroundSecondary'
                            >
                                <Input type='radio' name='triggerType' label={triggerOptionLabels[o]} value={o} />
                            </div>
                        ))}
                    </div>
                    {chosenTriggerType !== 'workspaceState' && (
                        <>
                            <hr className='mt-6 border-dividerPrimary' />
                            {/* list of dashboards and their monitors */}
                            <div className='table-scroll-overflow'>
                                {dashboards.map((dashboard) => (
                                    <DashboardMonitorToggles
                                        dashId={dashboard.dashId}
                                        key={dashboard.dashId}
                                        folderPath={dashboard.folderPath}
                                        name={dashboard.displayName}
                                        enabled={chosenTriggerType === 'selectedMonitors'}
                                        tiles={dashboard.monitoredTiles}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </div>
                {/* Render buttons */}
                {children}
            </form>
        </FormProvider>
    );
};
