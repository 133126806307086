
import { Row, SortDirection } from '@tanstack/react-table';
import type { ImportStatus } from 'dynamo-wrapper';
import { ImportStatusType } from 'pages/datasources/components/ImportInfo';
import { HealthState } from 'dashboard-engine/types/data/StateData';

export const sortTimeAgo = <T,>(rowA: Row<T>, rowB: Row<T>, columnId: string) => {
    const a: Date | undefined = rowA.getValue(columnId);
    const b: Date | undefined = rowB.getValue(columnId);
    if (a && b) {
        return a > b ? -1 : a < b ? 1 : 0;
    } else if (a) {
        return -1;
    } else if (b) {
        return 1;
    } else {
        return 0;
    }    
};

export const sortInTime = <T,>(rowA: Row<T>, rowB: Row<T>, columnId: string) => {
    const a: Date | undefined = rowA.getValue(columnId);
    const b: Date | undefined = rowB.getValue(columnId);
    if (a && b) {
        return a > b ? 1 : a < b ? -1 : 0;
    } else if (a) {
        return 1;
    } else if (b) {
        return 1;
    } else {
        return 0;
    }
};

export const sortStatus = 
<T,>(rowA: Row<T>, rowB: Row<T>, columnId: string, getIsSorted: () => SortDirection | false): number => {
    let desc = false;
    if (getIsSorted !== undefined) {
        desc = getIsSorted() === 'desc';
    }
    const statusValues = {
        success: desc ? 2 : 1,
        warning: desc ? 3 : 2,
        error: desc ? 4 : 3,
        unknown: desc ? 1 : 4 // Unknown should always be last
    };

    const a: HealthState = rowA.getValue(columnId);
    const b: HealthState = rowB.getValue(columnId);

    const aValue = statusValues[a];
    const bValue = statusValues[b];

    return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
};

export const sortImportStatus = 
<T,>(rowA: Row<T>, rowB: Row<T>, columnId: string, getIsSorted: () => SortDirection | false): number => {
    let desc = false;
    if (getIsSorted !== undefined) {
        desc = getIsSorted() === 'desc';
    }    
    const statusValues = {
        notRun: desc ? 5 : 1, // Not run and Started should always be first
        started: desc ? 5 : 1,
        waitingForData: desc ? 4 : 2, // Followed by Waiting for data and Running
        running: desc ? 4 : 2,
        failed: desc ? 1 : 3, // Followed by everything else sorted according to sort direction
        warnings: desc ? 2 : 4,
        succeeded: desc ? 3 : 5
    };

    const getStatus = (importStatus: ImportStatus) =>
        (importStatus.status === 'succeeded' && (importStatus.totalWarningCount ? 'warnings' : 'succeeded')) ||
        importStatus.status;

    const a: ImportStatusType = getStatus(rowA.getValue(columnId));
    const b: ImportStatusType = getStatus(rowB.getValue(columnId));

    const aValue = statusValues[a];
    const bValue = statusValues[b];

    return (aValue > bValue) ? 1 : ((aValue < bValue) ? -1 : 0);
};