import Tooltip from 'components/tooltip/Tooltip';

interface DrilldownTabLabelProps {
    dashboardName: string;
    workspaceName: string;
    folderPath: string[];
}

export const DrilldownTabLabel: React.FC<DrilldownTabLabelProps> = ({ dashboardName, workspaceName, folderPath }) => {
    const path = [workspaceName, ...folderPath, dashboardName];
    const tooltipTitle = path.join(' / ');

    return (
        <Tooltip title={tooltipTitle} variant='light'>
            <span className='block max-w-[300px] truncate'>{dashboardName}</span>
        </Tooltip>
    );
};
