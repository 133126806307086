import { cn } from '@/lib/cn';
import { faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stateStrings } from '@squaredup/monitoring';
import { useNetworkBackground, useNetworkMapStoreContext } from 'components/map/context/NetworkMapStoreContext';
import { healthStateTextColors } from 'constants/state';
import { mapBackgroundColors } from 'dashboard-engine/visualisations/Network/utils/mapColors';
import { EdgeLabelRenderer, EdgeProps, getStraightPath } from 'reactflow';

export const FLOATING_EDGE = 'floatingEdge';

const healthStateStyles = {
    [stateStrings.error]: 'stroke-statusErrorPrimary',
    [stateStrings.warning]: 'stroke-statusWarningPrimary',
    [stateStrings.success]: 'stroke-statusHealthyPrimary',
    [stateStrings.unknown]: 'stroke-dividerSecondary'
};

export const FloatingEdge = ({ id, source, target, label, style }: EdgeProps) => {
    const background = useNetworkBackground();

    const targetNode = useNetworkMapStoreContext((state) => state.nodes.find(({ id: nodeId }) => nodeId === target));
    const sourceNode = useNetworkMapStoreContext((state) => state.nodes.find(({ id: nodeId }) => nodeId === source));

    if (
        sourceNode?.position?.x === undefined ||
        targetNode?.position?.x === undefined ||
        !sourceNode?.width ||
        !targetNode?.width ||
        sourceNode?.hidden ||
        targetNode?.hidden
    ) {
        return null;
    }

    const targetHealthState = targetNode?.data?.healthState ?? targetNode?.data?.initialState ?? stateStrings.unknown;

    const {
        position: { x: sourceX, y: sourceY },
        width: sourceWidth,
        height: sourceHeight
    } = sourceNode;
    const {
        position: { x: targetX, y: targetY },
        width: targetWidth,
        height: targetHeight
    } = targetNode;

    const sourceCenter = {
        x: sourceX + sourceWidth! / 2,
        y: sourceY + sourceHeight! / 2
    };

    const targetCenter = {
        x: targetX + targetWidth! / 2,
        y: targetY + targetHeight! / 2
    };

    let dx = targetCenter.x - sourceCenter.x;
    let dy = targetCenter.y - sourceCenter.y;
    const edgeLength = Math.sqrt(dx ** 2 + dy ** 2);

    const targetDistanceFromTheEndRatio = (edgeLength - targetWidth! / 2) / edgeLength;
    let targetEdgeX = sourceCenter.x + dx * targetDistanceFromTheEndRatio;
    let targetEdgeY = sourceCenter.y + dy * targetDistanceFromTheEndRatio;

    const sourceDistanceFromTheEndRatio = sourceWidth! / 2 / edgeLength;
    let sourceEdgeX = sourceCenter.x + dx * sourceDistanceFromTheEndRatio;
    let sourceEdgeY = sourceCenter.y + dy * sourceDistanceFromTheEndRatio;

    let [path, labelX, labelY] = getStraightPath({
        sourceX: sourceEdgeX,
        sourceY: sourceEdgeY,
        targetX: targetEdgeX,
        targetY: targetEdgeY
    });

    const labelAngle = Math.abs(
        180 - Math.atan2(targetCenter.x - sourceCenter.x, targetCenter.y - sourceCenter.y) * (180 / Math.PI)
    );
    const reversedLabel = labelAngle <= 180;

    return (
        <>
            <path
                id={id}
                d={path}
                markerEnd={`url(#arrow-head-${background}-${targetHealthState}`}
                markerWidth={8}
                fill='none'
                strokeWidth={2}
                className={cn(
                    'text-dividerSecondary transition-[stroke] duration-700 stroke-current',
                    healthStateStyles[targetHealthState]
                )}
                strokeDasharray='4 3'
                {...(style && { style })}
            />

            {label && (
                <EdgeLabelRenderer>
                    <div
                        className={cn(
                            'absolute text-xs origin-center text-[7px] font-bold',
                            targetHealthState ? healthStateTextColors[targetHealthState] : 'text-statusUnknownPrimary'
                        )}
                        style={{ transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)` }}
                    >
                        <span
                            className={cn(
                                'inline-flex space-x-1 items-center align-middle px-1 leading-none origin-center',
                                mapBackgroundColors[background]
                            )}
                            style={{ transform: `rotate(${labelAngle - (reversedLabel ? 90 : -90)}deg)` }}
                        >
                            {!reversedLabel && <FontAwesomeIcon icon={faCaretLeft} />}
                            <span className='h-[1em] leading-none'>{label}</span>
                            {reversedLabel && <FontAwesomeIcon icon={faCaretRight} />}
                        </span>
                    </div>
                </EdgeLabelRenderer>
            )}
        </>
    );
};
