import type { DashboardTileContent } from '@squaredup/dashboards';
import type { OOBInfo } from 'dynamo-wrapper';
import trackEvent from 'lib/analytics';
import { mean } from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';

type MetricsTileState = Record<
    string,
    {
        startTime?: number;
        endTime?: number;
        success?: boolean;
    }
>;

type DashboardMetricsValue = {
    reportTileStart: (tileId: string, startTime: number) => void;
    reportTileEnd: (tileId: string, endTime: number, success: boolean) => void;
};

const DashboardMetricsContext = createContext<DashboardMetricsValue>({
    reportTileStart: () => undefined,
    reportTileEnd: () => undefined
});

export const useDashboardMetricsContext = () => useContext(DashboardMetricsContext);

interface DashboardMetricsProviderProps {
    dashboardContents: DashboardTileContent[];
    oobInfo?: OOBInfo;
}

export const DashboardMetricsProvider: React.FC<DashboardMetricsProviderProps> = ({
    dashboardContents,
    oobInfo,
    children
}) => {
    const [tiles, setTiles] = useState<MetricsTileState>({});

    const [hasSentEvent, setHasSentEvent] = useState(false);

    const dataStreamTileIds = dashboardContents.filter((c) => c.config._type === 'tile/data-stream').map((c) => c.i);
    const allTilesHaveReported =
        dataStreamTileIds.length > 0 &&
        dataStreamTileIds.every((id) => tiles[id]?.startTime != null && tiles[id]?.endTime != null);

    useEffect(() => {
        if (allTilesHaveReported && !hasSentEvent) {
            const success = dataStreamTileIds.filter((id) => tiles[id]?.success);
            const error = dataStreamTileIds.filter((id) => !tiles[id]?.success);
            const loadTimes = dataStreamTileIds.map((id) => (tiles[id]?.endTime ?? 0) - (tiles[id]?.startTime ?? 0));
            const averageLoadTime = Math.round(mean(loadTimes));
            const minLoadTime = Math.round(Math.min(...loadTimes));
            const maxLoadTime = Math.round(Math.max(...loadTimes));

            // Pendo property values must be strings or booleans
            trackEvent('Dashboard Loaded', {
                success: String(success.length),
                error: String(error.length),
                averageLoadTime: String(averageLoadTime),
                minLoadTime: String(minLoadTime),
                maxLoadTime: String(maxLoadTime),
                oobName: oobInfo?.dashboardName,
                oobPluginId: oobInfo?.pluginId,
                oobPluginVersion: oobInfo?.pluginVersion
            });
            setHasSentEvent(true);
        }
    }, [allTilesHaveReported, hasSentEvent, tiles, dataStreamTileIds, oobInfo, setHasSentEvent]);

    const reportTileStart = (tileId: string, startTime: number) => {
        setTiles((currentTiles) => {
            if (currentTiles[tileId]?.startTime != null) {
                return currentTiles;
            }
            return {
                ...currentTiles,
                [tileId]: {
                    ...(currentTiles[tileId] ?? {}),
                    startTime
                }
            };
        });
    };

    const reportTileEnd = (tileId: string, endTime: number, success: boolean) => {
        setTiles((currentTiles) => {
            if (currentTiles[tileId]?.endTime != null) {
                return currentTiles;
            }
            return {
                ...currentTiles,
                [tileId]: {
                    ...(currentTiles[tileId] ?? {}),
                    endTime,
                    success
                }
            };
        });
    };

    return (
        <DashboardMetricsContext.Provider value={{ reportTileStart, reportTileEnd }}>
            {children}
        </DashboardMetricsContext.Provider>
    );
};
