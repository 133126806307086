import { setupLibrary } from 'lib/fontawesome/fontawesome';
import { isOpenAccess } from 'lib/openAccessUtil';
import groupBy from 'object.groupby';
import LoginPage from 'pages/login/LoginPage';
import SelectTenant from 'pages/selectTenant/SelectTenant';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Routes } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import RequireAuth from 'services/Auth/RequireAuth';
import { RequireOpenAccessAuth } from 'services/Auth/RequireOpenAccessAuth';
import ErrorFallback from 'ui/errorHandling/ErrorFallback';
import OpenAccessWrapper from 'ui/wrappers/OpenAccessWrapper';
import Wrapper from 'ui/wrappers/Wrapper';
import { SignOutComplete } from './pages/login/SignOutComplete';

groupBy.shim();

export const iconLibrary = setupLibrary();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

function App() {
    return (
        <div className='w-full h-full overflow-hidden text-textPrimary'>
            <QueryClientProvider client={queryClient}>
                <Toaster
                    containerStyle={{
                        bottom: '4rem',
                        right: '-1.5rem'
                    }}
                />
                {isOpenAccess() && (
                    <Routes>
                        <Route
                            path='/*'
                            element={
                                <RequireOpenAccessAuth>
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        <OpenAccessWrapper />
                                    </ErrorBoundary>
                                </RequireOpenAccessAuth>
                            }
                        />
                    </Routes>
                )}
                {!isOpenAccess() && (
                    <Routes>
                        <Route path='/login/*' element={<LoginPage />} />
                        <Route path='/signoutComplete' element={<SignOutComplete />} />

                        <Route
                            path='/organization'
                            element={
                                <RequireAuth>
                                    <SelectTenant />
                                </RequireAuth>
                            }
                        />

                        <Route
                            path='/*'
                            element={
                                <RequireAuth>
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        <Wrapper />
                                    </ErrorBoundary>
                                </RequireAuth>
                            }
                        />
                    </Routes>
                )}
                <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />

                <ReactTooltip id='tooltip' opacity={1} clickable={true} role='tooltip' />
            </QueryClientProvider>
        </div>
    );
}

export default App;
