import { Toggle } from '@/components/forms/Toggle';
import { Serialised } from '@squaredup/ids';
import { AlertingImageTheme, AlertingRuleV2, ChannelTypeIds } from '@squaredup/monitoring';
import { FullWidthModalButton } from 'components/Modal';
import { ModalOptionsWithOriginal } from 'components/ModalOptions';
import { ToggleStyleWrapper } from 'components/ToggleStyleWrapper';
import Button from 'components/button/Button';
import { useEffect, useMemo } from 'react';
import { ChannelType } from 'services/NotificationChannelService';
import { AddEditNotificationRulePanelProps } from '../AddEditNotificationRuleModal';
import { DashboardMonitors } from '../Monitoring';
import { NotificationDestinationIcon } from '../NotificationDestinationIcon';
import { getTriggerText } from '../NotificationRulesTable';
import { isFeatureEnabled, sortFeaturesByStartingTier, Tier } from '@squaredup/tenants';
import { FeatureUnavailablePill } from 'components/plans/FeatureUnavailablePill';
import { Switch } from '@/components/Switch';
import { themeSwitchOptionsWithoutSystem } from 'lib/themeSwitchOptions';

export interface NotificationRuleSummaryProps extends AddEditNotificationRulePanelProps {
    channelTypes: Serialised<ChannelType>[];
    setChannelType: (t: Serialised<ChannelType>) => void;
    setIsValid: (isValid: boolean) => void;
    dashboards: DashboardMonitors[];
    tier: Tier | undefined;
}

// Fix the type of the ModalOptions generic component
const ModalOptionsChannelType = ModalOptionsWithOriginal<Serialised<ChannelType>>();

export const NotificationRuleSummary: React.FC<NotificationRuleSummaryProps> = ({
    dashboards,
    rule,
    setActivePanel,
    channels,
    channelTypes,
    setRule,
    setChannelType,
    children,
    setIsValid,
    tier
}) => {
    const isDestinationSelected = useMemo(() => {
        return rule.channels.length > 0;
    }, [rule.channels]);

    // Ensure email channel is first, custom is last, and the rest are alphabetical case-insensitive.
    const sortedChannelTypes = useMemo(
        () =>
            [...channelTypes].sort((a, b) => {
                const featureSortOrder = sortFeaturesByStartingTier(a.requiresFeature, b.requiresFeature);
                if (featureSortOrder !== 0) {
                    return featureSortOrder;
                }

                if (a.id === ChannelTypeIds.Email || b.id === ChannelTypeIds.Custom) {
                    return -1;
                }

                if (a.id === ChannelTypeIds.Custom || b.id === ChannelTypeIds.Email) {
                    return 1;
                }

                return a.displayName.localeCompare(b.displayName, undefined, { sensitivity: 'base' });
            }),
        [channelTypes]
    );

    // don't currently need to check trigger conditions, since settings are mandatory
    useEffect(() => {
        setIsValid(isDestinationSelected);
    }, [setIsValid, isDestinationSelected]);

    // Assuming at the moment that you can only select one destination, may support more in the future
    const firstDestination = isDestinationSelected ? channels.find((c) => c.id === rule.channels[0].id) : undefined;

    const firstDestinationType = firstDestination
        ? sortedChannelTypes.find((c) => c.id === firstDestination.channelTypeId)
        : undefined;

    if (
        firstDestinationType?.imagePreviewSupported &&
        rule.channels?.[0] &&
        rule.channels[0].includePreviewImage === undefined &&
        !rule.conditions.monitors.rollupHealth
    ) {
        // Default preview image to true if it's supported and it hasn't been enabled/disabled
        // explicitly (i.e. new rule)
        rule.channels[0].includePreviewImage = true;
        rule.channels[0].previewImageTheme = 'dark';
    }

    const onClickChannelType = (channelType: Serialised<ChannelType>) => {
        setChannelType(channelType);
        setActivePanel('destination');
    };

    return (
        <>
            <div className='flex flex-col px-8 overflow-hidden' data-testid='summary-panel'>
                {/* Trigger section */}
                <div className='mb-6'>
                    <p className='mb-2 text-textSecondary'>Trigger</p>
                    <FullWidthModalButton onClick={() => setActivePanel('triggers')} title='Trigger'>
                        {getTriggerText(rule.conditions.monitors, dashboards)}
                    </FullWidthModalButton>
                </div>

                {/* Change existing destination -OR- Select a new one*/}
                {isDestinationSelected && firstDestination ? (
                    <div className='mb-6'>
                        <div className='flex items-center mb-2 text-textSecondary'>
                            <span>Destination</span>
                            <span className='ml-auto'>
                                <Button
                                    type='button'
                                    variant='tertiary'
                                    onClick={() => setRule({ ...rule, channels: [] })}
                                >
                                    Change destination
                                </Button>
                            </span>
                        </div>

                        <div className='flex items-center w-full rounded-md ring-1 ring-inset ring-outlinePrimary py-input leading-input px-md bg-componentBackgroundSecondary'>
                            <NotificationDestinationIcon channelTypeId={firstDestination.channelTypeId} className='w-5 h-5'/>
                            <span className='ml-3 font-normal'>{firstDestination.displayName}</span>
                            {firstDestinationType?.requiresFeature && (
                                <FeatureUnavailablePill featureKey={firstDestinationType.requiresFeature} className='ml-3' />
                            )}
                        </div>
                    </div>
                ) : (
                    <div className='flex flex-col mb-6 overflow-hidden'>
                        <p className='mb-2 text-textSecondary'>Select an integration</p>
                        <div className='pb-12 table-scroll-overflow'>
                            <ModalOptionsChannelType
                                objects={sortedChannelTypes}
                                optionMapper={(ct) => ({
                                    original: ct,
                                    key: ct.id,
                                    label: ct.displayName,
                                    icon: (
                                        <NotificationDestinationIcon
                                            channelTypeId={ct.id}
                                            className='w-5 h-5'
                                        />
                                    ),
                                    subLabel: ct.requiresFeature 
                                        ? (<FeatureUnavailablePill featureKey={ct.requiresFeature} className='ml-3' />) 
                                        : undefined,
                                    disabled: ct.requiresFeature &&
                                        (!tier || !isFeatureEnabled(tier, ct.requiresFeature))
                                })}
                                onClick={(channelType) => onClickChannelType(channelType)}
                                includeRightArrow={true}
                            />
                        </div>
                    </div>
                )}
                {/* Options section */}
                {firstDestinationType?.imagePreviewSupported &&
                    !rule.conditions.monitors.rollupHealth && (
                        <div className='mb-6'>
                            <p className='mb-2 text-textSecondary'>Options</p>
                            <div className='pt-1 ml-1 flex justify-between'>
                                <ToggleStyleWrapper
                                    name='toggleIncludeImagePreview'
                                    label='Include an image of the tile in the notification'
                                    labelClasses='!text-base !font-normal'
                                    wrapperClassName='mr-3 pr-0 flex-grow'
                                >
                                    <div className='w-full mr-6' aria-label='Include image preview'>
                                        <Toggle
                                            checked={rule.channels?.[0]?.includePreviewImage ?? false} 
                                            onCheckedChange={() => setRule({ ...toggleIncludePreviewImage(rule) })}
                                        />
                                    </div>
                                </ToggleStyleWrapper>
                                <Switch
                                    value={rule.channels?.[0]?.includePreviewImage ? rule.channels?.[0]?.previewImageTheme ?? 'dark' : undefined}
                                    options={themeSwitchOptionsWithoutSystem}
                                    disabled={!rule.channels?.[0].includePreviewImage}
                                    onValueChange={(selectedTheme) => {
                                        if (selectedTheme) {
                                            setRule({
                                                ...setPreviewImageTheme(
                                                    rule,
                                                    selectedTheme as AlertingImageTheme
                                                )
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
            </div>
            {/* Render buttons */}
            {children}
        </>
    );
};

function toggleIncludePreviewImage(rule: AlertingRuleV2) {
    const includePreviewImage = rule.channels[0].includePreviewImage ?? false;
    rule.channels[0].includePreviewImage = !includePreviewImage;
    rule.channels[0].previewImageTheme = rule.channels[0].includePreviewImage ? 'dark' : undefined;
    return rule;
}

function setPreviewImageTheme(rule: AlertingRuleV2, theme: AlertingImageTheme) {
    const includePreviewImage = rule.channels[0].includePreviewImage ?? false;
    rule.channels[0].previewImageTheme = includePreviewImage ? theme : undefined;
    return rule;
}