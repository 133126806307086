import { animate } from 'framer-motion';
import useFitText from 'lib/useFitText';
import { useEffect, useState } from 'react';
import usePrefersReducedMotion from '../../../lib/usePrefersReducedMotion';
import { DonutCenterValueProps } from './Config';

function DonutCenterValue({ value, formatValue, className, style }: DonutCenterValueProps) {
    const [isFinished, setIsFinished] = useState(false);
    const [prevValue, setPrevValue] = useState(value);
    const preferedReducedMotion = usePrefersReducedMotion();

    const { fontSize, ref: counterRef } = useFitText({
        maxFontSize: 2000,
        onFinish: () => setIsFinished(true)
    });
    const [formattedValue, setFormattedValue] = useState<string>(formatValue(prevValue));

    useEffect(() => {
        if (prevValue === value) {
            return;
        }

        animate(prevValue ?? 0, value, {
            duration: preferedReducedMotion ? 0 : 0.3,
            onUpdate(currentValue) {
                setFormattedValue(formatValue(currentValue));
            },
            onComplete() {
                setPrevValue(value);
            }
        });
    }, [prevValue, value, formatValue]);

    return (
        <div ref={counterRef} style={{ ...style, fontSize, opacity: isFinished ? 1 : 0 }} className={className}>
            <p className='font-semibold whitespace-nowrap'>{formattedValue}</p>
        </div>
    );
}

export default DonutCenterValue;
