import Text from '@/components/Text';
import useSize from '@react-hook/size';
import { HealthState } from '@squaredup/monitoring';
import { useVirtualizer } from '@tanstack/react-virtual';
import Button from 'components/button';
import { FeatureUnavailablePill } from 'components/plans/FeatureUnavailablePill';
import type { KPI } from 'dynamo-wrapper';
import { Dictionary, upperFirst } from 'lodash';
import { useDatasourceConfigs } from 'queries/hooks/useDatasourceConfigs';
import { useWorkspaces } from 'queries/hooks/useWorkspaces';
import React, { useCallback, useEffect, useMemo } from 'react';
import { SortValue, StatusRequestType } from '../StatusOverview';
import { StatusBlock, type StatusBlockDataItem } from './StatusBlock';

export const calculateEstimateSize = ({
    type,
    groupedKPIs,
    showKPIs,
    data,
    showMonitorText,
    showFooter,
    showAddWorkspace
}: {
    type: StatusRequestType;
    groupedKPIs?: Dictionary<KPI[]>;
    showKPIs: boolean;
    data: (StatusBlockDataItem & { workspaceId: string })[];
    showMonitorText: boolean;
    showFooter: boolean;
    showAddWorkspace?: boolean;
}): number => {
    const baseHeight = 88;

    let kpiHeight = 0;
    if (type === 'space' && groupedKPIs && showKPIs) {
        // We need to get the max number of KPIs for a workspace in the current view
        const maxLength = Math.max(
            ...Object.entries(groupedKPIs)
                .filter(([spaceID]) => data.some(({ workspaceId }) => workspaceId === spaceID))
                .map(([_, kpis]) => kpis.length),
            0
        );
        // We only want to show up to 3 KPIs before we start to scroll
        kpiHeight = Math.min(maxLength, 3) * 70;
    }

    let monitorTextHeight = 64;
    if (type === 'space' && showMonitorText && Boolean(kpiHeight)) {
        monitorTextHeight = 22;
    } else if (type === 'space' && !showMonitorText) {
        monitorTextHeight = 0;
    }

    let footerHeight = 0;
    if (type === 'space' && showFooter) {
        footerHeight = 42;
    }

    let minHeight = 0;
    if (type === 'space' && showAddWorkspace) {
        minHeight = 164;
    }

    return Math.max(baseHeight + kpiHeight + monitorTextHeight + footerHeight, minHeight);
};

interface StatusBlocksProps {
    data: (StatusBlockDataItem & { workspaceId: string })[];
    columns: number;
    fillGrid?: boolean;
    showAddWorkspace: boolean;
    noBlocksMessage?: React.ReactNode;
    onAddWorkspaceClick?: () => void;
    type: StatusRequestType;
    kpiTypeIds?: string[];
    viewSettings?: Record<string, boolean | number>;
    shaping: {
        sort: {
            value: SortValue;
            direction: 'asc' | 'desc';
        };
        stateFilters: HealthState[];
        type: StatusRequestType;
        tags?: string[];
        workspaceTypes?: string[];
    };
    isWorkspaceFeatureEnabled: boolean;
    groupedKPIs?: Dictionary<KPI[]>;
}

export const StatusBlocks: React.FC<StatusBlocksProps> = ({
    data,
    columns,
    showAddWorkspace,
    viewSettings,
    noBlocksMessage,
    type,
    onAddWorkspaceClick,
    shaping,
    kpiTypeIds,
    isWorkspaceFeatureEnabled,
    groupedKPIs
}) => {
    const { data: workspaces } = useWorkspaces();
    const { data: plugins } = useDatasourceConfigs();

    const blocksDataIds = data.map((d) => d.id);

    const extraDataMap = useMemo(() => {
        const map = new Map();

        switch (type) {
            case 'space': {
                blocksDataIds?.forEach((workspaceId: any) => {
                    const workspace = workspaces?.find((w: any) => w.id === workspaceId);

                    if (workspace) {
                        map.set(workspace.id, {
                            type: upperFirst(workspace.data?.properties?.type ?? ''),
                            tags: workspace.data?.properties?.tags ?? [],
                            plugins: plugins
                                ?.filter((p) => (workspace.data?.links?.plugins ?? []).includes(p.id))
                                .map((p) => ({
                                    id: p.id,
                                    workspaceId: workspace.id,
                                    displayName: p.displayName,
                                    name: p.plugin?.name
                                }))
                        });
                    }
                });
                break;
            }
            default: {
                break;
            }
        }

        return map;
    }, [type, workspaces, plugins, blocksDataIds]);

    const parentRef = React.useRef<HTMLDivElement>(null);

    const [, height] = useSize(parentRef);

    const showKPIs = Boolean(viewSettings?.kpis);
    const showMonitorText = Boolean(viewSettings?.sublabel);
    const showFooter = Boolean(viewSettings?.type) || Boolean(viewSettings?.tags) || Boolean(viewSettings?.dataSources);

    const estimateSize = useCallback(
        () =>
            calculateEstimateSize({
                type,
                groupedKPIs,
                showKPIs,
                data,
                showMonitorText,
                showFooter,
                showAddWorkspace
            }),
        [data, groupedKPIs, showKPIs, type, showMonitorText, showFooter, showAddWorkspace]
    );

    const rowVirtualizer = useVirtualizer({
        count: data.length + (showAddWorkspace ? 1 : 0),
        getScrollElement: () => parentRef.current,
        estimateSize,
        overscan: columns,
        lanes: columns,
        gap: 24
    });

    useEffect(() => {
        rowVirtualizer.measure();
    }, [estimateSize, rowVirtualizer, columns, data.length, height, height, showKPIs]);

    const shapingKey = JSON.stringify(shaping);

    return (
        <div
            ref={parentRef}
            className='h-full pb-4 overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'
            role='grid'
            aria-colcount={columns}
        >
            {data.length === 0 && !showAddWorkspace && <>{noBlocksMessage}</>}
            {(data.length !== 0 || showAddWorkspace) && (
                <div
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative'
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                        <div
                            key={`${virtualItem.key.toString()}-${shapingKey}`}
                            role='gridcell'
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: `calc(${virtualItem.lane * (100 / columns)}% + ${
                                    (virtualItem.index % columns) * 6
                                }px)`,
                                width: `calc(${100 / columns}% - ${(columns - 1) * 6}px)`,
                                height: `${virtualItem.size}px`,
                                transform: `translateY(${virtualItem.start}px)`
                            }}
                        >
                            {virtualItem.index === data.length ? (
                                <>
                                    {showAddWorkspace && (
                                        <div
                                            data-testid='add-workspace-block'
                                            className='flex flex-col overflow-hidden items-center rounded-lg justify-center w-full h-full p-2 border shadow-[0px_0px_10px_-1px_rgba(0,0,0,0.15)] border-cardOutline bg-cardBackground'
                                        >
                                            {!isWorkspaceFeatureEnabled && (
                                                <div className='flex items-center justify-center w-full mb-8 overflow-hidden'>
                                                    <Text.H2 className='truncate'>Workspaces</Text.H2>
                                                    <FeatureUnavailablePill
                                                        featureKey='workspaces'
                                                        title='Organize your dashboards and data'
                                                        className='ml-3'
                                                    />
                                                </div>
                                            )}
                                            <Button
                                                onClick={onAddWorkspaceClick}
                                                className='w-full truncate overflow-hidden max-w-fit !block'
                                                disabled={!isWorkspaceFeatureEnabled}
                                            >
                                                Add workspace
                                            </Button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <StatusBlock
                                    type={type}
                                    kpiTypeIds={kpiTypeIds}
                                    blockData={data[virtualItem.index]! as any}
                                    extraData={extraDataMap.get(data[virtualItem.index].id)}
                                    showFooter={Array.from(extraDataMap.values()).some((d) => d?.plugins?.length > 0)}
                                    viewSettings={viewSettings}
                                />
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
