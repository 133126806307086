import { FormattedStreamData, findColumn, isNoData, required } from '@squaredup/data-streams';
import { HealthState, stateValues } from '@squaredup/monitoring';

const isHealthState = (value: unknown): value is HealthState => {
    return typeof value === 'string' && Object.keys(stateValues).includes(value);
};

const getNodeHealthRow = (data: Readonly<FormattedStreamData>, id: string | undefined) => {
    if (data.rows.length === 1) {
        return data.rows[0];
    }

    const idColIndex = findColumn(data.metadata.columns, required('name', 'data.id')).getValue({
        dataIndex: -1
    }).dataIndex;

    if (idColIndex < 0) {
        return undefined;
    }

    return data.rows.find((row) => row[idColIndex].value === id);
};

export const getHealthState = (
    data: Readonly<FormattedStreamData> | undefined,
    id: string | undefined,
    isCanonical = false
): HealthState | undefined => {
    if (!data || isNoData(data)) {
        return undefined;
    }

    const stateColIndex = findColumn(data.metadata.columns, required('name', 'data.state')).getValue({
        dataIndex: -1
    }).dataIndex;

    if (stateColIndex < 0) {
        return 'unknown';
    }

    if (isCanonical) {
        // aggregate health state, as the canonical itself won't have health
        return data.rows.reduce((currentState: HealthState, row) => {
            const rowValue = row[stateColIndex].value;
            return isHealthState(rowValue) && stateValues[rowValue] >= stateValues[currentState]
                ? rowValue
                : currentState;
        }, 'unknown');
    }

    // for non canonicals, ensure we only pull back health for the matching node
    // in case canonicals were included in the response
    const value = getNodeHealthRow(data, id)?.[stateColIndex].value;
    if (value === 'error' || value === 'success' || value === 'warning') {
        return value;
    }

    return 'unknown';
};