import Template from '@/components/Template';
import Text from '@/components/Text';
import { Serialised, generateRandomNumber } from '@squaredup/ids';
import { AlertingRuleV2, AlertingRules, isV1AlertingRule } from '@squaredup/monitoring';
import { isFeatureEnabled } from '@squaredup/tenants';
import { ConfirmationPrompt } from 'components/ConfirmationPrompt';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { useNotificationChannelTypes } from 'components/hooks/useNotificationChannels';
import { AppContext } from 'contexts/AppContext';
import trackEvent from 'lib/analytics';
import { usePageTitle } from 'lib/usePageTitle';
import WorkspaceRequired from 'pages/workspace/WorkspaceRequired';
import { useTier } from 'queries/hooks/useTier';
import { useContext, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { useWorkspaceCanWrite } from 'services/AccessControlService';
import { CHANNELS, ChannelCreate, ChannelType, Create, List } from 'services/NotificationChannelService';
import { Get as GetWorkspace, Update, WORKSPACE, Workspace } from 'services/WorkspaceService';
import { useEnsureCorrectCurrentWorkspaceId } from 'ui/hooks/useEnsureCorrectCurrentWorkspaceId';
import { AddEditNotificationRuleModal } from './AddEditNotificationRuleModal';
import { MonitorsTable } from './MonitorsTable';
import { NotificationDestinationIcon } from './NotificationDestinationIcon';
import { NotificationRulesTable } from './NotificationRulesTable';
import { useMonitorsInWorkspace } from './useMonitorsInWorkspace';

export interface AlertingRuleWithKey extends AlertingRuleV2 {
    key: string;
}

export interface Monitor {
    dashboardDisplayName: string | undefined;
    dashboardFolderPath?: string[];
    tileDisplayName: string | undefined;
    state: string;
    lastEvaluated: Date;
    lastChanged?: Date;
    tileId: string;
    dashId: string;
    lastChangedFrom: string;
    consecutiveFailures: number;
    nextEvaluation?: Date;
    reason?: string;
}

export interface DashboardMonitors {
    dashId: string;
    displayName: string;
    monitoredTiles: Monitor[];
    folderPath?: string[];
}

export interface NewChannel {
    channel: ChannelCreate;
    tempId: string;
}

export interface NotificationRuleToCreate {
    rule: AlertingRuleV2;
    destinationConfigs: NewChannel[];
}

const addKeyToRules = (rules: AlertingRules): AlertingRuleWithKey[] =>
    rules.map((rule) =>
        isV1AlertingRule(rule)
            ? {
                  key: `${generateRandomNumber(100000)}`,
                  channels: [{ id: rule.channelId, includePreviewImage: false }],
                  conditions: {
                      monitors: {
                          rollupHealth: true,
                          dashboardRollupHealth: false,
                          includeAllTiles: false
                      }
                  }
              }
            : { ...rule, key: `${generateRandomNumber(100000)}` }
    );

const Monitoring: React.FC = () => {
    usePageTitle('Monitors');
    const { id } = useParams();
    const queryClient = useQueryClient();
    const { currentWorkspaceID } = useContext(AppContext);

    // If we're not in the workspace matching the id param, set it here
    useEnsureCorrectCurrentWorkspaceId(id);

    const { data: canWriteToWorkspace } = useWorkspaceCanWrite(currentWorkspaceID || undefined);

    const [addingNotifications, setAddingNotifications] = useState(false);
    const [editingNotification, setEditingNotification] = useState<AlertingRuleWithKey>();
    const [confirmDeleteNotification, setConfirmDeleteNotification] = useState<string | undefined>();

    const { data: channels, isLoading: isLoadingChannels } = useQuery(CHANNELS, List);
    const { data: tier } = useTier();
    const { channelTypes, isLoadingChannelTypes } = useNotificationChannelTypes();

    const destinations = useMemo(() => {
        if (!channels || !channelTypes) {
            return [];
        }
        const typeById = channelTypes.reduce(
            (acc, val) => acc.set(val.id, val),
            new Map<string, Serialised<ChannelType>>()
        );

        return channels.map((c) => {
            const channelType = typeById.get(c.channelTypeId);
            return {
                id: c.id,
                name: `${c.displayName}`,
                tooltip: `${channelType?.displayName} destination '${c.displayName}'`,
                icon: <NotificationDestinationIcon className='w-5 h-5' channelTypeId={c.channelTypeId} />,
                enabled:
                    c.enabled &&
                    (!channelType?.requiresFeature ||
                        (tier !== undefined && isFeatureEnabled(tier, channelType.requiresFeature)))
            };
        });
    }, [channels, channelTypes, tier]);

    const { data: workspace, isLoading: workspaceLoading } = useQuery<Workspace | undefined>(
        [WORKSPACE, currentWorkspaceID],
        () => {
            if (!currentWorkspaceID) {
                return undefined;
            }
            queryClient.cancelQueries([WORKSPACE, currentWorkspaceID]);
            return GetWorkspace(currentWorkspaceID!);
        }
    );

    const { data: monitoringData, isLoading: isLoadingMonitoringData } = useMonitorsInWorkspace({
        workspaceId: currentWorkspaceID,
        queryOptions: {
            refetchInterval: 30000,
            // disable background refresh while in the modal, to prevent refreshes from resetting it
            enabled: !(addingNotifications || editingNotification)
        }
    });

    const rules = useMemo(() => addKeyToRules(workspace?.data?.alertingRules ?? []), [workspace]);

    const { mutateAsync: persistNotificationChannel } = useMutation(
        async ({ channel, tempId }: { channel: ChannelCreate; tempId: string }) => {
            const newId = await Create(channel);
            return {
                tempId,
                newId: newId.value
            };
        },
        {
            onSuccess: (newChannelId, request) => {
                const channelType = (channelTypes ?? []).find((ct) => ct.id === request.channel.channelTypeId);
                trackEvent('Notification Channel Created', { type: channelType?.displayName });
            },
            onSettled: async () => queryClient.invalidateQueries(CHANNELS)
        }
    );

    const { mutateAsync: persistNotificationRules } = useMutation(
        async (updatedRules: AlertingRuleWithKey[]) => {
            if (workspace?.id) {
                // strip rule key as it shouldn't be persisted
                const clean = updatedRules.map(({ key, ...rest }) => rest);
                await Update(workspace.id, undefined, clean);
            }
        },
        {
            onSettled: () => queryClient.invalidateQueries([WORKSPACE, id])
        }
    );

    const addNotificationRule = (rule: AlertingRuleWithKey) => persistNotificationRules(rules.concat(rule));

    const deleteNotificationRule = (ruleId: string) => persistNotificationRules(rules.filter((r) => r.key !== ruleId));

    const saveNotificationRule = (rule: AlertingRuleWithKey) =>
        persistNotificationRules(rules.map((r) => (r.key === rule.key ? rule : r)));

    const saveNotificationContent = async (data: NotificationRuleToCreate) => {
        // first attempt to save channel, if required
        if (data.destinationConfigs.length > 0) {
            const newIds = await Promise.all(data.destinationConfigs.map((c) => persistNotificationChannel(c)));
            // need to replace fake id in the channel list with a real one
            data.rule.channels = data.rule.channels.map((c) => ({
                id: newIds.find((nc) => nc.tempId === c.id)?.newId ?? c.id,
                includePreviewImage: c.includePreviewImage
            }));
        }

        const ruleWithKey = { ...data.rule, key: editingNotification?.key ?? '' };

        await (addingNotifications ? addNotificationRule(ruleWithKey) : saveNotificationRule(ruleWithKey));
    };

    const isLoading = workspaceLoading || isLoadingChannels || isLoadingChannelTypes || isLoadingMonitoringData;

    const newNotificationRule: AlertingRuleV2 = {
        channels: [],
        conditions: {
            monitors: {
                includeAllTiles: true,
                dashboardRollupHealth: false,
                rollupHealth: false
            }
        }
    };

    return (
        <WorkspaceRequired>
            <Template title='Monitors' flex>
                <div className='flex flex-col flex-1 min-h-0'>
                    <Text.H2>Monitors</Text.H2>
                    <Text.Body className='mb-sm text-textSecondary'>
                        {'To add monitors to this workspace enable monitoring on a tile in a dashboard. '}
                        <Button variant='link' href='https://squaredup.com/cloud/monitoring'>
                            Learn more
                        </Button>
                    </Text.Body>
                    <div className='flex flex-col min-h-0 mb-10'>
                        {isLoading ? <LoadingSpinner /> : <MonitorsTable monitors={monitoringData?.monitors ?? []} />}
                    </div>
                </div>
                <div className='flex flex-col flex-1 min-h-0'>
                    <Text.H2>Notification rules</Text.H2>
                    <Text.Body className='mb-sm text-textSecondary'>
                        {'Manage rules to notify on state changes in this workspace. '}
                        <Button variant='link' href='https://squaredup.com/cloud/health-notifications'>
                            Learn more
                        </Button>
                    </Text.Body>
                    <div>
                        <Button
                            onClick={() => setAddingNotifications(true)}
                            disabled={!canWriteToWorkspace || isLoading}
                            variant='primary'
                        >
                            Add notification rule
                        </Button>
                    </div>
                    <div className='flex flex-col min-h-0 mt-4 mb-8'>
                        {isLoading ? (
                            <LoadingSpinner />
                        ) : (
                            <NotificationRulesTable
                                monitors={monitoringData?.tilesByDashboard ?? []}
                                destinations={destinations}
                                rules={rules}
                                onDelete={setConfirmDeleteNotification}
                                onEdit={setEditingNotification}
                            />
                        )}
                    </div>
                </div>
            </Template>

            {/* Modals */}
            {(addingNotifications || editingNotification) && (
                <AddEditNotificationRuleModal
                    channelTypes={channelTypes ?? []}
                    channels={channels ?? []}
                    dashboards={monitoringData?.tilesByDashboard ?? []}
                    isEditing={editingNotification !== undefined}
                    onClose={() => {
                        setEditingNotification(undefined);
                        setAddingNotifications(false);
                    }}
                    onSave={saveNotificationContent}
                    rule={editingNotification ?? newNotificationRule}
                    tier={tier}
                />
            )}
            {confirmDeleteNotification && (
                <ConfirmationPrompt
                    title='Delete Notification Rule'
                    prompt='Are you sure you want to permanently delete this notification rule?'
                    confirmButtonText='Delete'
                    confirmButtonVariant='destructive'
                    onConfirm={async () => {
                        await deleteNotificationRule(confirmDeleteNotification);
                    }}
                    onClose={() => setConfirmDeleteNotification(undefined)}
                />
            )}
        </WorkspaceRequired>
    );
};

export default Monitoring;
