import { defaultOnCreate } from 'components/forms/autocomplete/Autocomplete';
import Field from 'components/forms/field/Field';
import type { ShapeConfigFieldsComponent } from './ShapeConfigFields';

export const StateShapeConfigFields: ShapeConfigFieldsComponent = ({ name }) => (
    <Field.Label label='Map Values to States' labelClassName='text-base'>
        <Field.Input
            name={`${name}.map.success`}
            label='Success'
            type='autocomplete'
            selectOptionsAs='valueString'
            onCreate={defaultOnCreate}
        />
        <Field.Input
            name={`${name}.map.warning`}
            label='Warning'
            type='autocomplete'
            selectOptionsAs='valueString'
            onCreate={defaultOnCreate}
        />
        <Field.Input
            name={`${name}.map.error`}
            label='Error'
            type='autocomplete'
            selectOptionsAs='valueString'
            onCreate={defaultOnCreate}
        />
        <Field.Input
            name={`${name}.map.unknown`}
            label='Unknown'
            type='autocomplete'
            selectOptionsAs='valueString'
            onCreate={defaultOnCreate}
        />
    </Field.Label>
);
