import { cn } from '@/lib/cn';
import type { TileConfig } from '@squaredup/dashboards';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { DashboardId } from '@squaredup/ids';
import DashboardContext from 'contexts/DashboardContext';
import RefreshContext from 'contexts/RefreshContext';
import TileContext from 'contexts/TileContext';
import { TileTypes } from 'dashboard-engine/constants';
import { useRefresh } from 'lib/useRefresh';
import { dashboardHasNoVariableObjectsSelected } from 'pages/dashboard/components/utils/variableDropdown';
import { SaveToDashboardModal } from 'pages/explore/SaveToDashboardModal';
import { useTileHealthState } from 'queries/hooks/useTileHealthState';
import { TileState } from 'queries/types/types';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSearchParams } from 'react-router-dom';
import { TileEditor } from './editor/TileEditor';
import { ConfigurationPrompt } from './editor/components/ConfigurationPrompt/ConfigurationPrompt';
import { useDashboardId } from './hooks/useDashboardId';
import { TileDataErrorFallBack } from './tile/TileDataErrorFallBack';
import { TileGenericErrorFallBack } from './tile/TileGenericErrorFallBack';
import { TileHeader } from './tile/TileHeader';

interface TileProps {
    id: string;
    title: string;
    description: string;
    children: JSX.Element;
    preview: boolean;
    config: TileConfig;
    supportsEditingTitle: boolean;
    draggable?: boolean;
    previewHealth?: TileState;
    onSave: (field: 'title' | 'description', value?: string) => void;
    onChange: (config: TileConfig) => void;
    onDelete?: () => void;
    onClone?: () => void;
    onDownloadAsImage?: () => Promise<void>;
}

function Tile({
    id: tileId,
    title,
    description,
    children,
    preview,
    previewHealth,
    config,
    supportsEditingTitle,
    draggable = true,
    onSave,
    onChange,
    onDelete,
    onClone,
    onDownloadAsImage
}: TileProps) {
    const [search] = useSearchParams();
    const dashboardId = useDashboardId();
    const context = useContext(DashboardContext);
    const { editing, variables } = context;

    const [tileType, setTileType] = useState(config._type);
    const [tileToolbar, setTileToolbar] = useState<ReactNode>([]);
    const [tileData, setTileData] = useState<unknown[]>([]);
    const [tileProcessedData, setTileProcessedData] = useState<unknown[]>([]);
    const [tileEditingDrawerOpen, setTileEditingDrawerOpen] = useState(false);
    const [isCopying, setIsCopying] = useState(false);
    const [isExporting, setIsExporting] = useState(false);

    const tileReference = useRef<HTMLDivElement>(null);
    const { data: tileHealthState } = useTileHealthState(tileId, dashboardId);

    // Use the preview health state if it exists, else use the actual health state data
    const health = previewHealth ?? tileHealthState;

    const onEdit = () => setTileEditingDrawerOpen(true);
    const onClose = () => setTileEditingDrawerOpen(false);
    const onCopy = () => setIsCopying(true);

    const searchIncludesTileId = search.has('tile') && tileId === search.get('tile');

    const { refreshCount, forceRefresh } = useRefresh();

    useEffect(() => {
        if (searchIncludesTileId) {
            // TODO: More reliable/cleaner way to do this?
            // Scroll to tile 200ms after load
            setTimeout(() => {
                tileReference.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }, 200);
        }
    }, [searchIncludesTileId]);

    const noVariableObjectsSelected = dashboardHasNoVariableObjectsSelected(variables);

    const tileClassName = cn(
        'group/tile relative flex flex-col h-full overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary',
        !preview && 'px-4 py-2',
        editing && !preview && 'border border-tileOutline',
        !editing &&
            noVariableObjectsSelected &&
            'variables' in config &&
            config.variables?.length &&
            'opacity-25',
        !(config?.noBackground || preview) && 'bg-tileBackground rounded-tileRadius'
    );

    return (
        <ErrorBoundary 
            FallbackComponent={({ resetErrorBoundary }) => 
                <TileGenericErrorFallBack 
                    className={tileClassName} 
                    reset={() => {
                        // Reset the open dialogs to handle instances when the error originated there
                        setTileEditingDrawerOpen(false);
                        setIsCopying(false);
                        resetErrorBoundary();
                    }}
                />
            }
        >
            <TileContext.Provider
                value={{
                    config,
                    tileId,
                    tileData,
                    tileProcessedData,
                    tileReference,
                    health,
                    preview,
                    isExporting,
                    setTileData,
                    setTileToolbar,
                    setTileProcessedData,
                    setIsExporting,
                    onEdit,
                    onClose,
                    onClone,
                    onCopy,
                    onDelete,
                    onChange
                }}
            >
                <RefreshContext.Provider
                    value={{
                        name: `tile-${tileId}`,
                        refreshCount,
                        forceRefresh
                    }}
                >
                    <div
                        id={`tile-${tileId}`}
                        ref={tileReference}
                        data-tile={title}
                        className={tileClassName}
                    >
                        {!config.noHeader && <TileHeader
                            tileId={tileId}
                            title={title}
                            description={description}
                            config={config}
                            editing={editing}
                            preview={preview}
                            health={health}
                            tileToolbar={tileToolbar}
                            draggable={draggable}
                            supportsEditingTitle={supportsEditingTitle}
                            onSave={onSave}
                            onDownloadAsImage={onDownloadAsImage}
                        />}

                        <div className='h-full min-h-0 text-sm' data-testid='tileBody'>
                            <ErrorBoundary FallbackComponent={TileDataErrorFallBack}>
                                <ConfigurationPrompt
                                    editing={editing}
                                    isConfigured={Boolean(config?._type)}
                                    setTileType={setTileType}
                                />
                                {children}
                            </ErrorBoundary>
                        </div>
                    </div>
                    {tileEditingDrawerOpen && (
                        <TileEditor
                            config={config}
                            tileType={config._type || tileType || TileTypes.dataStream}
                            onClose={onClose}
                            tileId={tileId}
                            context={context}
                        />
                    )}

                    {isCopying && tileType === TileTypes.dataStream && (
                        <SaveToDashboardModal
                            close={() => setIsCopying(false)}
                            config={config as DataStreamBaseTileConfig}
                            currentDashboardId={dashboardId as DashboardId['value']}
                        />
                    )}
                </RefreshContext.Provider>
            </TileContext.Provider>
        </ErrorBoundary>
    );
}

Tile.propTypes = {};

export default Tile;
