import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
    draggable,
    dropTargetForElements,
    monitorForElements
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import type { DragLocationHistory } from '@atlaskit/pragmatic-drag-and-drop/types';
import clsx from 'clsx';
import { TruncatedText } from 'components/TruncatedText';
import { DashboardStateIndicator } from 'components/ui/state/DashboardStateIndicator';
import { useAppContext } from 'contexts/AppContext';
import type { DashboardType } from '@squaredup/dashboards';
import { DashboardFolder, isDashboard, isFolder } from 'queries/utils/dashboardSorted';
import { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useMatch } from 'react-router';
import { useWorkspaceCanWrite } from 'services/AccessControlService';
import useTimeout from 'ui/hooks/useTimeout';
import { NavLink } from 'ui/nav/components/NavLink';
import { DashboardDropIndicator } from './DashboardDropIndicator';
import { DashboardFolderInlineEdit } from './DashboardFolderInlineEdit';
import { AttachedInstruction, attachInstruction, DashboardTreeItemData } from './DashboardTreeHitbox';
import {
    getParentLevelOfInstruction,
    INDENT_PER_LEVEL,
    Instruction,
    isFolderInitEdit,
    removeFolderInitEdit,
    tree
} from './DashboardTreeUtils';
import { FolderMenu } from './FolderMenu';
import { useIsFolderOpen, useSetFolderOpen } from './useFoldersOpen';

export type ItemState = 'idle' | 'dragging' | 'preview' | 'parent-of-instruction';

export interface GhostTreeItemProps {
    treeId: string;
    level: number;
    item: DashboardType | DashboardFolder;
}

// This component is designed to sit at the very bottom of the tree.
// It makes it easier to move items between folders when at the bottom of the tree
export const GhostTreeItem: React.FC<GhostTreeItemProps> = ({ treeId, level, item }) => {
    const ghostRef = useRef<HTMLSpanElement>(null);
    const [instruction, setInstruction] = useState<Instruction>();

    useEffect(() => {
        return dropTargetForElements({
            element: ghostRef.current!,
            getData: ({ input, element, source }) => {
                return attachInstruction({
                    source: source.data as DashboardTreeItemData,
                    input,
                    element,
                    currentLevel: level,
                    itemType: 'ghost',
                    item
                });
            },
            canDrop: ({ source }) => source.data.treeId === treeId,
            onDrag: ({ self }) => setInstruction((self.data as AttachedInstruction).instruction),
            onDragLeave: () => {
                setInstruction(undefined);
            },
            onDrop: () => {
                setInstruction(undefined);
            }
        });
    }, [level, treeId, item]);
    return (
        <span ref={ghostRef} className='h-4 grow flex'>
            <span className='relative h-0  grow'>
                <DashboardDropIndicator instruction={instruction} />
            </span>
        </span>
    );
};

interface TreeItemProps {
    index: number;
    item: DashboardType | DashboardFolder;
    level: number;
    treeId: string;
    isLastOfGroup: boolean;
}

export const TreeItem = memo(function TreeItem({ item, level, index, treeId, isLastOfGroup }: TreeItemProps) {
    const buttonRef = useRef<HTMLElement>(null);

    const [state, setState] = useState<ItemState>('idle');
    const [instruction, setInstruction] = useState<Instruction>();

    const isOpen = useIsFolderOpen(item);
    const setOpen = useSetFolderOpen();

    const clearParentOfInstructionState = useCallback(() => {
        setState((current) => (current === 'parent-of-instruction' ? 'idle' : current));
    }, []);

    const delayedOpen = useTimeout((...args: Parameters<typeof setOpen>) => setOpen(...args));

    const { currentWorkspaceID } = useAppContext();
    const { data: canWrite } = useWorkspaceCanWrite(currentWorkspaceID ?? '');

    // We want to highlight the direct parent folder of the current items drop target
    const shouldHighlightParent = useCallback(
        (location: DragLocationHistory): boolean => {
            const targetData = location.current.dropTargets[0];

            if (!targetData || isDashboard(item)) {
                return false;
            }

            const currInstruction = targetData.data.instruction as Instruction;

            if (!currInstruction) {
                return false;
            }

            const targetId = targetData.data.id as string;

            if (currInstruction.type === 'make-child') {
                return targetId === item.id;
            }

            const paths =
                tree.getPathToItem({
                    targetId,
                    current: [item]
                }) || [];

            return getParentLevelOfInstruction(currInstruction) === level && paths.includes(item.id);
        },
        [item, level]
    );

    useEffect(() => {
        function updateIsParentOfInstruction({ location }: { location: DragLocationHistory }) {
            if (shouldHighlightParent(location)) {
                setState('parent-of-instruction');
            } else {
                clearParentOfInstructionState();
            }
        }

        return combine(
            draggable({
                element: buttonRef.current!,
                canDrag: () => Boolean(canWrite),
                getInitialData: () =>
                    ({
                        id: item.id,
                        type: isDashboard(item) ? 'dashboard' : 'folder',
                        isOpenOnDragStart: isFolder(item) && isOpen,
                        maxDepth: tree.getMaxDepth(item),
                        treeId
                    }) as DashboardTreeItemData,
                onGenerateDragPreview: ({ nativeSetDragImage }) => {
                    setCustomNativeDragPreview({
                        getOffset: pointerOutsideOfPreview({ x: '16px', y: '8px' }),
                        render: ({ container }) => {
                            ReactDOM.render(
                                <span className='text-white'>{isFolder(item) ? item.name : item.displayName}</span>,
                                container
                            );
                            return () => ReactDOM.unmountComponentAtNode(container);
                        },
                        nativeSetDragImage
                    });
                },
                onDragStart: ({ source }) => {
                    setState('dragging');
                    // collapse open items during a drag
                    if (source.data.isOpenOnDragStart) {
                        setOpen([{ itemId: item.id, open: false }]);
                    }
                },
                onDrop: ({ source }) => {
                    setState('idle');
                    if (source.data.isOpenOnDragStart) {
                        setOpen([{ itemId: item.id, open: true }]);
                    }
                }
            }),
            dropTargetForElements({
                element: buttonRef.current!,
                getData: ({ input, element, source }) =>
                    attachInstruction({
                        source: source.data as DashboardTreeItemData,
                        input,
                        element,
                        currentLevel: level,
                        itemType: isFolder(item) ? 'folder' : 'dashboard',
                        expanded: isOpen ?? false,
                        lastInGroup: isLastOfGroup && isFolder(item) ? item.children.length === 0 : isLastOfGroup,
                        item
                    }),
                canDrop: ({ source }) => source.data.treeId === treeId,
                onDrag: ({ self, source }) => {
                    const currInstruction = (self.data as AttachedInstruction).instruction;

                    if (!currInstruction || (source.data.id === item.id && currInstruction.type !== 'reparent')) {
                        return setInstruction(undefined);
                    }

                    // expand after 500ms if still dropping onto folder
                    if (isFolder(item) && currInstruction.type === 'make-child' && !isOpen && !delayedOpen.isActive()) {
                        delayedOpen.start(500, [{ itemId: item.id, open: !isOpen }]);
                    }
                    if (currInstruction.type !== 'make-child') {
                        delayedOpen.stop();
                    }

                    setInstruction(currInstruction);
                },
                onDragLeave: () => {
                    delayedOpen.stop();
                    setInstruction(undefined);
                },
                onDrop: () => {
                    delayedOpen.stop();
                    setInstruction(undefined);
                }
            }),
            monitorForElements({
                canMonitor: ({ source }) => source.data.treeId === treeId,
                onDragStart: updateIsParentOfInstruction,
                onDrag: updateIsParentOfInstruction,
                onDrop() {
                    clearParentOfInstructionState();
                }
            })
        );
    }, [
        item,
        level,
        delayedOpen,
        treeId,
        clearParentOfInstructionState,
        shouldHighlightParent,
        isOpen,
        setOpen,
        isLastOfGroup,
        instruction?.type,
        canWrite
    ]);

    const dashboardPage = useMatch('/dashboard/:id');

    useEffect(() => {
        if (dashboardPage?.params.id === item.id) {
            buttonRef.current?.scrollIntoView({ block: 'nearest' });
        }
    }, [dashboardPage?.params.id, item.id]);

    return (
        <>
            <div
                data-type={isDashboard(item) ? 'dash' : 'folder'}
                className='relative grow flex overflow-visible items-center'
            >
                {isDashboard(item) ? (
                    <DashboardComponent
                        id={`tree-item-${item.id}`}
                        ref={buttonRef as React.Ref<HTMLAnchorElement>}
                        item={item}
                        state={state}
                        instruction={instruction}
                        level={level}
                        data-index={index}
                        data-level={level}
                    />
                ) : (
                    <FolderComponent
                        id={`tree-item-${item.id}`}
                        onClick={() => setOpen([{ itemId: item.id, open: !isOpen }])}
                        ref={buttonRef as React.Ref<HTMLButtonElement>}
                        item={item}
                        state={state}
                        instruction={instruction}
                        level={level}
                        isOpen={isOpen}
                        data-index={index}
                        data-level={level}
                    />
                )}
            </div>
            {isFolder(item) && isOpen ? (
                <div className='flex flex-col grow' data-type='content' id={`tree-item-${item.id}--subtree`}>
                    {item.children?.map((child, i) => (
                        <TreeItem
                            isLastOfGroup={i === item.children.length - 1}
                            item={child}
                            key={child.id}
                            level={level + 1}
                            index={i}
                            treeId={treeId}
                        />
                    ))}
                </div>
            ) : null}
        </>
    );
});

const FolderChevron: React.FC = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='currentColor'
        className='shrink-0 pl-px group-hover/btn:text-textPrimary mr-3.5 group-aria-expanded/btn:rotate-0 -rotate-90 origin-center transition h-3.5'
        viewBox='0 0 448 512'
    >
        <path d='M432.6 209.3l-191.1 183.1C235.1 397.8 229.1 400 224 400s-11.97-2.219-16.59-6.688L15.41 209.3C5.814 200.2 5.502 184.1 14.69 175.4c9.125-9.625 24.38-9.938 33.91-.7187L224 342.8l175.4-168c9.5-9.219 24.78-8.906 33.91 .7187C442.5 184.1 442.2 200.2 432.6 209.3z' />
    </svg>
);

type FolderComponentProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    item: DashboardFolder;
    level: number;
    state: ItemState;
    instruction?: Instruction;
    isOpen?: boolean;
};
const FolderComponent = forwardRef<HTMLSpanElement, FolderComponentProps>(
    ({ item, level, state, instruction, isOpen, className, ...props }, ref) => {
        const [editName, setEditName] = useState(isFolderInitEdit(item));

        useEffect(() => {
            if (!editName) {
                removeFolderInitEdit(item);
            }
        }, [editName, item]);

        const { currentWorkspaceID } = useAppContext();
        const { data: canWrite } = useWorkspaceCanWrite(currentWorkspaceID ?? '');
        return (
            <span
                draggable={!editName}
                {...props}
                className={clsx('flex grow w-full px-2.5 items-center group pb-[8px] relative', className)}
                ref={ref}
            >
                <button
                    style={{ paddingLeft: level * INDENT_PER_LEVEL + 9 }}
                    type='button'
                    className={clsx(
                        'flex basis-0 w-[calc(100%-17.5px)] grow py-px focus-visible:text-textPrimary text-textSecondary focus-visible:outline outline-2 outline-current  leading-input items-center group/btn ',
                        state === 'parent-of-instruction' && '!text-textPrimary'
                    )}
                    aria-expanded={Boolean(isOpen)}
                    aria-controls={`tree-item-${item.id}--subtree`}
                    aria-label='ExpandCollapse Folder'
                >
                    <FolderChevron />
                    <DashboardFolderInlineEdit state={state} editing={editName} item={item} setEditing={setEditName} />
                    <DashboardDropIndicator instruction={instruction} />
                </button>

                {canWrite && (
                    <FolderMenu
                        aria-label='folderMenuButton'
                        level={level}
                        folder={item}
                        onEditClick={() => setEditName(true)}
                        className='invisible shrink-0 grow-0 focus-visible:text-textPrimary -mr-[2.5px] group-hover:visible px-1 group-focus-within:visible hover:text-textPrimary ml-auto'
                    />
                )}
            </span>
        );
    }
);

type DashboardComponentProps = React.ButtonHTMLAttributes<HTMLAnchorElement> & {
    item: DashboardType;
    level: number;
    state: ItemState;
    instruction?: Instruction;
};
const DashboardComponent = forwardRef<HTMLAnchorElement, DashboardComponentProps>(
    ({ item, level, state, instruction, className, ...props }, ref) => (
        <span ref={ref} className={clsx('pb-[5.5px] w-full px-2.5 relative', className)} {...props}>
            <NavLink
                data-testid='dashboardsLink'
                draggable={false}
                style={{ paddingLeft: level * INDENT_PER_LEVEL + 9 }}
                to={`/dashboard/${item.id}`}
                className={'!py-0.5 pr-1 grow focus-visible:outline outline-current outline-2'}
            >
                <DashboardStateIndicator dashboardId={item.id} className={clsx('ml-0.5 mr-4 pl-px')} />
                <TruncatedText
                    disabled={state === 'dragging'}
                    placement='bottom-end'
                    className='truncate shrink'
                    title={item.displayName || ''}
                >
                    {item.displayName}
                </TruncatedText>
                <DashboardDropIndicator instruction={instruction} />
            </NavLink>
        </span>
    )
);
