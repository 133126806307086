import { faCircleExclamation, faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, SortDirection, createColumnHelper } from '@tanstack/react-table';
import { Pill } from 'components/pill/Pill';
import { HealthState } from 'dashboard-engine/types/data/StateData';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { sortStatus } from 'pages/components/ApplicationTable/ApplicationTableSort';
import { FC } from 'react';
import TruncateWithTooltip from '../TruncateWithTooltip';
import AgentStatusIcon from './AgentStatusIcon';
import { AgentGroupWithPlatform } from 'components/hooks/useAgentGroups';
import { Action } from 'pages/components/ApplicationTable/types';

interface ProjectedAgentGroup extends AgentGroupWithPlatform {
    agentsString: string;
    status: string;
    statusMessage: string;
}

interface AgentGroupsTableProps {
    isFeatureAvailable: boolean;
    agentGroups: AgentGroupWithPlatform[] | undefined;
    onEdit: (agentGroup: AgentGroupWithPlatform) => void;
    onDelete: (agentGroup: AgentGroupWithPlatform) => void;
}

const AgentsGroupApplicationTable = ApplicationTable<ProjectedAgentGroup, string>();

const AgentGroupsTable: FC<AgentGroupsTableProps> = ({ agentGroups, onEdit, onDelete, isFeatureAvailable }) => {
    const columnHelper = createColumnHelper<ProjectedAgentGroup>();
    let getIsSortedStatus: () => SortDirection | false;
    const columns: ColumnDef<ProjectedAgentGroup, string>[] = [
        columnHelper.accessor((row) => row.status, {
            id: 'status',
            header: 'Status',
            cell: ({ row, getValue, column }) => {
                getIsSortedStatus = column.getIsSorted;
                return (
                    <div className='flex items-center'>
                        <AgentStatusIcon state={getValue() as HealthState} title={row.original.statusMessage} />
                    </div>
                );
            },
            // eslint-disable-next-line max-len
            sortingFn: (rowA, rowB, columnId) => sortStatus<ProjectedAgentGroup>(rowA, rowB, columnId, getIsSortedStatus),
            size: 75
        }),
        columnHelper.accessor((row) => row.displayName, {
            id: 'displayName',
            header: 'Name',
            cell: ({ getValue }) => {
                return (
                    <TruncateWithTooltip title={getValue()}>
                        <div className='truncate'>{getValue()}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 200
        }),
        columnHelper.accessor((row) => row.description, {
            id: 'description',
            header: 'Description',
            cell: ({ getValue }) => {
                return (
                    <TruncateWithTooltip title={getValue()}>
                        <div className='truncate'>{getValue()}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 200
        }),
        columnHelper.accessor((row) => row.platformSummary, {
            id: 'platform',
            header: 'Platform',
            cell: ({ getValue }) => {
                const value = getValue();
                return value ? (
                    <Pill data-testid='platform-pill' className='capitalize'>
                        {getValue()}
                    </Pill>
                ) : (
                    <></>
                );
            },
            size: 100
        }),
        columnHelper.accessor((row) => row.agentsString, {
            id: 'agents',
            header: 'Agent(s)',
            cell: ({ getValue }) => {
                return (
                    <>
                        {getValue() ? (
                            <TruncateWithTooltip title={getValue()}>
                                <div className='truncate'>{getValue()}</div>
                            </TruncateWithTooltip>
                        ) : (
                            <div className='text-statusErrorPrimary'>
                                <FontAwesomeIcon icon={faCircleExclamation} className='mr-2' />
                                No agents
                            </div>
                        )}
                    </>
                );
            },
            size: 200
        })
    ];

    const actions: Action[] = [
        {
            action: onEdit,
            icon: faPencil,
            dataTestId: 'editAgentGroupButton',
            tooltip: 'Edit agent group',
            visible: () => isFeatureAvailable
        },
        {
            action: onDelete,
            icon: faTrash,
            dataTestId: 'deleteAgentGroupButton',
            tooltip: 'Delete agent group'
        }
    ];

    const projectedAgentGroups = agentGroups?.map((group) => {

        let status = 'unknown';
        if (isFeatureAvailable && group.agentOptions.length > 0) {
            if (group.listenerCount >= group.agentOptions.length) {
                status = 'success';
            } else if (group.listenerCount > 0) {
                status = 'warning';
            } else {
                status = 'error';
            }
        }

        return { 
            ...group,
            agentsString: (group.agentOptions.map((option) => option.label) ?? []).join(', '),
            status,
            statusMessage: isFeatureAvailable
                ? `${group.listenerCount} of ${group.agentOptions?.length || 0} agents listening`
                : 'Agent Group disabled (not included in current plan).'
        };
    }) ?? [];

    return (
        <AgentsGroupApplicationTable
            config={{
                actions,
                noDataMessage: 'There are no agent groups configured.',
                dataTestId: 'agentGroupList'
            }}
            columns={columns}
            data={projectedAgentGroups}
        />
    );
};

export default AgentGroupsTable;
