import { faCircleExclamation, faDownload, faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, SortDirection, createColumnHelper } from '@tanstack/react-table';
import { AgentWithRelatedObjects } from 'components/hooks/useAgents';
import { Pill } from 'components/pill/Pill';
import { HealthState } from 'dashboard-engine/types/data/StateData';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { sortStatus } from 'pages/components/ApplicationTable/ApplicationTableSort';
import { Action } from 'pages/components/ApplicationTable/types';
import { FC } from 'react';
import TruncateWithTooltip from '../TruncateWithTooltip';
import AgentStatusIcon from './AgentStatusIcon';

interface ProjectedAgent extends AgentWithRelatedObjects {
    status: string;
    statusMessage: string;
    groupsString: string;
    lastUpdated?: string | undefined;
}

interface AgentsTableProps {
    isFeatureAvailable: boolean;
    agents: AgentWithRelatedObjects[] | undefined;
    latestAgentVersion: string;
    onDownload: (agent: AgentWithRelatedObjects) => void;
    onEdit: (agent: AgentWithRelatedObjects) => void;
    onDelete: (agent: AgentWithRelatedObjects) => void;
}

const AgentsApplicationTable = ApplicationTable<ProjectedAgent, string>();

const AgentsTable: FC<AgentsTableProps> = ({
    agents,
    latestAgentVersion,
    onDownload,
    onEdit,
    onDelete,
    isFeatureAvailable
}) => {
    const columnHelper = createColumnHelper<ProjectedAgent>();
    let getIsSortedStatus: () => SortDirection | false;
    const columns: ColumnDef<ProjectedAgent, string>[] = [
        columnHelper.accessor((row) => row.status, {
            id: 'status',
            header: 'Status',
            cell: ({ row, getValue, column }) => {
                getIsSortedStatus = column.getIsSorted;
                return (
                    <div className='flex items-center'>
                        <AgentStatusIcon state={getValue() as HealthState} title={row.original.statusMessage} />
                    </div>
                );
            },
            sortingFn: (rowA, rowB, columnId) => sortStatus<ProjectedAgent>(rowA, rowB, columnId, getIsSortedStatus),
            size: 75
        }),
        columnHelper.accessor((row) => row.displayName, {
            id: 'displayName',
            header: 'Name',
            cell: ({ getValue }) => {
                return (
                    <TruncateWithTooltip title={getValue()}>
                        <div className='truncate'>{getValue()}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 200
        }),
        columnHelper.accessor((row) => row.description, {
            id: 'description',
            header: 'Description',
            cell: ({ getValue }) => {
                return (
                    <TruncateWithTooltip title={getValue()}>
                        <div className='truncate'>{getValue()}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 200
        }),
        columnHelper.accessor((row) => row.hostname, {
            id: 'hostname',
            header: 'Host',
            cell: ({ getValue }) => {
                return (
                    <>
                        {getValue() && (
                            <TruncateWithTooltip title={getValue()}>
                                <div className='truncate'>{getValue()}</div>
                            </TruncateWithTooltip>
                        )}
                    </>
                );
            },
            size: 200
        }),
        columnHelper.accessor((row) => row.platform, {
            id: 'platform',
            header: 'Platform',
            cell: ({ getValue }) => {
                const value = getValue();
                return value ? (
                    <Pill data-testid='platform-pill' className='capitalize'>
                        {getValue()}
                    </Pill>
                ) : (
                    <></>
                );
            },
            size: 100
        }),
        columnHelper.accessor((row) => row.version, {
            id: 'versionNumber',
            header: 'Version',
            cell: ({ getValue }) => {
                const cellAgentVersion = getValue();
                if (!cellAgentVersion) {
                    return <span className='capitalize text-textSecondary'>unknown</span>;
                }

                const outDatedVersion =
                    cellAgentVersion.localeCompare(latestAgentVersion, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    }) < 0;

                if (outDatedVersion) {
                    return (
                        <div className='flex items-center gap-2'>
                            <span className='truncate'>{cellAgentVersion}</span>
                            <TruncateWithTooltip title={`New version available: ${latestAgentVersion}`}>
                                <FontAwesomeIcon
                                    icon={faCircleExclamation}
                                    className='mb-px text-statusWarningPrimary'
                                />
                            </TruncateWithTooltip>
                        </div>
                    );
                }

                return (
                    <TruncateWithTooltip title={cellAgentVersion}>
                        <div className='truncate'>{cellAgentVersion}</div>
                    </TruncateWithTooltip>
                );
            },
            size: 120
        }),
        columnHelper.accessor((row) => row.apiKey.display, {
            id: 'apiKey',
            header: 'API Key',
            cell: ({ getValue }: any) => getValue(),
            size: 170
        }),
        columnHelper.accessor((row) => row.groupsString, {
            id: 'agentGroups',
            header: 'Agent Group(s)',
            cell: ({ getValue }) => {
                return (
                    <>
                        {getValue() ? (
                            <TruncateWithTooltip title={getValue()}>
                                <div className='truncate'>{getValue()}</div>
                            </TruncateWithTooltip>
                        ) : (
                            <div className='text-statusErrorPrimary'>
                                <FontAwesomeIcon icon={faCircleExclamation} className='mr-2' />
                                No agent groups
                            </div>
                        )}
                    </>
                );
            },
            size: 250
        })
    ];

    const actions: Action[] = [
        {
            action: onDownload,
            icon: faDownload,
            dataTestId: 'downloadAgentButton',
            tooltip: 'Deploy SquaredUp Cloud agent',
            visible: () => isFeatureAvailable
        },
        {
            action: onEdit,
            icon: faPencil,
            dataTestId: 'editAgentButton',
            tooltip: 'Edit agent',
            visible: () => isFeatureAvailable
        },
        {
            action: onDelete,
            icon: faTrash,
            dataTestId: 'deleteAgentButton',
            tooltip: 'Delete agent'
        }
    ];

    const projectedAgents = agents?.map((agent) => {
        let status = 'error';
        let message = 'Agent heartbeat missed.';

        const sixMinutes = 6 * 60 * 1000;

        if(!isFeatureAvailable) {
            status = 'unknown';
            message = 'Agent disabled (not included in current plan).';
        } else if (!agent.lastConnected) {
            status = 'unknown';
            message = 'Awaiting first connection.';
        } else if (agent.lastConnected > Date.now() - sixMinutes) {
            status = 'success';
            message = 'Agent connected.';
        }

        return {
            ...agent,
            status,
            groupsString: agent.formattedAgentGroups.map((ag) => ag.label).join(', '),
            statusMessage: `${message} Last connected: ${
                agent.lastConnected ? new Date(agent.lastConnected).toString() : 'never'
            }`
        };
    });

    return (
        <AgentsApplicationTable
            config={{
                actions,
                noDataMessage: 'There are no agents configured.',
                dataTestId: 'agentList'
            }}
            columns={columns}
            data={projectedAgents ?? []}
        />
    );
};

export default AgentsTable;
