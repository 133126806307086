import { cn } from '@/lib/cn';
import { stateStrings } from '@squaredup/monitoring';
import LoadingSpinner from 'components/LoadingSpinner';
import { useNetworkBackground } from 'components/map/context/NetworkMapStoreContext';
import { PinnableNodeData } from 'components/map/data/types';
import { useStoreHealthStateNode } from 'components/map/hooks/useStoreHealthStateNode';
import { TruncatedText } from 'components/TruncatedText';
import { mapBackgroundColors, mapFillColors } from 'dashboard-engine/visualisations/Network/utils/mapColors';
import type { KPIValue } from 'dynamo-wrapper';
import { useStatusConfig } from 'pages/status/utils/useStatusConfig';
import { useWorkspaceHealthStates } from 'queries/hooks/useWorkspaceHealthStates';
import { useWorkspaceKpis } from 'queries/hooks/useWorkspaceKpis';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Handle, Position } from 'reactflow';
import { KPI_NODE_SIZE } from '../KPINode';
import { WorkspaceAvatar } from './common/Avatar';

const healthStateStyles = {
    [stateStrings.error]: 'stroke-statusErrorPrimary',
    [stateStrings.warning]: 'stroke-statusWarningPrimary',
    [stateStrings.success]: 'stroke-statusHealthyPrimary',
    [stateStrings.unknown]: 'stroke-statusUnknownPrimary'
};

const healthStateBorderStyles = {
    success: 'border-statusHealthyPrimary',
    error: 'border-statusErrorPrimary',
    warning: 'border-statusWarningPrimary',
    unknown: 'border-statusUnknownPrimary'
};

interface KPINodeInternalProps extends PinnableNodeData {
    id: string;
}

export const KPINodeInternal: React.FC<KPINodeInternalProps> = memo(
    ({ id, label, pinned, workspaceId, kpis, kpiCount }) => {
        const kpisWithoutState: KPIValue[] | undefined = kpis ? JSON.parse(kpis) : undefined;
        const background = useNetworkBackground();

        const { data: { kpiTypeIds } = {} } = useStatusConfig();

        const { data: workspaceHealthStates = [] } = useWorkspaceHealthStates();
        const { data: KPIs = kpisWithoutState ?? [] } = useWorkspaceKpis(workspaceId, {
            enabled: Boolean(kpiCount),
            select: (data) => data.filter(({ type }) => !kpiTypeIds?.length || kpiTypeIds?.includes(type))
        });

        const healthState = workspaceHealthStates.find(
            ({ id: workspaceHealthStateId }) => workspaceId === workspaceHealthStateId
        )?.state;

        useStoreHealthStateNode(id, healthState);

        return (
            <div
                style={{
                    width: KPI_NODE_SIZE,
                    height: KPI_NODE_SIZE
                }}
                className='relative pointer-events-none'
            >
                <div
                    className={cn('absolute top-0 left-0 flex items-start space-x-0.5', !KPIs.length && 'items-center')}
                    style={{
                        width: KPIs.length ? KPI_NODE_SIZE + 160 : KPI_NODE_SIZE,
                        height: KPIs.length ? KPI_NODE_SIZE + KPIs.length * 30 + 50 : KPI_NODE_SIZE
                    }}
                >
                    <div
                        className={cn(
                            'relative w-full inline-flex rounded-full pointer-events-auto overflow-hidden items-center justify-center flex-grow-0 flex-shrink-0',
                            mapBackgroundColors[background]
                        )}
                        style={{
                            width: KPI_NODE_SIZE,
                            height: KPI_NODE_SIZE
                        }}
                    >
                        <svg viewBox='0 0 100 100' className={cn('absolute inset-0', mapFillColors[background])}>
                            <circle
                                cx='50%'
                                cy='50%'
                                r='40'
                                vectorEffect='non-scaling-stroke'
                                strokeWidth={5}
                                className={cn(
                                    'stroke-filterActive',
                                    mapFillColors[background],
                                    healthState &&
                                        healthState !== stateStrings.unknown &&
                                        healthStateStyles[healthState]
                                )}
                                {...(!pinned && { strokeDasharray: '2 2' })}
                            />
                        </svg>

                        <WorkspaceAvatar workspaceId={workspaceId} label={label} />

                        <Handle
                            type='target'
                            position={Position.Top}
                            className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
                        />

                        <Handle
                            type='source'
                            position={Position.Bottom}
                            className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
                        />
                    </div>

                    <div className='w-40 space-y-2'>
                        {label && (
                            <>
                                <div
                                    className={cn(
                                        'text-primary rounded-sm text-base inline-block pointer-events-auto max-w-full font-bold px-1 py-0.5 leading-tight',
                                        mapBackgroundColors[background],
                                        Boolean(KPIs?.length) && 'max-w-full mt-[28px]'
                                    )}
                                >
                                    <TruncatedText title={label}>{label}</TruncatedText>
                                </div>
                            </>
                        )}

                        {Boolean(KPIs?.length) && (
                            <div
                                className={cn(
                                    'w-full transition-opacity ease-in-out border border-l-0 divide-y pointer-events-auto border-dividerPrimary divide-dividerPrimary',
                                    mapBackgroundColors[background]
                                )}
                            >
                                {KPIs.map(({ tileId, name, formattedValue, status, dashboardId }) => (
                                    <Link
                                        key={tileId}
                                        to={`/dashboard/${dashboardId}?tile=${tileId}`}
                                        className={cn(
                                            'block px-2 py-1 border-l-4',
                                            status && healthStateBorderStyles[status]
                                        )}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <TruncatedText
                                            title={name}
                                            className='text-[8px] leading-tight text-textSecondary'
                                        />
                                        <TruncatedText
                                            title={formattedValue}
                                            className='font-bold text-[8px]'
                                            disabled={formattedValue === undefined}
                                        >
                                            {formattedValue ?? <LoadingSpinner size={8} />}
                                        </TruncatedText>
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
);
