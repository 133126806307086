import { FormattedStreamValue } from '@squaredup/data-streams';
import { TruncatedText } from 'components/TruncatedText';
import Tooltip from 'components/tooltip/Tooltip';
import { toString } from 'lodash';

export const GUIDCell: React.FC<FormattedStreamValue> = ({ raw, formatted }) => {
    const rawString = toString(raw);

    return rawString && rawString !== formatted ? (
        <Tooltip disabled={false} className='truncate' title={rawString}>
            <pre className='font-mono whitespace-normal'>{formatted}</pre>
        </Tooltip>
    ) : (
        <TruncatedText title={formatted}>
            <pre className='font-mono whitespace-normal'>{formatted}</pre>
        </TruncatedText>
    );
};
