import Text from '@/components/Text';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'components/LoadingSpinner';
import Tooltip from 'components/tooltip/Tooltip';
import TruncateWithTooltip from 'pages/settings/TruncateWithTooltip';
import { useWorkspaceKpis } from 'queries/hooks/useWorkspaceKpis';
import { ComponentPropsWithoutRef, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Link, LinkProps } from 'react-router-dom';
import ErrorFallback from 'ui/errorHandling/ErrorFallback';
import { NoKpisInWorkspaceMessage } from './WorkspaceEmptyMessages';
import { Panel, useWorkspaceId } from './WorkspaceHome';

export const WorkspaceKpiOverviewPanel = () => {
    return (
        <Panel className='gap-4'>
            <Header>
                <Text.Body className='text-lg font-semibold capitalize'>KPIs</Text.Body>
                <Tooltip
                    title='KPIs in this workspace are automatically published outside of this workspace so they can be aggregated for reporting purposes.'
                    className='mr-auto'
                >
                    <FontAwesomeIcon icon={faQuestionCircle} className='text-textSecondary' />
                </Tooltip>
            </Header>

            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<LoadingSpinner className='m-auto' />}>
                    <WorkspaceKpisList />
                </Suspense>
            </ErrorBoundary>
        </Panel>
    );
};

const Header = (props: ComponentPropsWithoutRef<'header'>) => {
    return <header {...props} className='flex items-center gap-2' />;
};

const WorkspaceKpisList = () => {
    const workspaceId = useWorkspaceId();
    const workspaceKpis = useWorkspaceKpis(workspaceId, { suspense: true });

    if (!workspaceKpis.data?.length) {
        return (
            <div className='flex flex-col items-center justify-center gap-4 m-auto'>
                <NoKpisInWorkspaceMessage />
            </div>
        );
    }

    return (
        <Grid>
            {workspaceKpis.data.map((workspaceKpi) => (
                <KpiBlock
                    to={`/dashboard/${workspaceKpi.dashboardId}`}
                    key={workspaceKpi.dashboardId + workspaceKpi.tileId}
                >
                    <KpiTitle data-status={workspaceKpi.status}>{workspaceKpi.formattedValue}</KpiTitle>

                    <TruncateWithTooltip title={workspaceKpi.name}>
                        <KpiSublabel>{workspaceKpi.name}</KpiSublabel>
                    </TruncateWithTooltip>
                </KpiBlock>
            ))}
        </Grid>
    );
};

const Grid = (props: ComponentPropsWithoutRef<'div'>) => {
    return (
        <div
            {...props}
            className='grid w-full max-h-full grid-cols-1 gap-2 overflow-auto xl:grid-cols-2 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'
        />
    );
};

const KpiBlock = (props: LinkProps) => {
    return (
        <Link
            to={props.to}
            className='flex justify-center items-center gap-1 bg-componentTileBlock px-5 py-5 flex-col transition-colors hover:bg-componentTileBlockHover [&>div]:max-w-full'
        >
            {props.children}
        </Link>
    );
};

const KpiTitle = (props: ComponentPropsWithoutRef<'h1'>) => {
    return (
        <Text.H1
            {...props}
            className='text-[max(1.5rem,3vmax)] leading-tight data-[status=error]:text-statusErrorPrimary data-[status=success]:text-statusHealthyPrimary data-[status=warning]:text-statusWarningPrimary data-[status=unknown]:text-textPrimary max-w-full truncate'
        />
    );
};

const KpiSublabel = (props: ComponentPropsWithoutRef<'p'>) => {
    return (
        <Text.Body
            className='text-[max(0.5rem,1vmax)] first-letter:capitalize text-textSecondary font-semibold max-w-full truncate'
            {...props}
        />
    );
};
