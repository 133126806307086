import type { DashboardType } from '@squaredup/dashboards';
import { isDashboard, isFolder, type DashboardFolder } from 'queries/utils/dashboardSorted';

// a boolean map of folder Id's
// We store true if the folder should be in edit mode upon rendering
// If we create a new folder outside of the dashboard tree, we should set it's id to true here
const folderInlineEditing = new Map<string, boolean>();

export const folderInitEdit = (folder: DashboardFolder) => folderInlineEditing.set(folder.id, true);

export const isFolderInitEdit = (folder: DashboardFolder) => folderInlineEditing.get(folder.id) || false;

export const removeFolderInitEdit = (folder: DashboardFolder) => folderInlineEditing.delete(folder.id);

export type Instruction =
    | {
          type: 'reorder-above';
          currentLevel: number;
      }
    | {
          type: 'reorder-below';
          currentLevel: number;
      }
    | {
          type: 'make-child';
          currentLevel: number;
      }
    | {
          type: 'reparent';
          currentLevel: number;
          desiredLevel: number;
      }
    | {
          type: 'instruction-blocked';
          desired: Exclude<Instruction, { type: 'instruction-blocked' }>;
          reason: string;
      }
    | { type: 'rename'; name: string }
    | { type: 'add-folder'; newFolder: DashboardFolder }
    | { type: 'remove-folder'; deleteChildren: boolean };

export const MAX_NESTING_DEPTH = 4;
export const INDENT_PER_LEVEL = 16;

export function getParentLevelOfInstruction(instruction: Instruction): number | undefined {
    if (instruction.type === 'instruction-blocked') {
        return getParentLevelOfInstruction(instruction.desired);
    }
    if (instruction.type === 'reparent') {
        return instruction.desiredLevel - 1;
    }

    return 'currentLevel' in instruction ? instruction.currentLevel - 1 : undefined;
}

export const tree = {
    remove(data: (DashboardType | DashboardFolder)[], id: string): (DashboardType | DashboardFolder)[] {
        return data
            .filter((item) => item.id !== id)
            .map((item) => {
                if (tree.hasChildren(item)) {
                    return {
                        ...item,
                        children: tree.remove(item.children, id)
                    };
                }
                return item;
            });
    },
    insertBefore(
        data: (DashboardFolder | DashboardType)[],
        targetId: string,
        newItem: DashboardFolder | DashboardType
    ): (DashboardType | DashboardFolder)[] {
        return data.flatMap((item) => {
            if (item.id === targetId) {
                return [newItem, item];
            }
            if (tree.hasChildren(item)) {
                return {
                    ...item,
                    children: tree.insertBefore(item.children, targetId, newItem)
                };
            }
            return item;
        });
    },
    insertAfter(
        data: (DashboardType | DashboardFolder)[],
        targetId: string,
        newItem: DashboardType | DashboardFolder
    ): (DashboardType | DashboardFolder)[] {
        return data.flatMap((item) => {
            if (item.id === targetId) {
                return [item, newItem];
            }

            if (tree.hasChildren(item)) {
                return {
                    ...item,
                    children: tree.insertAfter(item.children, targetId, newItem)
                };
            }

            return item;
        });
    },
    insertChild(
        data: (DashboardType | DashboardFolder)[],
        targetId: string,
        newItem: DashboardType | DashboardFolder
    ): (DashboardType | DashboardFolder)[] {
        return data.flatMap((item) => {
            if (item.id === targetId && isFolder(item)) {
                // already a parent: add as first child
                return {
                    ...item,
                    // opening item so you can see where item landed
                    children: [...(item.children || []), newItem]
                };
            }

            if (!tree.hasChildren(item)) {
                return item;
            }

            return {
                ...item,
                children: tree.insertChild(item.children, targetId, newItem)
            };
        });
    },
    find(data: (DashboardType | DashboardFolder)[], itemId: string): DashboardFolder | DashboardType | undefined {
        for (const item of data) {
            if (item.id === itemId) {
                return item;
            }

            if (tree.hasChildren(item)) {
                const result = tree.find(item.children, itemId);
                if (result) {
                    return result;
                }
            }
        }
    },
    getPathToItem<T extends boolean = false>({
        current,
        targetId,
        fullItem,
        parentIds = []
    }: {
        current: (DashboardFolder | DashboardType)[];
        targetId: string;
        fullItem?: T;
        parentIds?: (T extends true ? DashboardType | DashboardFolder : string)[];
    }): (T extends true ? DashboardType | DashboardFolder : string)[] | undefined {
        for (const item of current) {
            if (item.id === targetId) {
                return parentIds;
            }
            if (tree.hasChildren(item)) {
                const nested = tree.getPathToItem<T>({
                    current: item.children,
                    targetId: targetId,
                    fullItem,
                    parentIds: [...parentIds, fullItem ? item : item.id] as (T extends true
                        ? DashboardType | DashboardFolder
                        : string)[]
                });
                if (nested) {
                    return nested;
                }
            }
        }
    },

    getMaxDepth(item: DashboardType | DashboardFolder): number {
        if (isDashboard(item)) {
            return 0;
        }
        return Math.max(...(item.children?.map((child) => this.getMaxDepth(child)) || []), 0) + 1;
    },

    hasChildren(item: DashboardType | DashboardFolder): item is DashboardFolder {
        return isFolder(item) && item.children.length > 0;
    },
    getLastExpandedItemWithDepth(
        openFolders: Record<string, boolean>,
        item: DashboardType | DashboardFolder
    ): {
        item: DashboardFolder | DashboardType;
        depth: number;
    } {
        if (isDashboard(item) || item.children.length === 0 || !openFolders[item.id]) {
            return { item, depth: 0 };
        }

        const finalItem = this.getLastExpandedItemWithDepth(openFolders, item.children.at(-1)!);

        finalItem.depth += 1;

        return finalItem;
    }
};
