/* eslint-disable @typescript-eslint/no-shadow */
import clsx from 'clsx';
import deepEqual from 'fast-deep-equal/es6';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import GridItem, { GridItemCallback } from './GridItem';
import { PositionParams, RowHeight, calcGridColWidth, resolveRowHeight } from './calculateUtils';
import {
    bottom,
    cloneLayoutItem,
    compact,
    getAllCollisions,
    getLayoutItem,
    moveElement,
    moveElementsBack,
    noop,
    synchronizeLayoutWithChildren,
    withLayoutItem
} from './gridUtils';
import {
    CompactType,
    DragEventCallback,
    DragOverEvent,
    DroppedEvent,
    GridDragEvent,
    GridDragStopEvent,
    GridResizeEvent,
    Layout,
    LayoutItem,
    ResizeEventCallback,
    ResizeHandle,
    ResizeHandleAxis
} from './types';

const debounce = (callback: (...args: any[]) => void, wait: number) => {
    let timeoutId: number | undefined = undefined;
    return {
        fn: (...args: any[]) => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                callback(...args);
            }, wait);
        },
        cancel: () => {
            window.clearTimeout(timeoutId);
        }
    };
};

export type GridLayoutProps = {
    className: string;
    style: React.CSSProperties;
    width: number;
    autoSize: boolean;
    cols: number;
    draggableCancel: string;
    draggableHandle: string;
    compactType?: CompactType;
    layout: Layout;
    margin?: [number, number];
    containerPadding?: [number, number];
    rowHeight: RowHeight;
    maxRows: number;
    isBounded: boolean;
    isDraggable: boolean;
    isResizable: boolean;
    isDroppable: boolean;
    preventCollision: boolean;
    useCSSTransforms: boolean;
    transformScale: number;
    droppingItem: LayoutItem;
    resizeHandles: ResizeHandleAxis[];
    resizeHandle?: ResizeHandle;
    allowOverlap: boolean;

    // Callbacks
    onLayoutChange: (layout: Layout) => void;
    onDrag: DragEventCallback;
    onDragStart: DragEventCallback;
    onDragStop: DragEventCallback;
    onResize: ResizeEventCallback;
    onResizeStart: ResizeEventCallback;
    onResizeStop: ResizeEventCallback;
    onDropDragOver: (e: DragOverEvent) => ({ w?: number; h?: number } | false) | undefined | void;
    onDrop: (properties: DroppedEvent) => void;
    innerRef?: React.Ref<HTMLDivElement>;
};

const layoutClassName = 'react-grid-layout';

/**
 * A reactive, fluid grid layout with draggable, resizable components.
 */

const GridLayout: React.FC<Partial<GridLayoutProps>> = (properties) => {
    const {
        autoSize = true,
        cols = 12,
        className = '',
        style = {},
        draggableHandle = '',
        draggableCancel = '',
        containerPadding = undefined,
        rowHeight = 150,
        maxRows = Infinity, // infinite vertical growth
        margin = [10, 10],
        isBounded = false,
        isDraggable = true,
        isResizable = true,
        allowOverlap = false,
        useCSSTransforms = true,
        transformScale = 1,
        compactType = 'vertical',
        preventCollision = false,
        resizeHandles = ['se'],
        onLayoutChange = noop,
        onDragStart = noop,
        onDrag = noop,
        onDragStop = noop,
        onResizeStart = noop,
        onResize = noop,
        onResizeStop = noop,
        width = 0,
        resizeHandle,
        innerRef
    } = properties;

    // Refactored to another module to make way for preval
    const [activeDrag, setActiveDrag] = useState<LayoutItem>();
    const [mounted, setMounted] = useState<boolean>();
    const [oldDragItem, setOldDragItem] = useState<LayoutItem>();
    const [oldLayout, setOldLayout] = useState<Layout>();
    const [oldResizeItem, setOldResizeItem] = useState<LayoutItem>();
    const [children, setChildren] = useState<React.ReactNode>(properties.children);
    const [layout, setLayout] = useState<Layout>(() =>
        synchronizeLayoutWithChildren(properties.layout || [], children, cols, compactType, allowOverlap)
    );

    useEffect(() => {
        setMounted(true);
        // Possibly call back with layout on mount. This should be done after correcting the layout width
        // to ensure we don't rerender with the wrong width.
        onLayoutMaybeChanged(layout, layout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLayout(synchronizeLayoutWithChildren(properties.layout || [], children, cols, compactType, allowOverlap));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(properties.layout)]);

    useEffect(() => {
        const newLayout = synchronizeLayoutWithChildren(
            properties.layout || layout,
            properties.children,
            properties.cols || cols,
            properties.compactType,
            properties.allowOverlap
        );
        setLayout(newLayout);
        setChildren(properties.children);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [properties.children]);

    /**
     * Calculates a pixel value for the container.
     * @return {String} Container height in pixels.
     */
    const containerHeight = (): string | undefined => {
        if (!autoSize) {
            return;
        }
        const nbRow = bottom(layout);
        const containerPaddingY = containerPadding ? containerPadding[1] : margin[1];
        return (
            nbRow * resolveRowHeight(rowHeight, calcGridColWidth(getPositionParams())) +
            (nbRow - 1) * margin[1] +
            containerPaddingY * 2 +
            'px'
        );
    };

    /**
     * When dragging starts
     * @param {String} i Id of the child
     * @param {Number} x X position of the move
     * @param {Number} y Y position of the move
     * @param {Event} e The mousedown event
     * @param {Element} node The current dragging DOM element
     */
    const onDragStartFn: GridItemCallback<GridDragEvent> = (properties) => {
        const l = getLayoutItem(layout, properties.i);
        if (!l) {
            return;
        }

        setOldDragItem(cloneLayoutItem(l));
        setOldLayout(cloneDeep(layout));
        return onDragStart({
            layout,
            prev: l,
            item: l,
            placeholder: undefined,
            event: properties.data.e,
            node: properties.data.node
        });
    };

    /**
     * Each drag movement create a new dragElement and move the element to the dragged location
     * @param {String} i Id of the child
     * @param {Number} x X position of the move
     * @param {Number} y Y position of the move
     * @param {Event} e The mousedown event
     * @param {Element} node The current dragging DOM element
     */
    const onDragFn: GridItemCallback<GridDragEvent> = (properties) => {
        const l = getLayoutItem(layout, properties.i);
        if (!l) {
            return;
        }

        // Create placeholder (display only)
        const placeholder = {
            w: l.w,
            h: l.h,
            x: l.x,
            y: l.y,
            z: l.z,
            placeholder: true,
            i: properties.i
        };

        // Move the element to the dragged location.
        const isUserAction = true;
        const newLayout = moveElement({
            layout,
            l,
            x: properties.x,
            y: properties.y,
            isUserAction,
            preventCollision,
            compactType,
            cols,
            allowOverlap
        });

        onDrag({
            layout: newLayout,
            prev: oldDragItem,
            item: l,
            placeholder,
            event: properties.data.e,
            node: properties.data.node
        });

        let movedLayout: Layout = [];
        // Set state
        if (oldLayout) {
            const movedBack = moveElementsBack(l, newLayout, oldLayout);
            movedLayout = compact(movedBack, compactType, cols);
        } else {
            movedLayout = allowOverlap ? newLayout : compact(newLayout, compactType, cols);
        }

        setLayout(movedLayout);
    };

    const onDragFnDebounce = debounce(onDragFn, 75);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnDrag = useCallback(onDragFnDebounce.fn, [activeDrag, layout]);

    /**
     * When dragging stops, figure out which position the element is closest to and update its x and y.
     * @param  {String} i Index of the child.
     * @param {Number} x X position of the move
     * @param {Number} y Y position of the move
     * @param {Event} e The mousedown event
     * @param {Element} node The current dragging DOM element
     */
    const onDragStopFn: GridItemCallback<GridDragStopEvent> = (properties) => {
        onDragFnDebounce.cancel();

        const l = getLayoutItem(layout, properties.i);
        if (!l) {
            return;
        }

        // Move the element here
        const isUserAction = true;
        const movedLayout = moveElement({
            layout,
            l,
            x: properties.x,
            y: properties.y,
            isUserAction,
            preventCollision,
            compactType,
            cols,
            allowOverlap
        });

        onDragStop({
            layout: movedLayout,
            prev: oldDragItem,
            item: l,
            placeholder: undefined,
            event: properties.data.e,
            node: properties.data.node
        });

        let newLayout: Layout = [];
        // Set state
        if (oldLayout) {
            const movedBack = moveElementsBack(l, movedLayout, oldLayout);
            newLayout = compact(movedBack, compactType, cols);
        } else {
            newLayout = allowOverlap ? movedLayout : compact(movedLayout, compactType, cols);
        }
        setActiveDrag(undefined);
        setLayout(newLayout);
        setOldDragItem(undefined);
        setOldLayout(undefined);
        if (properties.data.change) {
            onLayoutChange(newLayout);
        }
    };

    const onLayoutMaybeChanged = (newLayout: Layout, oldLayout?: Layout) => {
        if (!oldLayout) {
            oldLayout = layout;
        }
        if (!deepEqual(oldLayout, newLayout)) {
            onLayoutChange(newLayout);
        }
    };

    const onResizeStartFn: GridItemCallback<GridResizeEvent> = (properties) => {
        const l = getLayoutItem(layout, properties.i);
        if (!l) {
            return;
        }

        setOldResizeItem(cloneLayoutItem(l));
        setOldLayout(layout);
        onResizeStart({
            layout,
            prev: l,
            item: l,
            event: properties.data.e,
            node: properties.data.node
        });
    };

    const onResizeFn: GridItemCallback<GridResizeEvent> = (properties) => {
        const [newLayout, l] = withLayoutItem(layout, properties.i, (l) => {
            // Something like quad tree should be used
            // to find collisions faster
            let hasCollisions;
            if (preventCollision && !allowOverlap) {
                const collisions = getAllCollisions(layout, {
                    ...l,
                    w: properties.x,
                    h: properties.y
                }).filter((layoutItem) => layoutItem.i !== l.i);
                hasCollisions = collisions.length > 0;

                // If we're colliding, we need adjust the placeholder.
                if (hasCollisions) {
                    // adjust w && h to maximum allowed space
                    let leastX = Infinity,
                        leastY = Infinity;
                    collisions.forEach((layoutItem) => {
                        if (layoutItem.x > l.x) {
                            leastX = Math.min(leastX, layoutItem.x);
                        }
                        if (layoutItem.y > l.y) {
                            leastY = Math.min(leastY, layoutItem.y);
                        }
                    });

                    if (Number.isFinite(leastX)) {
                        l.w = leastX - l.x;
                    }
                    if (Number.isFinite(leastY)) {
                        l.h = leastY - l.y;
                    }
                }
            }

            if (!hasCollisions) {
                // Set new width and height.
                l.w = properties.x;
                l.h = properties.y;
            }

            return l;
        });

        // Shouldn't ever happen, but typechecking makes it necessary
        if (!l) {
            return;
        }

        // Create placeholder element (display only)
        const placeholder = {
            w: l.w,
            h: l.h,
            x: l.x,
            y: l.y,
            z: l.z,
            static: true,
            i: properties.i
        };

        onResize({
            layout: newLayout,
            prev: oldResizeItem,
            item: l,
            placeholder,
            event: properties.data.e,
            node: properties.data.node
        });

        // Re-compact the newLayout and set the drag placeholder.
        setLayout(allowOverlap ? newLayout : compact(newLayout, compactType, cols));
        setActiveDrag(placeholder);
    };

    const onResizeStopFn: GridItemCallback<GridResizeEvent> = (properties) => {
        const l = getLayoutItem(layout, properties.i);
        onResizeStop({
            layout,
            prev: oldResizeItem,
            item: l,
            event: properties.data.e,
            node: properties.data.node
        });

        // Set state
        const newLayout = allowOverlap ? layout : compact(layout, compactType, cols);
        setActiveDrag(undefined);
        setLayout(newLayout);
        setOldResizeItem(undefined);
        setOldLayout(undefined);
        onLayoutMaybeChanged(newLayout, oldLayout);
    };

    /**
     * Create a placeholder object.
     * @return {Element} Placeholder div.
     */
    const placeholder = (): ReactElement<any> | undefined => {
        if (!activeDrag) {
            return;
        }

        // {...activeDrag} is pretty slow, actually
        return (
            <GridItem
                w={activeDrag.w}
                h={activeDrag.h}
                x={activeDrag.x}
                y={activeDrag.y}
                z={activeDrag.z || 0}
                i={activeDrag.i}
                className='react-grid-placeholder'
                containerWidth={width}
                cols={cols}
                margin={margin}
                containerPadding={containerPadding || margin}
                maxRows={maxRows}
                rowHeight={rowHeight}
                isDraggable={false}
                isResizable={false}
                isBounded={false}
                useCSSTransforms={useCSSTransforms}
                transformScale={transformScale}
            >
                <div />
            </GridItem>
        );
    };

    /**
     * Given a grid item, set its style attributes & surround in a <Draggable>.
     * @param  {Element} child React element.
     * @return {Element}       Element wrapped in draggable and properly placed.
     */
    const processGridItem = (child: ReactElement<any>): ReactElement<any> | undefined => {
        if (!child || !child.key) {
            return;
        }
        const l = getLayoutItem(layout, String(child.key));
        if (!l) {
            return;
        }

        // Determine user manipulations possible.
        // If an item is static, it can't be manipulated by default.
        // Any properties defined directly on the grid item will take precedence.
        const draggable = typeof l.isDraggable === 'boolean' ? l.isDraggable : !l.static && isDraggable;
        const resizable = typeof l.isResizable === 'boolean' ? l.isResizable : !l.static && isResizable;
        const resizeHandlesOptions = l.resizeHandles || resizeHandles;

        // isBounded set on child if set on parent, and child is not explicitly false
        const bounded = draggable && isBounded && l.isBounded !== false;

        return (
            <GridItem
                containerWidth={width}
                cols={cols}
                margin={margin}
                containerPadding={containerPadding || margin}
                maxRows={maxRows}
                rowHeight={rowHeight}
                cancel={draggableCancel}
                handle={draggableHandle}
                onDragStop={onDragStopFn}
                onDragStart={onDragStartFn}
                onDrag={debouncedOnDrag}
                onResizeStart={onResizeStartFn}
                onResize={onResizeFn}
                onResizeStop={onResizeStopFn}
                isDraggable={draggable}
                isResizable={resizable}
                isBounded={bounded}
                useCSSTransforms={useCSSTransforms && mounted}
                usePercentages={!mounted}
                transformScale={transformScale}
                w={l.w}
                h={l.h}
                x={l.x}
                y={l.y}
                z={l.z || 0}
                i={l.i}
                minH={l.minH}
                minW={l.minW}
                maxH={l.maxH}
                maxW={l.maxW}
                static={l.static}
                resizeHandles={resizeHandlesOptions}
                resizeHandle={resizeHandle}
            >
                {child}
            </GridItem>
        );
    };

    const getPositionParams = (): PositionParams => {
        return {
            cols,
            margin,
            maxRows,
            rowHeight,
            containerWidth: width,
            containerPadding: containerPadding || margin
        };
    };

    const mergedClassName = clsx(layoutClassName, className);
    const mergedStyle = {
        height: containerHeight(),
        ...style
    };

    return (
        <div ref={innerRef} className={mergedClassName} style={mergedStyle}>
            {
                children &&
                    (Array.isArray(children)
                        ? React.Children.map(children, (child) => processGridItem(child))
                        : processGridItem(children as any)) // TODO fix types
            }
            {placeholder()}
        </div>
    );
};
GridLayout.displayName = 'GridLayout';
export { GridLayout };
