import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WorkspaceStateIndicator } from 'components/ui/state/WorkspaceStateIndicator';
import { workspacePredefinedIconKey } from 'lib/fontawesome/fontawesome';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { useWorkspaceAvatar } from 'queries/hooks/useWorkspaceAvatar';

interface WorkspaceAvatarProps {
    workspaceId?: string;
    label?: string;
}

export const WorkspaceAvatar: React.FC<WorkspaceAvatarProps> = ({ workspaceId, label }) => {
    const { data: workspace } = useWorkspace(workspaceId);
    const { data: avatar } = useWorkspaceAvatar({
        id: workspaceId ?? '',
        enabled: !workspace?.data?.properties?.avatarIconName
    });

    if (!avatar && !workspace?.data?.properties?.avatarIconName) {
        return <WorkspaceStateIndicator id={workspaceId ?? ''} size={46} className='relative z-10' />;
    }

    if (workspace?.data?.properties?.avatarIconName) {
        return (
            <FontAwesomeIcon
                className='text-textPrimary w-[30px] h-[30px] z-10'
                icon={workspace.data.properties.avatarIconName.replace(workspacePredefinedIconKey, '') as IconProp}
            />
        );
    }

    if (avatar && avatar?.startsWith(workspacePredefinedIconKey)) {
        return (
            <FontAwesomeIcon
                className='text-textPrimary w-[30px] h-[30px] z-10'
                icon={avatar.replace(workspacePredefinedIconKey, '') as IconProp}
            />
        );
    }

    return (
        <img
            src={avatar}
            alt={`${label} avatar`}
            className={
                'w-[46px] h-[46px] object-contain left-[12px] top-[12px] rounded-full overflow-hidden z-10 bg-white'
            }
        />
    );
};
