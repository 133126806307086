import { Button } from '@/components/Button';
import { cn } from '@/lib/cn';
import { faSliders, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { scopeLimitMaximum } from '@squaredup/constants';
import { Node } from '@squaredup/graph';
import LoadingSpinner from 'components/LoadingSpinner';
import { TruncatedText } from 'components/TruncatedText';
import stringify from 'fast-json-stable-stringify';
import { uniq } from 'lodash';
import { MouseEvent, useRef } from 'react';
import { useTileEditorObjectsFilterContext } from '../../contexts/TileEditorObjectsFilterContext';
import { FilterType } from '../hooks/useDataStreamObjectFilters';
import { ObjectList } from './ObjectList';
import { ObjectOption } from './ObjectOption';
import { AddPropertyFilter } from './filter/AddPropertyFilter';

const tableHeaders = ['Name', 'Data Source', 'Type'];

export const ObjectTable: React.FC = () => {
    const {
        objects,
        count,
        objectLimit,
        filterQuery,
        filterSources,
        filterTypes,
        pluginLookup,
        isFetchingObjects,
        isLoadingObjects,
        hasNextObjectsPage,
        isFetchingNextObjectsPage,
        selectedObjects,
        selectedObjectsCount,
        selectedAllObjects,
        isDynamic,
        isFiltered,
        filterProperties,
        hasVariables,
        handleFixedScope,
        handleSetFilterState,
        fetchNextObjectsPage,
        resetFilters,
        setFilterProperties
    } = useTileEditorObjectsFilterContext();

    const canSelectObject = !isDynamic && !hasVariables;

    const lastChecked = useRef<number | null>(null);

    const resetScrollKey = stringify({ filterQuery, filterSources, filterTypes, filterProperties });

    if ((!objects || objects?.length === 0) && isLoadingObjects) {
        return (
            <div className='flex flex-col items-center justify-center flex-1 w-full min-h-0'>
                <LoadingSpinner size={20} />
            </div>
        );
    }

    if (!objects || !count || objects?.length === 0) {
        return (
            <p className='flex-1 text-sm'>
                No objects available.{' '}
                {isFiltered && (
                    <Button variant='tertiary' onClick={resetFilters} className='text-textLink'>
                        Reset filters
                    </Button>
                )}
            </p>
        );
    }

    const filterPropertiesColumns = Object.keys(filterProperties);
    const headers = [
        ...tableHeaders,
        ...filterPropertiesColumns,
        <AddPropertyFilter
            triggerClassName='inline-flex items-center justify-end h-full'
            triggerTooltip='Add column'
            triggerContent={<FontAwesomeIcon icon={faSliders} />}
            triggerTestid='AddColumnToObjectTable'
            align='end'
            forceEnabled
        />
    ];

    const handleObjectClick = (e: MouseEvent<HTMLButtonElement>, index: number) => {
        const object = objects[index];

        // Shift click allows selection of multiple objects at once
        if (lastChecked.current !== null && e.nativeEvent.shiftKey) {
            const start = Math.min(lastChecked.current, index);
            const end = Math.max(lastChecked.current, index);

            const shiftSelectedObjects = objects.slice(start, end + 1).map(({ id }) => id);

            // The object the user is clicking is already selected, deselect it and all objects in between
            if (selectedObjects.includes(object.id)) {
                handleFixedScope(uniq([...selectedObjects.filter((id) => !shiftSelectedObjects.includes(id))]));
            } else {
                // Add all the objects between the last check object and this one
                handleFixedScope(uniq([...selectedObjects, ...shiftSelectedObjects]).slice(0, objectLimit));
            }
            return;
        }

        lastChecked.current = index;
        if (!selectedObjects.includes(object.id)) {
            handleFixedScope([...selectedObjects, object.id]);
        } else {
            handleFixedScope(selectedObjects.filter((id) => id !== object.id));
        }
    };

    return (
        <div className='flex flex-col flex-1 min-h-0 text-sm'>
            {objects.length > 0 && (
                <>
                    <div
                        className='grid self-stretch gap-6 p-1 px-2 mr-4 font-semibold text-left select-none [scrollbar-gutter:stable]'
                        style={{
                            gridTemplateColumns: `minmax(10rem, 1fr) repeat(${
                                headers.length - 2
                            }, minmax(0, 1fr)) 0.875rem`
                        }}
                    >
                        {headers.map((header) => {
                            const isPropertyHeader =
                                typeof header === 'string' && filterPropertiesColumns.includes(header);

                            return typeof header === 'string' ? (
                                <span
                                    key={header}
                                    className={cn('flex items-center space-x-2', isPropertyHeader && 'group/header')}
                                >
                                    <TruncatedText
                                        key={header}
                                        title={header}
                                        className='min-w-0 capitalize align-middle'
                                    />

                                    {isPropertyHeader && (
                                        <button
                                            className='items-center hidden h-full group-hover/header:inline-flex'
                                            onClick={() => {
                                                const { [header]: _, ...newFilterProperties } = filterProperties;
                                                handleSetFilterState(
                                                    FilterType.properties,
                                                    newFilterProperties,
                                                    setFilterProperties
                                                );
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTimes} className='align-middle' />
                                        </button>
                                    )}
                                </span>
                            ) : (
                                header
                            );
                        })}
                    </div>

                    <ObjectList
                        objects={objects}
                        selectedObjects={selectedObjects}
                        resetScrollKey={resetScrollKey}
                        hasNextObjectsPage={hasNextObjectsPage}
                        isFetchingNextObjectsPage={isFetchingNextObjectsPage}
                        isFetchingObjects={isFetchingObjects}
                        fetchNextObjectsPage={fetchNextObjectsPage}
                        renderObjectRow={(object: Node, index: number) => {
                            const isSelected = selectedObjects.includes(object.id);
                            return (
                                <ObjectOption
                                    object={object}
                                    isActive={isSelected || selectedAllObjects}
                                    className={index % 2 === 0 ? 'bg-tagBackground' : ''}
                                    checkboxDisabled={
                                        (selectedObjectsCount >= scopeLimitMaximum && !isSelected) ||
                                        (Boolean(objectLimit) &&
                                            selectedObjects.length >= (objectLimit ?? 0) &&
                                            !isSelected)
                                    }
                                    filterPropertiesColumns={filterPropertiesColumns}
                                    pluginLookup={pluginLookup}
                                    // We only want to pass onClick if we're selecting a
                                    // fixed scope (!dynamic)
                                    {...(canSelectObject && {
                                        onClick: (e) => handleObjectClick(e, index)
                                    })}
                                />
                            );
                        }}
                    />
                </>
            )}
        </div>
    );
};
