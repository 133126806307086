import { cn } from '@/lib/cn';
import { stateStrings } from '@squaredup/monitoring';
import { healthStateTextColors } from 'constants/state';
import { HealthState } from 'dashboard-engine/types/data/StateData';

const WorkspaceNode: React.FC<{
    id?: string;
    className?: string;
    state?: HealthState;
    size?: number;
    isSelected?: boolean;
    dataTestId?: string;
}> = ({ className, state, size = 120, isSelected, dataTestId }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 62 62'
            width={size}
            height={size}
            fill='none'
            className={cn(className, healthStateTextColors[state ?? stateStrings.unknown])}
            data-testid={dataTestId}
        >
            {isSelected && (
                <>
                    <path
                        d='M5.15321 16.0774L31 1.1547L56.8468 16.0774V45.9226L31 60.8453L5.15321 45.9226V16.0774Z'
                        className='fill-backgroundSecondary'
                    />
                    <path
                        d='M5.10449 13.6835V43.5851L31.0001 58.5359L56.8956 43.5851V13.6835L59.8956 11.9514V45.3171L31.0001 62L2.10449 45.3171V11.9514L5.10449 13.6835Z'
                        className='fill-current'
                    />
                </>
            )}
            <path
                d='M10.8494 17.0001L31 5.36618L51.1506 17.0001V40.2681L31 51.9021L10.8494 40.2681V17.0001Z'
                className='stroke-current fill-backgroundPrimary'
                strokeWidth='3'
            />
            <path opacity='0.5' d='M31 27.5936L10.3478 16.637V40.5501L31 52.5066V27.5936Z' className='fill-current' />
            <path
                d='M31.1553 27.6618L51.8075 16.7116L30.718 4.80453L10.1926 16.4817L31.1553 27.6618Z'
                className='fill-current'
            />
        </svg>
    );
};

export default WorkspaceNode;
