import { stateStrings } from '@squaredup/monitoring';
import { useAppContext } from 'contexts/AppContext';
import { useDashboardHealthStates } from 'queries/hooks/useDashboardHealthStates';
import { FC, SVGProps } from 'react';
import { StateIndicator } from './StateIndicator';

interface DashboardStateIndicatorProps extends SVGProps<SVGSVGElement> {
    dashboardId: string;
    workspaceId?: string;
    className?: string;
}

export const DashboardStateIndicator: FC<DashboardStateIndicatorProps> = ({ dashboardId, workspaceId, className }) => {
    const { currentWorkspaceID } = useAppContext();
    const { data: dashboardStates } = useDashboardHealthStates(workspaceId ?? currentWorkspaceID);

    const state = dashboardStates?.find(
        ({ id }) => dashboardId === id
    )?.state ?? stateStrings.unknown;

    return (
        <StateIndicator
            state={state}
            className={className}
        />
    );
};