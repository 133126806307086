import { Serialised } from '@squaredup/ids';
import Tooltip from 'components/tooltip/Tooltip';
import type { ProjectedPlugin } from 'dynamo-wrapper';
import trackEvent from 'lib/analytics';
import useOverflowing from 'lib/useOverflowing';
import PluginIcon from 'pages/scope/PluginIcon';
import { PluginPill } from 'pages/settings/plugins/components/PluginPill';
import { PLUGIN } from 'pages/settings/plugins/components/usePlugin';
import { useQueryClient } from 'react-query';
import { Get } from 'services/PluginService';

export interface PluginCardProps {
    plugin: Serialised<ProjectedPlugin>;
    onClick?: (plugin: Serialised<ProjectedPlugin>) => void;
}

export function PluginCard({ plugin, onClick }: PluginCardProps) {
    const queryClient = useQueryClient();

    const [ref, isOverflowing] = useOverflowing<HTMLHeadingElement>();
    return (
        <div
            onClick={() => {
                onClick && onClick(plugin);
                trackEvent('Plugin Selected', { type: plugin.displayName });
            }}
            onMouseEnter={() => queryClient.prefetchQuery({
                queryKey: [PLUGIN, plugin.id], 
                queryFn: async () => Get(plugin.id),
                staleTime: 30_000,
                cacheTime: 30_000
            })}
            data-testid={plugin.id}
            aria-label={plugin.displayName + (plugin.onPrem ? ' On-Prem' : ' Cloud')}
            className='h-full p-4 text-sm border cursor-pointer border-outlinePrimary rounded-input w-items-center group last:mt-0 bg-componentBackgroundSecondary hover:bg-tileBackground'
        >
            <div className='flex mb-3'>
                <div className='mr-3 w-14 h-14 shrink-0'>
                    <PluginIcon pluginName={plugin.displayName!} />
                </div>
                <div className='overflow-hidden'>
                    <Tooltip title={plugin.displayName} disabled={!isOverflowing}>
                        <h3 ref={ref} className='mb-1 font-bold leading-4 truncate whitespace-nowrap'>
                            {plugin.displayName}
                        </h3>
                    </Tooltip>

                    <p className='text-xs font-normal text-textSecondary'>By {plugin.author}</p>
                </div>
                <div className='pl-2 ml-auto'>
                    <PluginPill onPrem={plugin.onPrem} />
                </div>
            </div>
            <p className='text-sm max-h-10 line-clamp-2 text-textSecondary'>{plugin.description}</p>
        </div>
    );
}
