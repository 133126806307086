import { cn } from '@/lib/cn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TruncatedText } from 'components/TruncatedText';
import DropdownMenu from 'components/dropdownMenu';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { useState } from 'react';
import { resolveEachMatchedType } from '../../utilities/resolveEachMatchedType';
import { SearchBar } from '../SearchBar';
import { useFilterSearchItems } from '../hooks/useFilterSearchItems';

interface TypeFilterDropdownProps {
    types?: string[];
    selectedType?: string;
    setSelectedType: (value: string | undefined) => void;
}

const searchKeys = [{ name: 'name', weight: 10 }];

export const TypeFilterDropdown: React.FC<TypeFilterDropdownProps> = ({
    types = [],
    selectedType,
    setSelectedType
}) => {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const menuContainer = useDOMElement('dialogContent');

    const resolvedTypes = types.map((type) => ({
        type,
        ...resolveEachMatchedType([type])[0]
    }));

    const { searchItems, setSearchTerm } = useFilterSearchItems({
        items: resolvedTypes,
        keys: searchKeys
    });

    const resolvedSelectedType = selectedType ? resolveEachMatchedType([selectedType])[0] : undefined;

    const handleDropdownOpenChange = (open: boolean) => {
        setSearchTerm('');
        setIsDropdownOpened(open);
    };

    return (
        <DropdownMenu onOpenChange={handleDropdownOpenChange} modal={false}>
            <DropdownMenu.Button
                active={Boolean(selectedType)}
                className='whitespace-nowrap'
                dropdownOpen={isDropdownOpened}
                showChevron
                useFilterStyle
                data-testid='typeFilter'
            >
                {resolvedSelectedType ? (
                    <div className='inline-flex align-middle'>
                        <div className='w-5 h-5 mr-3'>
                            {resolvedSelectedType && <FontAwesomeIcon icon={resolvedSelectedType.icon} />}
                        </div>
                        <TruncatedText
                            title={resolvedSelectedType.name}
                            className='flex-1 min-w-0 max-w-[80px] truncate'
                        />
                    </div>
                ) : (
                    'Type'
                )}
            </DropdownMenu.Button>
            <DropdownMenu.Menu align='end' className='w-[260px]' container={menuContainer}>
                <SearchBar placeholder='Search for a type...' onChange={setSearchTerm} className='mb-3' />
                <DropdownMenu.Group className='max-h-[320px] overflow-y-scroll scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                    {searchItems?.map(({ type, name, icon }) => {
                        return (
                            <DropdownMenu.Item
                                key={type}
                                className={cn({
                                    'bg-dividerPrimary': selectedType === type
                                })}
                                onSelect={() => setSelectedType(selectedType === type ? undefined : type)}
                            >
                                <div className='flex'>
                                    <div className='w-5 h-5 mr-3'>
                                        <FontAwesomeIcon icon={icon} />
                                    </div>
                                    <TruncatedText title={name ?? ''} className='flex-1 min-w-0 truncate' />
                                </div>
                            </DropdownMenu.Item>
                        );
                    })}
                </DropdownMenu.Group>
                <DropdownMenu.Separator />
                <DropdownMenu.SecondaryItem disabled={!selectedType} onSelect={() => setSelectedType(undefined)}>
                    Clear filter
                </DropdownMenu.SecondaryItem>
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
};
