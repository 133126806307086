import { ChoiceChips } from 'components/forms/choiceChips/ChoiceChips';
import Field from 'components/forms/field/Field';
import { FormProvider, useForm } from 'react-hook-form';
import { useFormChange } from 'ui/editor/dataStream/hooks/useFormChange';
import { FormDropdown } from '../DataStreamBarChart/components/FormDropdown';
import { DataStreamDonutConfig } from './Config';
import { AccordionMultipleProps } from '@radix-ui/react-accordion';
import { VisualisationConfigAccordion } from '../VisualisationConfigAccordion';

const legendPositionOptions = [
    { label: 'Auto', value: 'auto' },
    { label: 'Bottom', value: 'bottom' },
    { label: 'Top', value: 'top' },
    { label: 'Left', value: 'left' },
    { label: 'Right', value: 'right' }
];

interface DonutConfigurationFormProps {
    config: DataStreamDonutConfig;
    accordionControls: AccordionMultipleProps;
    onChange: (data: any) => void;
}

export const DonutConfigurationForm = ({ config, accordionControls, onChange }: DonutConfigurationFormProps) => {
    const defaultValues = {
        legendMode: config.legendMode ?? 'inline',
        legendPosition: config.legendPosition ?? 'auto'
    };

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues
    });

    const { watch } = formMethods;

    useFormChange(watch, (data) => {
        onChange({ action: 'form-change', data });
    });

    return (
        <FormProvider {...formMethods}>
            <form className='w-full'>
                <VisualisationConfigAccordion
                    value='legend'
                    label='Legend'
                    accordionControls={accordionControls}
                >
                    <ChoiceChips
                        name='legendMode'
                        label='Type'
                        options={[
                            {
                                label: 'Inline',
                                value: 'inline'
                            },
                            {
                                label: 'Table',
                                value: 'table'
                            }
                        ]}
                        defaultValue={config?.legendMode ?? 'inline'}
                    />
                    {config?.legendMode === 'table' && (
                        <Field 
                            label='Position'
                            className='mt-[9px]'
                        >
                            <FormDropdown name='legendPosition' options={legendPositionOptions} />
                        </Field>
                    )}
                </VisualisationConfigAccordion>
            </form>
        </FormProvider>
    );
};
