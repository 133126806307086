import clsx, { ClassValue } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';
import { squaredupTailwindConfig } from 'tailwind.config';

type ThemeObject = Exclude<
    Exclude<Parameters<typeof extendTailwindMerge>[0], (...a: any) => unknown>['theme'],
    undefined
>;

const theme = Object.entries(squaredupTailwindConfig.theme.extend).reduce((config, [property, values]) => {
    config[property] = Object.keys(values);
    return config;
}, {} as ThemeObject);

const tailwindMerge = extendTailwindMerge({ theme });

/**
 * @param inputs input css classes
 * @returns merged css className string with unique property-level tailwind classNames the final input has precedence
 */

export const cn = (...inputs: ClassValue[]) => tailwindMerge(clsx(inputs));
