import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { LoadingWrapper } from 'components/LoadingWrapper';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import DataStreamTable from 'dashboard-engine/visualisations/DataStreamTable/DataStreamTable';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import { cn } from '@/lib/cn';

interface UnshapedDataTableProps {
    config?: DataStreamBaseTileConfig;
    rawHeaderLabels?: boolean;
    className?: string;
}

export const UnshapedDataTable: React.FC<UnshapedDataTableProps> = ({ config, className, rawHeaderLabels }) => {
    const { tileConfig } = useTileEditorContext();
    const tableConfig = config ?? tileConfig;

    const { data, isLoading } = useDataStreamConfig({
        ...tableConfig,
        dataStream: {
            ...tableConfig.dataStream,
            filter: undefined,
            group: undefined,
            sort: undefined
        }
    });

    return (
        <div className={cn('h-full', className)}>
            <LoadingWrapper loading={isLoading}>
                <DataStreamTable
                    data={data}
                    config={{
                        showShapeInTooltip: true,
                        useAccessorHeaderLabels: rawHeaderLabels
                    }}
                />
            </LoadingWrapper>
        </div>
    );
};
