import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { TileConfig } from '@squaredup/dashboards';
import type { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import clsx from 'clsx';
import { StateIndicator } from 'components/ui/state/StateIndicator';
import { healthReasonMessage } from 'dashboard-engine/util/monitoring';
import { TileState } from 'queries/types/types';
import { ReactNode } from 'react';
import EasyEdit from 'react-easy-edit';
import EasyEditOverflowWrapper from './EasyEditOverflowWrapper';
import { KPIIcon } from './KPIIcon';
import { TileHeaderActions } from './TileHeaderActions';
import { TileScopeLimitInfo } from './TileScopeLimitInfo';
import { TileTimeframe } from './TileTimeframe';
import { TileWarnings } from './TileWarnings';

interface TileHeaderProps {
    tileId: string;
    title: string;
    description: string;
    config: TileConfig;
    editing: boolean;
    preview: boolean;
    health?: TileState;
    tileToolbar: ReactNode;
    draggable: boolean;
    supportsEditingTitle: boolean;
    onSave: (field: 'title' | 'description', value: string | undefined) => void;
    onDownloadAsImage?: () => Promise<void>;
}
export const TileHeader: React.FC<TileHeaderProps> = ({
    tileId,
    title,
    description,
    config,
    editing,
    preview,
    health,
    tileToolbar,
    draggable,
    supportsEditingTitle,
    onSave,
    onDownloadAsImage
}) => {
    const statusMessage = healthReasonMessage(health?.state, health?.stateReason);
    const isMonitored = Boolean('monitor' in config && config.monitor);

    if (preview) {
        return null;
    }

    // Prevent saving empty strings, by trimming the value before saving
    const onTextSave = (field: 'title' | 'description', value: string | null) => {
        onSave(field, value?.trim() || '');
    };

    return (
        !preview && (
            <>
                {editing && draggable && (
                    <FontAwesomeIcon
                        size='lg'
                        fixedWidth
                        icon={faGripVertical}
                        className='absolute left-1 cursor-grab top-2.5 text-textSecondary hover:text-textPrimary drag-handle select-none hide-for-image-export'
                        data-testid='tileDragHandle'
                        // Prevent text selection when dragging
                        onMouseDown={(e) => e.preventDefault()}
                    />
                )}
                <div className={clsx('flex items-start justify-between mb-1', editing && !preview && 'pl-4')}>
                    {supportsEditingTitle && (title || description || editing || isMonitored) && (
                        <h2
                            className={clsx('min-w-0 text-xl leading-10 align-bottom flex-grow', isMonitored && 'pl-5')}
                        >
                            <div
                                className='flex items-center w-full mr-5 space-x-2 text-base font-bold'
                                data-testid='tileTitle'
                            >
                                {isMonitored && (
                                    <StateIndicator
                                        state={health?.state}
                                        className='w-3 h-3 my-1.5 -ml-5'
                                        titleOverride={statusMessage}
                                    />
                                )}

                                <div className='flex flex-1 w-full gap-x-sm'>
                                    <Text.H3 className={cn('flex-1', !editing && !title ? 'hidden' : 'truncate')}>
                                        <EasyEdit
                                            type='text'
                                            onSave={(v: string | null) => onTextSave('title', v)}
                                            value={title || undefined}
                                            saveOnBlur={true}
                                            allowEdit={editing}
                                            placeholder='Click to add title'
                                            displayComponent={
                                                <EasyEditOverflowWrapper
                                                    value={title}
                                                    placeholder='Click to add title'
                                                />
                                            }
                                        />
                                    </Text.H3>

                                    <KPIIcon kpiConfig={(config as DataStreamBaseTileConfig)?.kpi} />
                                </div>
                            </div>

                            <div
                                className='flex flex-row w-full my-1 text-sm text-textSecondary'
                                data-testid='tileDescription'
                            >
                                <div
                                    className={cn(
                                        'flex-1',
                                        (description != null && description.length > 0) || editing
                                            ? 'truncate'
                                            : 'hidden'
                                    )}
                                >
                                    <Text.SmallBody>
                                        <EasyEdit
                                            type='text'
                                            onSave={(v: string | null) => onTextSave('description', v)}
                                            value={description || undefined}
                                            saveOnBlur={true}
                                            allowEdit={editing}
                                            placeholder='Click to add description'
                                            displayComponent={
                                                <EasyEditOverflowWrapper
                                                    value={title}
                                                    placeholder='Click to add description'
                                                />
                                            }
                                        />
                                    </Text.SmallBody>
                                </div>
                            </div>
                        </h2>
                    )}

                    <div
                        className='flex items-center pl-2 ml-auto gap-xxxs hide-for-image-export -mr-xxs'
                        data-testid='tileHeader'
                    >
                        {config._type === 'tile/data-stream' && (
                            <>
                                <TileTimeframe config={config as DataStreamBaseTileConfig} />
                                <TileScopeLimitInfo config={config as DataStreamBaseTileConfig} />
                            </>
                        )}

                        <div id={`${tileId}Toolbar`} className='flex gap-x-xxs' />

                        {tileToolbar}

                        <TileHeaderActions />

                        {config._type === 'tile/data-stream' && (
                            <TileWarnings config={config as DataStreamBaseTileConfig} />
                        )}

                        {!editing && onDownloadAsImage && (
                            // For use by Puppeteer when generating tile previews for notifications.
                            <div id={`tile-download-${tileId}`} className='hidden' onClick={onDownloadAsImage} />
                        )}
                    </div>
                </div>
            </>
        )
    );
};
