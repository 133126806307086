import Text from '@/components/Text';
import { Button, buttonVariants } from 'components/button';
import { useCreateDashboardAndNavigate } from 'components/hooks/useCreateDashboardAndNavigate';
import { LINKED_PLUGINS } from 'components/hooks/useLinkedPluginConfigs';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useWorkspaceCanWrite } from 'services/AccessControlService';
import type { PluginSourceConfig } from 'services/SourceConfigService';
import { useWorkspaceId } from './WorkspaceHome';
import pluralize from 'pluralize';

export const NoDataSourcesAndDashboardsInWorkspace = () => {
    const workspaceId = useWorkspaceId();
    const navigate = useNavigate();

    const workspaceCanWrite = useWorkspaceCanWrite(workspaceId);
    const canWrite = workspaceCanWrite.data;

    const navigateToDatasource = () => {
        navigate(`/datasources/${workspaceId}`);
    };

    if (!canWrite) {
        return (
            <>
                <Text.H2>Looks a bit empty here</Text.H2>

                <Text.H3>No data sources | No dashboards </Text.H3>
            </>
        );
    }

    return (
        <>
            <Text.H2>Let's get started</Text.H2>

            <Text.Body className='text-textSecondary'>Add a data source to start surfacing insights.</Text.Body>

            <Text.H3>No data sources | No dashboards </Text.H3>

            <Button onClick={navigateToDatasource}>Add data source</Button>
        </>
    );
};

export const NoDashboardsInWorkspaceMessage = () => {
    const queryClient = useQueryClient();
    const workspaceId = useWorkspaceId();
    const { createDashboardAndNavigate } = useCreateDashboardAndNavigate();

    const linkedDataSources = queryClient.getQueryState<PluginSourceConfig[]>([LINKED_PLUGINS, workspaceId]);
    const numberOfDataSources = linkedDataSources?.data?.length ?? 0;

    const workspaceCanWrite = useWorkspaceCanWrite(workspaceId);
    const canWrite = workspaceCanWrite.data;

    if (!canWrite) {
        return (
            <>
                <Text.H2>Looks a bit empty here</Text.H2>

                <Text.H3>
                    {numberOfDataSources} data {pluralize('source', numberOfDataSources)} | No
                    dashboards
                </Text.H3>
            </>
        );
    }

    return (
        <>
            <Text.H2>Let's continue</Text.H2>

            <Text.Body className='text-textSecondary'>Add a dashboard to start surfacing insights.</Text.Body>

            <Text.H3>
                {numberOfDataSources} data {pluralize('source', numberOfDataSources)} | No dashboards
            </Text.H3>

            <Button onClick={createDashboardAndNavigate}>Add dashboard</Button>
        </>
    );
};

export const NoDataSourcesInWorkspaceMessage = () => {
    const workspaceId = useWorkspaceId();

    const workspaceCanWrite = useWorkspaceCanWrite(workspaceId);
    const canWrite = workspaceCanWrite.data;

    if (!canWrite) {
        return <Text.Body className='text-textSecondary'>There are no data sources in this workspace.</Text.Body>;
    }

    return (
        <>
            <Text.Body className='text-textSecondary'>There are no data sources in this workspace.</Text.Body>
            <Link to={`/datasources/${workspaceId}`} className={buttonVariants({ variant: 'secondary' })}>
                Add data source
            </Link>
        </>
    );
};

export const NoMonitorsInWorkspaceMessage = () => {
    const workspaceId = useWorkspaceId();

    const workspaceCanWrite = useWorkspaceCanWrite(workspaceId);
    const canWrite = workspaceCanWrite.data;

    if (!canWrite) {
        return <Text.Body>There are no monitors in this workspace.</Text.Body>;
    }

    return (
        <>
            <Text.Body>There are no monitors in this workspace.</Text.Body>
            <Text.Body>
                Enable monitoring on a tile in a dashboard.{' '}
                <Button href='https://squaredup.com/cloud/monitoring' variant='link'>
                    Learn more
                </Button>
            </Text.Body>
        </>
    );
};

export const NoMonitorsMatchingFiltersCriteriaMessage = () => {
    return (
        <>
            <Text.Body>There are no monitors matching the filter criteria.</Text.Body>
            <Text.Body>Try adjusting your filters.</Text.Body>
        </>
    );
};

export const NoDashboardsMatchingFiltersCriteriaMessage = () => {
    return (
        <>
            <Text.Body>There are no dashboards matching the filter criteria.</Text.Body>
            <Text.Body>Try adjusting your filters.</Text.Body>
        </>
    );
};
export const NoKpisInWorkspaceMessage = () => {
    return (
        <>
            <Text.Body className='text-textSecondary'>There are no KPIs in this workspace.</Text.Body>
            <Text.Body className='text-textSecondary'>
                Enable KPI on a tile in a dashboard.{' '}
                <Button href='https://squaredup.com/cloud/kpis' variant='link'>
                    Learn more
                </Button>
            </Text.Body>
        </>
    );
};
