import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import Form from 'components/forms/form/Form';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import trackEvent from 'lib/analytics';
import { useQueryClient } from 'react-query';
import { CreateType, KPI_TYPES, UpdateType } from 'services/KPIService';

const description = (
    <>
        KPI Types allow you to define the available types for any published KPIs. Read the{' '}
        <a href='https://squaredup.com/cloud/kpis' target='_blank' rel='noreferrer noopener' className='text-textLink'>
            KPIs help
        </a>{' '}
        for more information.
    </>
);

function KPITypeModal({ type, close }) {
    const queryClient = useQueryClient();

    const defaultValues = {
        displayName: type?.displayName
    };

    const handleSubmit = async (data) => {
        const { displayName } = data;

        if (type && type.id) {
            await UpdateType(type.id, displayName);
        } else {
            await CreateType(displayName);
            trackEvent('KPI Type Created', { name: displayName });
        }

        queryClient.invalidateQueries(KPI_TYPES);

        await close();
    };

    return (
        <Modal
            title={type ? `Edit KPI type: ${type.displayName}` : 'Add KPI type'}
            close={close}
            description={description}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <Form submit={handleSubmit} defaultValues={defaultValues} className='flex flex-col flex-1 min-h-0'>
                {(isValid, isSubmitting) => (
                    <>
                        <div className='px-8 pt-8 tile-scroll-overflow'>
                            <Field.Input
                                name='displayName'
                                label='Name'
                                title='Name'
                                placeholder='KPI Type Name'
                                validation={{
                                    required: true,
                                    maxLength: { value: 128 },
                                    minLength: { value: 1 }
                                }}
                            />
                        </div>

                        <ModalButtons>
                            <Button type='button' onClick={close} variant='tertiary' data-testid='cancelKPIType'>
                                Cancel
                            </Button>
                            <Button type='submit' disabled={isSubmitting || !isValid} data-testid='submitKPIType'>
                                {isSubmitting ? <LoadingSpinner size={18} /> : 'Save'}
                            </Button>
                        </ModalButtons>
                    </>
                )}
            </Form>
        </Modal>
    );
}

export default KPITypeModal;
