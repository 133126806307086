import Config from '../../config';

function isProd(): boolean {
    return !(Config.Environment === 'Development' || Config.Environment === 'Local');
}

export const STARTER_MONTHLY_PADDLE_ID = isProd() ? 786949 : 33551;
export const STARTER_ANNUALLY_PADDLE_ID = isProd() ? 786950 : 33552;
export const PRO_MONTHLY_PADDLE_ID = isProd() ? 786947 : 33516;
export const PRO_ANNUALLY_PADDLE_ID = isProd() ? 786948 : 33517;
export const ENTERPRISE_FAKE_ID = -1;

export const planOptions = [
    { label: 'Starter (Monthly)', value: STARTER_MONTHLY_PADDLE_ID },
    { label: 'Starter (Annually)', value: STARTER_ANNUALLY_PADDLE_ID },
    { label: 'Pro (Monthly)', value: PRO_MONTHLY_PADDLE_ID },
    { label: 'Pro (Annually)', value: PRO_ANNUALLY_PADDLE_ID },
    { label: 'Enterprise', value: ENTERPRISE_FAKE_ID }
];

export const planRanges: { [key: number]: { min: number; max: number } } = {
    [STARTER_MONTHLY_PADDLE_ID]: { min: 1, max: 1000 },
    [STARTER_ANNUALLY_PADDLE_ID]: { min: 1, max: 1000 },
    [PRO_MONTHLY_PADDLE_ID]: { min: 5, max: 1000 },
    [PRO_ANNUALLY_PADDLE_ID]: { min: 5, max: 1000 },
    [ENTERPRISE_FAKE_ID]: { min: 10, max: 100 }
};
