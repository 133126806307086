import { TimeframeEnumValue } from '@squaredup/timeframes';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { useFullscreenContext } from 'contexts/FullscreenContext';
import { SetStateAction } from 'react';
import { useDataStreamSupportedTimeframes } from 'ui/editor/dataStream/TileEditor/hooks/useDataStreamSupportedTimeframes';
import TimeframeChooser from 'ui/timeframe/TimeframeChooser';

interface FullscreenTimeframeChooserProps {
    timeframe: TimeframeEnumValue;
    setTimeframe: React.Dispatch<SetStateAction<TimeframeEnumValue>>;
}

export const FullscreenTimeframeChooser: React.FC<FullscreenTimeframeChooserProps> = ({ timeframe, setTimeframe }) => {
    const { fullscreenConfig: config } = useFullscreenContext();
    const supportedTimeframes = useDataStreamSupportedTimeframes(config?.dataStream?.id);

    const menuContainer = useDOMElement('dialogContent');

    if (!config) {
        return null;
    }

    return (
        <TimeframeChooser
            currentTimeframe={timeframe ?? undefined}
            allowNullSelection={false}
            alignment='end'
            onChange={(updatedTimeframe: TimeframeEnumValue) => setTimeframe(updatedTimeframe)}
            title={'Tile timeframe'}
            supportedTimeframes={supportedTimeframes}
            container={menuContainer}
            modal={false}
        />
    );
};
