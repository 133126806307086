import { cn } from '@/lib/cn';
import { HealthState, healthStates } from '@squaredup/monitoring';
import { healthStatesEnum } from 'constants/state';
import { useWorkspaceHealthStates } from 'queries/hooks/useWorkspaceHealthStates';
import { ComponentPropsWithoutRef, memo, useMemo } from 'react';

type Props = ComponentPropsWithoutRef<'svg'> & { size?: number; state?: HealthState };

export const WorkspaceStateIndicator = memo(({ id, size = 20, className, state, ...rest }: Props) => {
    const workspaceHealthStates = useWorkspaceHealthStates({ enabled: !state });

    const currentState = useMemo(() => {
        const foundState = workspaceHealthStates.data?.find((w) => w.id === id)?.state;

        return state ?? healthStatesEnum.parse(foundState);
    }, [id, state, workspaceHealthStates.data]);

    return (
        <svg
            width={size}
            height={size}
            viewBox='0 1.5 27 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-label={`Current status is ${currentState}`}
            className={cn(stateToTextColor[currentState], className)}
            {...rest}
        >
            <path
                d='M13.6365 15.796L2.37161 9.8197V22.8632L13.6365 29.385V15.796Z'
                fill='currentColor'
                fillOpacity='0.5'
            />
            <path
                d='M13.7212 15.8333L24.986 9.86038L13.4826 3.36561L2.2869 9.73498L13.7212 15.8333Z'
                fill='currentColor'
            />
            <path
                d='M2.37239 9.8603L13.6364 3.35701L24.9004 9.8603V22.8669L13.6364 29.3702L2.37239 22.8669V9.8603Z'
                stroke='currentColor'
                strokeWidth='1.09091'
            />
        </svg>
    );
});

const stateToTextColor: Record<(typeof healthStates)[number], string> = {
    error: 'text-statusErrorPrimary',
    success: 'text-statusHealthyPrimary',
    unknown: 'text-workspaceIcon',
    warning: 'text-statusWarningPrimary'
} as const;
