import { HealthState, stateStrings } from '@squaredup/monitoring';
import { sortBy } from 'lodash';
import { oneMinute } from 'queries/constants';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { ListWorkspaceHealthByIds, TileState } from 'services/HealthService';
import { List } from 'services/WorkspaceService';
import { IterableElement } from 'type-fest';
import { useWorkspaces } from './useWorkspaces';

type QueryReturnType = (IterableElement<Awaited<ReturnType<typeof List>>> & {
    state: HealthState;
    reasons?: Partial<TileState & { text: string }>[];
})[];

export const useWorkspacesWithHealthRollup = <T = QueryReturnType>(
    options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>
) => {
    const { data: workspaces } = useWorkspaces();
    const { enabled, ...queryOptions } = options ?? {};

    return useQuery<QueryReturnType, unknown, T, string[]>(
        workspaceQueryKeys.stateRollups(workspaces?.map((w) => w.id) ?? []),
        async () =>
            ListWorkspaceHealthByIds(workspaces?.map((w) => w.id) ?? []).then((data) => {
                const { workspaceStates } = data;

                const workspaceHealthsLookup = new Map(
                    workspaceStates?.map(({ workspaceId, state: workspaceState, unhealthyTileStates }) => [
                        workspaceId,
                        {
                            state: workspaceState,
                            reasons: unhealthyTileStates?.map(({ dashId, tileId, state, stateReason }) => ({
                                text: stateReason?.text,
                                stateReason,
                                dashId,
                                tileId,
                                state
                            }))
                        }
                    ])
                );

                return sortBy(workspaces ?? [], 'displayName').map((workspace) => {
                    return {
                        ...workspace,
                        state: workspaceHealthsLookup.get(workspace.id)?.state || stateStrings.unknown,
                        reasons: workspaceHealthsLookup.get(workspace.id)?.reasons
                    };
                });
            }),
        {
            enabled: (enabled === undefined || enabled) && workspaces && workspaces.length > 0,
            cacheTime: oneMinute,
            staleTime: oneMinute,
            refetchInterval: oneMinute,
            ...queryOptions
        }
    );
};
