import type { HealthState } from '@squaredup/monitoring';
import { fetchDashboardsWithHealth } from 'deprecated/helpers';
import type { StatusRequestType } from 'pages/status/ui/StatusOverview';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { useQuery } from 'react-query';
import { DASHBOARDS } from 'services/DashboardService';
import { CURRENT_WORKSPACE } from 'services/WorkspaceService';
import { useWorkspaceId } from './WorkspaceHome';

export const useDashboardsInWorkspace = () => {
    const workspaceId = useWorkspaceId();

    const { data: workspace } = useWorkspace(workspaceId);

    const dashboardsInWorkspaceQuery = useQuery(
        [DASHBOARDS, workspaceId, CURRENT_WORKSPACE],
        async () => {
            const dashboards = await fetchDashboardsWithHealth(workspace!);

            return dashboards.map((dashboard) => {
                return {
                    ...dashboard,
                    state: dashboard.state as HealthState,
                    name: dashboard.displayName,
                    type: 'dash' as StatusRequestType
                };
            });
        },
        {
            enabled: Boolean(workspace),
            suspense: true
        }
    );

    return dashboardsInWorkspaceQuery;
};
