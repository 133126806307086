import { Button } from '@/components/Button';
import { Skeleton } from '@/components/Skeleton';
import { Switch } from '@/components/Switch';
import { faChartNetwork, faGrid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BehindFlag } from 'components/BehindFlag';
import type { OrganisationHomeConfig } from 'dynamo-wrapper';
import { Dispatch, Suspense } from 'react';
import { useSaveStatusConfig } from '../utils/useStatusConfig';
import DownstreamToggle from './DownstreamToggle';
import KPIFilterMenu from './KPIFilterMenu';
import SortMenu from './SortMenu';
import StateFilter from './StateFilterMenu';
import TagMenu from './TagMenu';
import TypeFilter from './TypeMenu';
import ViewSettingsMenu from './ViewSettingsMenu';
import WorkspaceTypeMenu from './WorkspaceTypeMenu';

export type StatusView = 'map' | 'tile';

export interface StatusMenuBarSkeleton {
    view: StatusView;
}

function StatusMenuBarSkeleton({ view }: StatusMenuBarSkeleton) {
    if (view !== 'tile') {
        return null;
    }

    return (
        <div className='flex items-center h-[40px] shrink-0 justify-between'>
            <Skeleton className='w-[36rem] h-full' />
            <Skeleton className='w-[23rem] h-full' />
        </div>
    );
}

const displayModeOptions = [
    {
        value: 'tile',
        label: (
            <span className='inline-flex items-center space-x-2 align-middle'>
                <FontAwesomeIcon size='lg' icon={faGrid} />
                <span>Tile</span>
            </span>
        )
    },
    {
        value: 'map',
        label: (
            <span className='inline-flex items-center space-x-2 align-middle'>
                <FontAwesomeIcon size='lg' icon={faChartNetwork} />
                <span>Map</span>
            </span>
        )
    }
];

export interface StatusMenuBar {
    view: StatusView;
    setView: Dispatch<StatusView>;
    isLoading: boolean;
    config?: OrganisationHomeConfig;
}

export default function StatusMenuBar({ view, setView, isLoading, config }: StatusMenuBar) {
    const { mutate: saveConfig } = useSaveStatusConfig();

    if (isLoading || !config) {
        return <StatusMenuBarSkeleton view={view} />;
    }

    return (
        <Suspense fallback={<StatusMenuBarSkeleton view={view} />}>
            <div className='flex items-center space-x-4'>
                <TypeFilter
                    type={config.type}
                    setType={(type: any) =>
                        saveConfig({
                            ...config,
                            type
                        })
                    }
                />
                <span className='w-0.5 h-10 mx-1 shrink-0 rounded-full bg-dividerPrimary' />
                <StateFilter
                    states={config.filter || []}
                    setStates={(filter: any) =>
                        saveConfig({
                            ...config,
                            filter
                        })
                    }
                />
                <WorkspaceTypeMenu
                    types={config.workspaceTypes}
                    setTypes={(workspaceTypes) =>
                        saveConfig({
                            ...config,
                            workspaceTypes
                        })
                    }
                />
                <TagMenu
                    selectedTags={config.tags}
                    setSelectedTags={(tags) =>
                        saveConfig({
                            ...config,
                            tags
                        })
                    }
                />

                <KPIFilterMenu
                    selectedKPITypeIds={config.kpiTypeIds}
                    setSelectedKPITypeIds={(kpiTypeIds) =>
                        saveConfig({
                            ...config,
                            kpiTypeIds
                        })
                    }
                />

                {(Boolean(config.kpiTypeIds?.length) ||
                    Boolean(config.tags?.length) ||
                    Boolean(config.filter?.length) ||
                    config.downstream ||
                    Boolean(config.workspaceTypes?.length)) && (
                    <Button
                        variant='tertiary'
                        onClick={() =>
                            saveConfig({
                                ...config,
                                filter: [],
                                workspaceTypes: [],
                                tags: [],
                                kpiTypeIds: [],
                                downstream: false
                            })
                        }
                    >
                        Clear
                    </Button>
                )}
                <div className='flex justify-end flex-1 space-x-4'>
                    {view === 'tile' && (
                        <>
                            <SortMenu
                                sort={config.sort}
                                setSort={(sort: any) =>
                                    saveConfig({
                                        ...config,
                                        sort
                                    })
                                }
                            />

                            {config.type === 'space' && <ViewSettingsMenu />}
                        </>
                    )}

                    {view === 'map' && (
                        <DownstreamToggle
                            checked={config.downstream || false}
                            onChecked={(downstream) =>
                                saveConfig({
                                    ...config,
                                    downstream
                                })
                            }
                        />
                    )}

                    {config.type === 'space' && (
                        <BehindFlag flagName='tenantMap'>
                            <Switch
                                value={view}
                                options={displayModeOptions}
                                onValueChange={(v) => {
                                    setView((v as StatusView) || 'tile');
                                }}
                                disabled={config.type !== 'space'}
                            />
                        </BehindFlag>
                    )}
                </div>
            </div>
        </Suspense>
    );
}
