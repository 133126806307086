import { cn } from '@/lib/cn';
import { Datum } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import useSize from '@react-hook/size';
import { FoundColumn } from '@squaredup/data-streams';
import { clamp } from 'lodash';
import React, { RefObject, useRef } from 'react';
import GraphTooltip from '../../../components/GraphTooltip';
import { opacityGradient } from '../../util/nivoGradient';
import nivoTheme from '../../util/nivoTheme';
import { DataStreamDonutConfig } from './Config';
import { MAX_LABEL_LENGTH, calculateSize, getScale } from './DataStreamDonutSizing';
import DonutCenterValue from './DonutCenterValue';
import { Donut, convertToRgba } from './dataUtils';

interface DataStreamDonutVizProps {
    series: Donut[];
    config: DataStreamDonutConfig;
    isAutoVertical: boolean;
    currentlyHoveredId?: number;
    formatValue: (v: unknown) => string;
    getDonutColor: (datum: Datum) => string;
    containerRef: RefObject<HTMLElement>;
    valueColumn: FoundColumn;
    centerValue: number;
    totalValue: number;
    onHover: (attributes: Datum) => void;
    onLeave: () => void;
}

export const DataStreamDonutViz: React.FC<DataStreamDonutVizProps> = ({
    series,
    config,
    isAutoVertical,
    currentlyHoveredId,
    formatValue,
    getDonutColor,
    containerRef,
    valueColumn,
    centerValue,
    totalValue,
    onHover,
    onLeave
}) => {
    const donutRef = useRef<HTMLDivElement>(null);

    const [width, height] = useSize(donutRef);

    const isHovered = currentlyHoveredId != null;
    const position = config.legendPosition;

    const isVertical = isAutoVertical || position === 'top' || position === 'bottom';
    const isLegendTable = config.legendMode === 'table';

    const scale = getScale(width, height);

    const { size, margins, showLabels } = calculateSize(width, height, scale, isLegendTable, series);

    return (
        <div
            ref={donutRef}
            className='relative w-full h-full overflow-hidden font-inter'
            data-visualization='data-stream-donut-chart'
        >
            <div
                className={cn('relative', {
                    'absolute inset-0 left-auto m-auto': isLegendTable && !isVertical,
                    'right-auto': position === 'left',
                    'left-auto': position === 'right'
                })}
                style={
                    !isVertical && isLegendTable && donutRef.current
                        ? {
                              width: Math.min(width, height),
                              height: Math.min(width, height)
                          }
                        : { width: '100%', height: '100%' }
                }
            >
                <ResponsivePie
                    data={series}
                    startAngle={0}
                    endAngle={360}
                    margin={margins}
                    innerRadius={0.85}
                    padAngle={1}
                    borderWidth={4} // add transparent border to increase hover area, and reduce flicking as moving around donut
                    borderColor={'transparent'}
                    valueFormat={formatValue}
                    colors={getDonutColor}
                    defs={[opacityGradient('pie-gradient')]}
                    fill={[
                        {
                            match: (d) => isHovered && d.id !== currentlyHoveredId,
                            id: 'pie-gradient'
                        }
                    ]}
                    arcLinkLabelsSkipAngle={clamp(12 / scale, 9, 15)} // Hide labels for small segments
                    theme={{
                        ...nivoTheme,
                        labels: {
                            text: { fontSize: 14, fontWeight: 700, fontFamily: 'montserrat' }
                        }
                    }}
                    tooltip={({ datum: { label, value, color, data: pointData } }) => {
                        // If more than 1 row was used to create this value,
                        // the combined value has to be formatted as if it were a value in
                        // the value column
                        const formattedValue =
                            pointData.rows.length > 1
                                ? formatValue(value)
                                : pointData.rows[0]?.[valueColumn.dataIndex]?.formatted ?? pointData.label;

                        // The values that make up that combined value have already been formatted
                        // in the original data
                        const formattedComposition = pointData.composition?.map((c, i) => ({
                            ...c,
                            value: pointData.rows[i][valueColumn.dataIndex].formatted
                        }));

                        return (
                            <GraphTooltip
                                points={[
                                    {
                                        label: label.toString(),
                                        value: formattedValue,
                                        composition: formattedComposition,
                                        color: color,
                                        highlight: true
                                    }
                                ]}
                                graphRef={containerRef}
                            />
                        );
                    }}
                    enableArcLabels={false}
                    enableArcLinkLabels={showLabels}
                    arcLinkLabel={(attributes) => {
                        const id = attributes.id;
                        let label = attributes.label.toString();
                        const value = formatValue(attributes.value);

                        if (label.length > MAX_LABEL_LENGTH) {
                            label = label.substring(0, MAX_LABEL_LENGTH) + '...';
                        }
                        const fontSize = clamp(10 * scale, 10, 14);
                        const isLabelHovered = id === currentlyHoveredId;

                        return (
                            <tspan
                                className={cn('font-semibold', {
                                    'opacity-20': isHovered && !isLabelHovered
                                })}
                                onMouseEnter={() => onHover(attributes)}
                                onMouseLeave={onLeave}
                            >
                                <tspan
                                    x='0'
                                    y='-0.5em'
                                    fontSize={fontSize}
                                    className='fill-textPrimary'
                                    data-legendlabel='legendLabel'
                                >
                                    {label}
                                </tspan>
                                <tspan
                                    x='0'
                                    dy='1.2em'
                                    fontSize={fontSize}
                                    className='fill-textSecondary'
                                    data-legendvalue='legendValue'
                                >
                                    {value}
                                </tspan>
                            </tspan>
                        ) as unknown as string; //Type does not match actual functionality
                    }}
                    arcLinkLabelsTextOffset={6}
                    arcLinkLabelsDiagonalLength={clamp(20 * scale, 10, 30)}
                    arcLinkLabelsStraightLength={7}
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={(attributes) => {
                        const id = attributes.id;
                        const isLabelHovered = id === currentlyHoveredId;

                        if (isHovered && !isLabelHovered) {
                            return convertToRgba(attributes.color, 0.2);
                        }

                        return attributes.color;
                    }}
                    arcLabelsTextColor='var(--textSecondary)'
                    onMouseEnter={onHover}
                    onMouseLeave={onLeave}
                />

                <div className='absolute top-0 left-0 w-full h-full pointer-events-none'>
                    <DonutCenterValue
                        className='absolute inset-0 flex justify-center w-full h-full m-auto pointer-events-none place-items-center text-textPrimary'
                        style={{
                            // 57% is a rough approximate of the inner size of the donut
                            width: size * 0.57,
                            height: size * 0.57
                        }}
                        value={currentlyHoveredId !== undefined ? centerValue : totalValue}
                        formatValue={formatValue}
                    />
                </div>
            </div>
        </div>
    );
};
