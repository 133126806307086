import { getWorkspaceIdFromConfigId } from '@squaredup/ids';
import type { HealthState } from '@squaredup/monitoring';
import type { KPIValue } from 'dynamo-wrapper';
import { groupBy, orderBy, sortBy } from 'lodash';
import { WorkspaceWithDependencies, calculateUpstreamDependencyIds } from 'pages/monitoring/common';
import { ListWorkspaceHealthByIds, TileState } from 'services/HealthService';
import { List as ListKPIs } from 'services/KPIService';

interface Edge {
    group: 'edges';
    type: 'default';
    data: {
        inV: string;
        outV: string;
        to: string;
        from: string;
        group: 'edges';
        source: string;
        target: string;
        state: HealthState;
    };
}

export interface WorkspaceWithDependencyInstances extends WorkspaceWithDependencies {
    downStreamDependencies: WorkspaceWithDependencies[];
    upstream?: string[];
    state?: HealthState;
    tiles?: TileState[];
    kpis?: KPIValue[];
}

export type WorkspacesDependenciesMap = Map<string, WorkspaceWithDependencyInstances>;

export interface WorkspaceNetworkNodeData extends WorkspaceWithDependencyInstances {
    sourceId: string[];
    label: string;
    alwaysShowLabel: boolean;
    group: 'nodes';
    link: `/explorer/${string}`;
    type: ['space'];
    sourceType: ['squaredup/space'];
    selected: boolean;
}

export interface WorkspaceNetworkNode {
    group: 'nodes';
    data: WorkspaceNetworkNodeData;
}

const stateValues = {
    unknown: 0,
    success: 1,
    warning: 2,
    error: 3
};

const getWorkspaceHealthStatesLookup = async (workspacesToLookup: string[]) => {
    const { workspaceStates } = await ListWorkspaceHealthByIds(workspacesToLookup);
    return new Map(workspaceStates.map(({ workspaceId, state }) => [workspaceId, state as HealthState]));
};

const getWorkspaceHealthWithTileStatesLookup = async (workspacesToLookup: string[]) => {
    if (!workspacesToLookup.length) {
        return new Map();
    }

    // Lookup workspace health states
    const includeTileStates = true, includeDashboardNames = true;
    const { workspaceStates, tileStates } = await ListWorkspaceHealthByIds(
        workspacesToLookup,
        includeTileStates,
        includeDashboardNames
    );

    const tilesGroupedByWorkspaceId = groupBy(
        tileStates?.filter(({ state }) => state !== 'unknown'),
        ({ configId }) => getWorkspaceIdFromConfigId(configId)
    );
    const workspaceTileStateLookup = new Map(Object.entries(tilesGroupedByWorkspaceId));

    return new Map(
        workspaceStates.map(({ workspaceId, state }) => {
            const workspaceTileStates = workspaceTileStateLookup.get(workspaceId);
            const workspaceTilesSortedByState = orderBy(
                workspaceTileStates,
                [({ state: tileState }) => stateValues[tileState as keyof typeof stateValues], 'name'],
                'desc'
            );

            return [
                workspaceId,
                {
                    state,
                    tileStates: workspaceTilesSortedByState.map((tileState) => ({
                        ...tileState,
                        dashboardName: tileState.dashboardName
                    }))
                }
            ];
        })
    );
};

const getWorkspaceKPILookup = async (workspacesToLookup: string[]) => {
    if (!workspacesToLookup.length) {
        return new Map();
    }

    // Lookup workspace KPIs and group them by workspaceId so they can be added to the node data
    const kpis = await ListKPIs(workspacesToLookup);
    const groupedKpis = groupBy(kpis, 'workspaceId');

    return new Map(Object.entries(groupedKpis).map(([workspaceID, workspaceKpis]) => [workspaceID, workspaceKpis]));
};

const getDownstreamNodesThatExist = (
    dependencyIds: string[],
    workspacesWithDependenciesByNodeId: WorkspacesDependenciesMap
) => {
    return dependencyIds?.filter((dependencyNodeId) => {
        const dependencyWorkspace = workspacesWithDependenciesByNodeId.get(dependencyNodeId);
        return Boolean(dependencyWorkspace);
    });
};

const addNode = (
    nodes: any[],
    workspaceToAdd: WorkspaceWithDependencyInstances,
    workspacesWithDependenciesByNodeId: WorkspacesDependenciesMap,
    upstreamWorkspacesByNodeId: Map<string, string[]>
) => {
    const {
        id,
        name,
        workspaceID,
        dependencies,
        downStreamDependencies
    } = workspaceToAdd;

    // Push target workspace node if it doesn't already exist
    if (!nodes.find((node) => node.id === id)) {
        nodes.push({
            id,
            group: 'nodes',
            sourceId: [workspaceID],
            label: name,
            name: name,
            alwaysShowLabel: true,
            link: `/explorer/${workspaceID}`,
            type: ['space'],
            sourceType: ['squaredup/space'],
            workspaceID,
            upstream: upstreamWorkspacesByNodeId.get(id),
            dependencies,
            downStreamDependencies
        });
    }
};

const addEdge = (edges: any[], edgeInV: string, edgeOutV: string, state?: HealthState) => {
    if (edges.find(({ data: { inV, outV } }) => inV === edgeInV && outV === edgeOutV)) {
        return;
    }

    edges.push({
        group: 'edges',
        type: 'default',
        data: {
            inV: edgeInV,
            outV: edgeOutV,
            to: edgeInV,
            from: edgeOutV,
            group: 'edges',
            source: edgeOutV,
            target: edgeInV,
            state: state || 'unknown'
        }
    });
};

export const getInitialNodes = (
    initialNodeIds: string[],
    targetNodes: string[],
    workspacesWithDependenciesByNodeId: WorkspacesDependenciesMap
) => {
    targetNodes.forEach((targetNodeId) => {
        const { id, dependencies } = workspacesWithDependenciesByNodeId.get(targetNodeId) || {};

        if (!id) {
            return;
        }

        // Push target workspace node if it doesn't already exist in initialNodes
        if (!initialNodeIds.find((nodeId) => nodeId === id)) {
            initialNodeIds.push(id);
        }

        if (dependencies?.length) {
            getInitialNodes(
                initialNodeIds, 
                dependencies.filter((dependency) => !initialNodeIds.includes(dependency)), 
                workspacesWithDependenciesByNodeId
            );
        }
    });
};

export const getDownstreamNodes = (
    downStreamNodes: any[],
    nodeIds: string[],
    workspacesWithDependenciesByNodeId: WorkspacesDependenciesMap
) => {
    const nodeInstances = nodeIds.map((nodeId) => workspacesWithDependenciesByNodeId.get(nodeId));
    downStreamNodes.push(...nodeInstances);

    const filteredDependencyIds = nodeInstances
        .map((workspaceWithDependencies) => {
            if (workspaceWithDependencies) {
                return getDownstreamNodesThatExist(
                    workspaceWithDependencies.dependencies,
                    workspacesWithDependenciesByNodeId
                );
            }
            return [];
        })
        .flat();

    getDownstreamNodes(downStreamNodes, filteredDependencyIds, workspacesWithDependenciesByNodeId);
};

/**
 * @param { any[] } nodes Nodes that can feature in the graph
 * @param { any[] } edges Edges that can feature in the graph
 * @param { Map<string, any> } workspacesWithDependenciesByNodeId Map to lookup dependencies from a Node ID
 */
const generateGraphWithConnections = (
    nodes: any[],
    edges: any[],
    workspacesWithDependenciesByNodeId: WorkspacesDependenciesMap,
    upstreamWorkspacesByNodeId: Map<string, string[]>
) => {
    workspacesWithDependenciesByNodeId.forEach((workspaceWithDependencies, nodeId) => {
        addNode(nodes, workspaceWithDependencies, workspacesWithDependenciesByNodeId, upstreamWorkspacesByNodeId);

        // Filter out nodes that don't exist in workspacesWithDependenciesByNodeId
        const filteredDependencies = getDownstreamNodesThatExist(
            workspaceWithDependencies.dependencies,
            workspacesWithDependenciesByNodeId
        );

        // If filtered dependencies exist and the the target workspace node exists
        // push the dependency edges and explore further connections
        if (filteredDependencies) {
            filteredDependencies.forEach((dependencyId) => {
                const state = workspacesWithDependenciesByNodeId.get(dependencyId)?.state;

                addEdge(edges, dependencyId, nodeId, state);
            });
        }
    });
};

export const generateWorkspaceNetworkData = async (
    targetWorkspaces: string[],
    workspacesWithDependencies: WorkspaceWithDependencies[],
    config: any
) => {
    // Generate maps for quick lookup
    const workspaceSourceIdToNodeId = new Map();
    const workspaceNodeIdToWorkspaceId = new Map();
    const workspacesWithDependenciesByNodeId: WorkspacesDependenciesMap = new Map();
    const upstreamWorkspacesByNodeId = new Map();

    const workspaceHealthLookup = await getWorkspaceHealthStatesLookup(
        workspacesWithDependencies.map(({ workspaceID }) => workspaceID)
    );

    workspacesWithDependencies.forEach((workspaceWithDependencies) => {
        // Lookup downstream dependencies so we can use all relevant props (e.g. name, workspaceId)
        const downStreamDependencies = workspacesWithDependencies.filter(({ id }) =>
            workspaceWithDependencies.dependencies?.includes(id)
        );

        const { id, workspaceID, ...dependencyProps } = workspaceWithDependencies;

        workspaceSourceIdToNodeId.set(workspaceID, id);
        workspaceNodeIdToWorkspaceId.set(id, workspaceID);
        workspacesWithDependenciesByNodeId.set(id, {
            id,
            workspaceID,
            downStreamDependencies,
            state: workspaceHealthLookup.get(workspaceID),
            ...dependencyProps
        });

        // Set the upstream dependencies map entry based on the current workspaceWithDependencies
        workspaceWithDependencies.dependencies?.forEach((downStreamNodeId) => {
            const existingUpstreamDependencies = upstreamWorkspacesByNodeId.get(downStreamNodeId) || [];
            upstreamWorkspacesByNodeId.set(downStreamNodeId, [
                ...existingUpstreamDependencies,
                workspaceWithDependencies.id
            ]);
        });
    });

    const targetWorkspacesNodeIds = [...targetWorkspaces].map((targetWorkspace) =>
        workspaceSourceIdToNodeId.get(targetWorkspace)
    );

    // Generate graph nodes and edges
    const nodes = [] as WorkspaceNetworkNodeData[];
    const edges = [] as Edge[];

    generateGraphWithConnections(nodes, edges, workspacesWithDependenciesByNodeId, upstreamWorkspacesByNodeId);

    const workspacesToLookup = nodes.map(({ workspaceID }) => workspaceID);
    const [workspaceStateLookup, workspaceKpiLookup]: [
        Map<string, { state: HealthState; tileStates: TileState[] }>,
        Map<string, KPIValue[]>
    ] = await Promise.all([
        getWorkspaceHealthWithTileStatesLookup(workspacesToLookup),
        getWorkspaceKPILookup(workspacesToLookup)
    ]);

    const networkNodes: WorkspaceNetworkNode[] = nodes.map((node) => {
        const { id: nodeId, workspaceID } = node;
        const { state, tileStates } = workspaceStateLookup.get(workspaceID) || {};
        const kpis = workspaceKpiLookup.get(workspaceID);

        // TODO: When KPIs have there own state strip this out
        const kpisWithState = sortBy(kpis || [], ({ name }) => name.toLowerCase()).map((kpi) => {
            const { dashboardId, tileId: kpiTile } = kpi;
            const kpiState = tileStates?.find(
                ({ dashId, tileId }) => dashboardId === dashId && tileId === kpiTile
            )?.state;

            return {
                ...kpi,
                kpiState: kpiState || 'unknown'
            };
        });

        // Get the tileStates that remain after merging them into KPIs
        const tileStatesAfterKPIMerge = tileStates?.filter(({ dashId: tileDashboardId, tileId: tileTileId }) => {
            return !kpisWithState.some(({ tileId: kpiTileId, dashboardId: kpiDashboardId }) => {
                return kpiDashboardId === tileDashboardId && kpiTileId === tileTileId;
            });
        });

        return {
            group: 'nodes',
            data: {
                ...node,
                state,
                kpis: kpisWithState,
                tiles: tileStatesAfterKPIMerge,
                selected: config?.fetchAllWorkspaces ? false : targetWorkspacesNodeIds.includes(nodeId)
            }
        };
    });

    return {
        workspacesWithDependencies,
        workspacesWithDependenciesByNodeId,
        networkData: {
            nodes: networkNodes,
            edges
        }
    };
};
