import { Toggle } from '@/components/forms/Toggle';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ToggleStyleWrapper } from 'components/ToggleStyleWrapper';
import { Controller, useFormContext } from 'react-hook-form';

export interface FormToggleProps {
    name: string;
    label: string;
    description?: string;
    disabled?: boolean;
    help?: string;
    icon?: IconProp;
    title?: string;
    wrapperClassName?: string;
    testId?: string;
    onCheckedChange?: (checked: boolean) => void;
}

export const FormToggle = ({
    name,
    label,
    description,
    disabled,
    help,
    icon,
    testId,
    title,
    wrapperClassName,
    onCheckedChange
}: FormToggleProps) => {
    const { control } = useFormContext();
    const props = { name, label, description, disabled, help, icon, title, wrapperClassName };
    
    return (
        <ToggleStyleWrapper {...props}>
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => (
                    <Toggle
                        checked={value}
                        onCheckedChange={() => {
                            onChange(!value);
                            onCheckedChange?.(!value);
                        }}
                        disabled={disabled}
                        name={name}
                        data-testid={testId}
                    />
                )}
            />
        </ToggleStyleWrapper>
    );
};
