import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Collapsible from '@radix-ui/react-collapsible';
import { ReactNode, forwardRef } from 'react';

interface NavSectionProps extends React.ComponentPropsWithoutRef<typeof Collapsible.Root> {
    title: string;
    action?: ReactNode;
    contentClassNames?: string;
}

export const NavSection = forwardRef<HTMLDivElement, NavSectionProps>(
    ({ title, action, children, contentClassNames, ...props }, ref) => (
        <Collapsible.Root className='pr-0.5' defaultOpen {...props} ref={ref}>
            <div className='flex items-center justify-between pl-3.5 group mb-4  space-x-3'>
                <Collapsible.Trigger className='flex group items-center grow hover:text-textPrimary'>
                    <FontAwesomeIcon
                        icon={faChevronUp}
                        fixedWidth
                        className='text-[12px] mr-xxs ml-1 transform collapsibleArrow'
                    />
                    <Text.H4 className='inline-block'>{title}</Text.H4>
                </Collapsible.Trigger>
                <span className='shrink-0 flex items-center pr-px '>{action}</span>
            </div>

            <Collapsible.Content className={cn('px-2.5 text-[14px]', contentClassNames)}>
                {children}
            </Collapsible.Content>
        </Collapsible.Root>
    )
);
