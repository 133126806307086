import { TruncatedText } from 'components/TruncatedText';
import { useClickOutside } from 'lib/useClickOutside';
import type { DashboardFolder } from 'queries/utils/dashboardSorted';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ItemState } from './DashboardTreeItem';
import { useApplyDashboardInstruction } from './useApplyDashboardInstruction';

interface InlineEditProps {
    editing: boolean;
    setEditing: React.Dispatch<boolean>;
    item: DashboardFolder;
    state: ItemState;
}
export const DashboardFolderInlineEdit: React.FC<InlineEditProps> = ({ editing, item, state, setEditing }) => {
    const [innerVal, setInnerVal] = useState(item.name);
    const inputRef = useRef<HTMLInputElement>(null);

    const applyChanges = useApplyDashboardInstruction();

    const handleSave = useCallback(() => {
        if (innerVal.length > 0) {
            applyChanges({
                instruction: { type: 'rename', name: innerVal },
                itemId: item.id,
                targetId: ''
            });
        } else {
            setInnerVal(item.name);
        }

        setEditing(false);
    }, [applyChanges, innerVal, item.id, item.name, setEditing]);

    useClickOutside([inputRef], () => editing && handleSave());

    useEffect(() => {
        setInnerVal(item.name);
    }, [item.name]);

    return (
        <div className='mr-px max-w-full overflow-hidden'>
            {editing ? (
                <input
                    onClick={(e) => e.stopPropagation()}
                    className='bg-transparent selection:bg-primaryButtonBackground selection:text-primaryButtonText w-full p-0 h-[18px]'
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === 'Tab') {
                            handleSave();
                        } else if (e.key === 'Escape') {
                            setEditing(false);
                        }
                    }}
                    onKeyUp={(e) => e.preventDefault()}
                    type='text'
                    ref={inputRef}
                    value={innerVal}
                    onFocus={(e) => e.currentTarget.select()}
                    autoFocus
                    onChange={({ currentTarget }) => setInnerVal(currentTarget.value)}
                />
            ) : (
                <TruncatedText
                    disabled={state === 'dragging'}
                    placement='bottom-end'
                    className='truncate group-hover:text-textPrimary text-left'
                    title={item.name}
                >
                    {item.name}
                </TruncatedText>
            )}
        </div>
    );
};
