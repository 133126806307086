import { Button, TooltipButton } from '@/components/Button';
import { DialogContent } from '@/components/Dialog';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faCircleNotch, faPencil, faRefresh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { TimeframeEnumValue } from '@squaredup/timeframes';
import { TruncatedText } from 'components/TruncatedText';
import { StateIndicator } from 'components/ui/state/StateIndicator';
import { useDashboardContext } from 'contexts/DashboardContext';
import { FullScreenTileConfig, useFullscreenContext } from 'contexts/FullscreenContext';
import TileContext, { useTileContext } from 'contexts/TileContext';
import DataStreamBaseTile from 'dashboard-engine/basetiles/DataStreamBaseTile';
import { useForceRefresh } from 'dashboard-engine/hooks/useForceRefresh';
import { useFullscreenTileConfig } from 'dashboard-engine/hooks/useFullscreenTileConfig';
import { healthReasonMessage } from 'dashboard-engine/util/monitoring';
import { isOpenAccess } from 'lib/openAccessUtil';
import { useRef, useState } from 'react';
import { UnshapedDataOption } from 'ui/editor/dataStream/TileEditor/preview/UnshapedDataOption';
import { UnshapedDataTable } from 'ui/editor/dataStream/TileEditor/preview/UnshapedDataTable';
import { KPIIcon } from 'ui/tile/KPIIcon';
import { useLastRefreshed } from 'ui/tile/hooks/useLastRefreshed';
import { FullscreenTileMenu } from './FullscreenTileMenu';
import { FullscreenTimeframeChooser } from './FullscreenTimeframeChooser';

export const FullscreenTile: React.FC = () => {
    const fullscreenRef = useRef<HTMLDivElement>(null);

    const [isShowingUnshapedData, setIsShowingUnshapedData] = useState(false);

    const { isEditingEnabled, fullscreenConfig, setIsEditing } = useFullscreenContext();
    const { health, ...config } = fullscreenConfig as FullScreenTileConfig;

    const tileContext = useTileContext();
    const { timeframe: dashboardTimeframe } = useDashboardContext();

    const [timeframeOverride, setTimeframeOverride] = useState<TimeframeEnumValue>(
        config?.timeframe ?? dashboardTimeframe
    );
    const configWithTimeframeOverride = useFullscreenTileConfig(config, timeframeOverride);
    const isTableVisualisation = config.visualisation?.type === 'data-stream-table';

    // Some hooks will not work in an open access dashboard so it's key we track whether we're in openaccess
    const isOA = isOpenAccess();

    const { forceRefresh, isRefreshing } = useForceRefresh(config);
    const { lastRefreshedString } = useLastRefreshed(config);

    const statusMessage = healthReasonMessage(health?.state, health?.stateReason);

    const hasChangedTimeframe = config?.timeframe !== timeframeOverride;

    const isGrouped = config.dataStream?.group?.aggregate?.length || -1 > 0;
    const isFiltered = config.dataStream?.filter;
    const isSorted = config.dataStream?.sort;
    const isTableShaped = isGrouped || isFiltered || isSorted;

    if (!config) {
        return null;
    }

    const DataTable = isShowingUnshapedData ? UnshapedDataTable : DataStreamBaseTile;

    return (
        <DialogContent
            className={cn(
                'fixed flex w-[92.5%] h-full justify-center -translate-x-1/2 -translate-y-1/2 gap-y-sm left-1/2 top-1/2 max-h-[calc(100%-48px)]',
                isTableVisualisation && 'h-max'
            )}
            ref={fullscreenRef}
        >
            <div
                className={cn(
                    'flex flex-col w-full min-h-0 border max-h-max bg-backgroundSecondary text-textPrimary border-modalOutline animate animate-enter',
                    !isTableVisualisation && 'grid grid-rows-[2fr_1px_1fr]'
                )}
                data-testid='fullscreenDialog'
            >
                <div
                    className='relative flex flex-col w-full min-h-[220px] max-h-full min-w-0 gap-y-sm p-lg flex-2'
                    id='tile-fullscreenTile'
                    tabIndex={0}
                >
                    <TileContext.Provider
                        value={{
                            ...tileContext,
                            config: configWithTimeframeOverride,
                            health: !hasChangedTimeframe ? health : undefined
                        }}
                    >
                        <div className='flex items-start justify-between gap-x-xs shrink-0'>
                            <div className='min-w-0'>
                                <div className='flex items-center w-full gap-x-sm'>
                                    {health && !hasChangedTimeframe && (
                                        <StateIndicator
                                            className='w-4 h-4'
                                            state={health.state}
                                            titleOverride={statusMessage}
                                        />
                                    )}

                                    <div className='flex flex-1 overflow-hidden gap-x-xs'>
                                        {config.title && (
                                            <Text.H2 className='flex w-full min-w-0' tabIndex={0}>
                                                <TruncatedText
                                                    title={config.title}
                                                    element='span'
                                                    className='inline-flex max-w-full'
                                                >
                                                    {config.title}
                                                </TruncatedText>
                                            </Text.H2>
                                        )}

                                        {config.kpi && (
                                            <div className='flex mr-2'>
                                                <KPIIcon kpiConfig={(config as DataStreamBaseTileConfig)?.kpi} />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {config.description && (
                                    <Text.H5 className={cn('text-textSecondary w-full', health && 'pl-[30px]')}>
                                        <TruncatedText
                                            title={config.description}
                                            element='span'
                                            className='inline-flex max-w-full'
                                        >
                                            {config.description}
                                        </TruncatedText>
                                    </Text.H5>
                                )}
                            </div>

                            <div className='flex justify-end gap-x-sm hide-for-image-export'>
                                {!isOA && (
                                    <TooltipButton
                                        onClick={() => forceRefresh()}
                                        variant='secondary'
                                        className='px-4'
                                        icon={
                                            <FontAwesomeIcon
                                                icon={isRefreshing ? faCircleNotch : faRefresh}
                                                spin={isRefreshing}
                                                fixedWidth
                                            />
                                        }
                                        disabled={isRefreshing}
                                        title={lastRefreshedString}
                                    ></TooltipButton>
                                )}

                                {!isOA && (
                                    <FullscreenTimeframeChooser
                                        timeframe={timeframeOverride}
                                        setTimeframe={setTimeframeOverride}
                                    />
                                )}

                                {isEditingEnabled && (
                                    <Button
                                        onClick={() => setIsEditing(true)}
                                        variant='secondary'
                                        icon={<FontAwesomeIcon icon={faPencil} />}
                                    >
                                        Edit
                                    </Button>
                                )}

                                <FullscreenTileMenu
                                    isShowingUnshapedData={isShowingUnshapedData}
                                />
                            </div>
                        </div>

                        <div className='relative flex w-full h-full min-h-0' data-testid='fullscreenTile'>
                            <DataStreamBaseTile config={configWithTimeframeOverride} />
                        </div>
                    </TileContext.Provider>
                </div>

                {!isTableVisualisation && (
                    <>
                        <div className='h-px bg-outlinePrimary shrink-0 grow-0' />
                        <div className='flex flex-col w-full min-w-0 min-h-[220px] gap-y-sm p-lg flex-1'>
                            <div className='flex items-center justify-between'>
                                <Text.H2>Data</Text.H2>
                                {isTableShaped && (
                                    <UnshapedDataOption
                                        isShowingUnshapedData={isShowingUnshapedData}
                                        setIsShowingUnshapedData={setIsShowingUnshapedData}
                                    />
                                )}
                            </div>

                            <div className='relative w-full min-h-0 overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary pr-sm'>
                                <DataTable
                                    config={{
                                        ...configWithTimeframeOverride,
                                        visualisation: {
                                            type: 'data-stream-table',
                                            config: {
                                                // This ensures we don't try to generate a default config and transpose
                                                // a single row table given we're meant to be showing raw data for
                                                // this panel
                                                'data-stream-table': {}
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </DialogContent>
    );
};
