import { FormattedStreamValue, StreamData } from '@squaredup/data-streams';
import { DataStreamDonutConfig } from './Config';
import { Donut, DonutComposition, getAllDonutData, getDonutColumns } from './dataUtils';

/**
 * Rows will be aggregated if there are more than {@link top} items
 *
 * e.g. rows 11 and 12 are aggregated by default
 *
 * Rows which are than {@link lowerPercent} of the total are aggregated
 *
 * Rows which are less than {@link upperPercent} but greater than
 * {@link lowerPercent} aren't aggregated unless there are more than
 * {@link smallCount} of them
 *
 * e.g. the 11th and 12th rows within these bounds are aggregated by default
 * @param data The donut rows to sort and aggregate
 * @param top The maximum number of sections to create before the rest are aggregated together
 * @param upperPercent The upper bound on what is considered a 'small' row
 * @param lowerPercent The lower bound on what is considered a 'small' row
 * @param smallCount The number of 'small' rows to include (up to {@link top}) before we start aggregating the rest
 */
export const getTopXSortedResults = (
    data: Donut[],
    top: number,
    upperPercent = 0.06,
    lowerPercent = 0.01,
    smallCount = 10
) => {
    const id = data.length;

    data.sort((a, b) => b.value - a.value);
    const total = data.reduce((prev, curr) => prev + curr.value, 0);

    let finalData: Donut[] = [];

    let index = 0;
    let smallElements = 0;

    // Grab the top x numbers provided they're a large enough percentage. The number of elements below is capped
    while (
        index < data.length &&
        index < top &&
        smallCount > smallElements &&
        data[index].value / total > lowerPercent
    ) {
        const element = data[index];
        finalData.push(element);

        if (element.value / total < upperPercent) {
            smallElements++;
        }
        index++;
    }

    if (finalData.length !== data.length) {
        // If there's only one element to go in other there's no point
        if (data.length - finalData.length === 1) {
            finalData.push(data[data.length - 1]);
        } else {
            let othersValue = 0;
            let composition: DonutComposition[] = [];
            let rows: FormattedStreamValue[][] = [];
            data.slice(finalData.length, data.length).forEach((element) => {
                othersValue += element.value;
                composition.push({ value: element.value, label: element.label });
                rows.push(...element.rows);
            });
            finalData.push({
                id,
                index,
                label: `${data.length - finalData.length} others`,
                value: othersValue,
                composition,
                rows
            });
        }
    }

    finalData = finalData.map((element, idx) => ({
        ...element,
        index: idx
    }));

    return finalData;
};

export const toDonut = (data: StreamData, config: DataStreamDonutConfig): Donut[] => {
    const top = 20;

    if (data.rows.length === 0) {
        return [];
    }

    const donutColumns = getDonutColumns(data.metadata.columns, config).throwIfFailed();

    const allData = getAllDonutData(data, donutColumns);

    return getTopXSortedResults(allData, top);
};
