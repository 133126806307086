import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import { DataStreamTooltip } from 'components/tooltip/commonTooltips/DataStreamTooltip';
import Tooltip from 'components/tooltip/Tooltip';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import TruncateWithTooltip from 'pages/settings/TruncateWithTooltip';
import { NavLink } from 'react-router-dom';
import { DataStreamMatchTypeDisplay } from 'ui/editor/dataStream/TileEditor/dataStream/DataStreamTypeDisplay';
import { DetailHeader } from './DetailHeader';
import { DataStreamDetails } from './useOverviewDataStreams';

const datastreamColumns: ColumnDef<Pick<DataStreamDetails, 'id'| 'displayName' | 'definition' | 'description'>, string>[] = [
    {
        id: 'name',
        header: 'Name',
        accessorKey: 'displayName',
        size: 370,
        cell: ({ row }) => {
            return (
                <div className='flex items-center w-full min-w-0'>
                    <TruncateWithTooltip title={row?.original?.displayName ?? ''}>
                        <NavLink
                            to={`/explore?datastream=${row?.original?.id}`}
                            className='min-w-0 truncate cursor-pointer text-textLink hover:underline'
                        >
                            {row.original?.displayName}
                        </NavLink>        
                    </TruncateWithTooltip>

                    {row.original?.description && (
                        <Tooltip 
                            title={row.original?.description} 
                            className='inline-block ml-2 shrink-0'
                        >
                            <FontAwesomeIcon icon={faQuestionCircle} className='text-textSecondary' />
                        </Tooltip>
                    )}                    
                </div>
            );            
        }
    },
    {
        id: 'type',
        header: 'Type',
        cell: ({ row }) => <DataStreamMatchTypeDisplay matchTypes={row.original.definition?.matchesTypes} />,
        accessorKey: '',
        size: 370
    }
];

const DataStreamDetailsApplicationTable = ApplicationTable<Pick<DataStreamDetails, 'id' | 'displayName' | 'definition' | 'description'>, string>();

export const OverviewDataStreamDetails = ({
    dataStreamDetails
}: {
    dataStreamDetails: Pick<DataStreamDetails, 'id' | 'displayName' | 'definition' | 'description'>[];
}) => {
    return (
        <div className='flex flex-col h-full' aria-label='overviewDataStreamDetails'>
            <DetailHeader 
                name='Data Streams'
                tooltip={<DataStreamTooltip placement='top' />}
                count={dataStreamDetails.length}
            />
            <hr className='h-px mx-4 my-4 border-0 bg-dividerPrimary shrink-0' />
            <div className='flex flex-col min-h-0 px-4'>
                <DataStreamDetailsApplicationTable
                    config={{
                        noDataMessage: 'No data streams',
                        disableSortBy: true
                    }}
                    data={dataStreamDetails ?? []}
                    columns={datastreamColumns}
                />
            </div>
        </div>
    );
};
