import Ajv from 'ajv';
import LoadingSpinner from 'components/LoadingSpinner';
import DashboardContext from 'contexts/DashboardContext';
import baseTileRepo from 'dashboard-engine/repositories/baseTileRepo';
import tileRepo from 'dashboard-engine/repositories/tileRepo';
import { construct } from 'dashboard-engine/schemas/schema';
import { getDashboardContents, setDashboardContents } from 'dashboard-engine/util/dashboard';
import { downloadTileAsImage } from 'dashboard-engine/util/dashboardImageSnapshots';
import { compactItem } from 'lib/gridLayout';
import merge from 'lodash/merge';
import { useDashboardMetricsContext } from 'pages/dashboard/DashboardMetrics';
import { useHandleSave } from 'pages/dashboard/hooks/useHandleSave';
import { optimisticMonitorCountUpdate } from 'queries/hooks/useMonitorsCount';
import { TileState } from 'queries/types/types';
import { Suspense, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { CloneDashboardImage, DeleteDashboardImage } from 'services/ImageService';
import Tile from 'ui/Tile';
import { TileDeleteModal } from 'ui/tile/TileDeleteModal';
import { v4 as uuidv4 } from 'uuid';

const ajv = new Ajv();

const cacheQueryInfinitely = {
    cacheTime: Number.POSITIVE_INFINITY,
    staleTime: Number.POSITIVE_INFINITY
};

const getTilePath = (tileType: keyof typeof tileRepo) => tileRepo.get(tileType).tile;

const RenderTile: React.FC<{
    tileId: string;
    config: Record<string, any>;
    preview: boolean;
    previewHealth?: TileState;
    supportsDelete?: boolean;
    supportsClone?: boolean;
    supportsDragging?: boolean;
    supportsEditingTitle?: boolean;
}> = memo(
    ({
        tileId,
        config,
        preview,
        previewHealth,
        supportsDelete = true,
        supportsClone = true,
        supportsDragging = true,
        supportsEditingTitle = true
    }) => {
        const tileType = config._type?.split('/').pop() || '';
        const { dashboard, timeframe, updateTile } = useContext(DashboardContext);
        const [deletingTile, setDeletingTile] = useState(false);

        const queryClient = useQueryClient();
        const { mutate: handleSave } = useHandleSave();

        const { reportTileStart } = useDashboardMetricsContext();

        reportTileStart(tileId, Date.now());

        const { data: baseTileConfig } = useQuery(
            ['baseTile', tileType],
            async () => {
                const { default: tileConfigJson } = await import(`../tiles/${getTilePath(tileType)}.ts`);
                return tileConfigJson;
            },
            {
                enabled: Boolean(tileType),
                ...cacheQueryInfinitely
            }
        );

        const BaseTile = baseTileConfig?.baseTile ? baseTileRepo.get(baseTileConfig.baseTile) : undefined;

        const { data: tileSchema = {} } = useQuery(['tileSchema'], construct, cacheQueryInfinitely);

        const tileConfig = useMemo(() => merge({}, baseTileConfig, config), [baseTileConfig, config]);

        const isValidTile = useMemo(() => {
            const validator = ajv.compile(tileSchema);
            return validator(tileConfig);
        }, [tileConfig, tileSchema]);

        // TODO: When new tile types are added extend this to make appropriate checks
        const isConfigured = Boolean(tileConfig?._type);
        const tileTimeframe = tileConfig.timeframe ?? timeframe;

        const save = (key: string, value: any) => {
            updateTile?.({ ...config, [key]: value }, tileId);
        };

        const handleCloneTile = useCallback(async () => {
            const contents = getDashboardContents(dashboard);
            const cell = contents.find((x) => x.i === tileId);

            if (!cell) {
                return;
            }

            const id = uuidv4();

            // 1. Clone the tile and add to a new layout
            const cloned = {
                ...cell,
                i: id
            };
            const layout = [...contents, cloned];

            // 2. Ensure the cell ends up in the nearest possible gap vertically
            // Note: cols is 0 here because we're only moving tiles vertically
            const compacted = compactItem(layout, cloned, 'vertical', 0, layout, false);

            // 3. Add the compacted cell, and cloned config
            const updated = [
                ...contents,
                {
                    ...compacted,
                    config: {
                        ...config,
                        title: config.title ? `Copy of ${config.title}` : ''
                    }
                }
            ];

            if (tileType === 'image') {
                // Attempt to clone any stored dashboard image (it won't fail if one doesn't exist)
                await CloneDashboardImage(dashboard.workspaceId, dashboard.id, tileId, id);
            }

            if ('monitor' in cell.config && cell.config.monitor) {
                // optimistically update the number of monitors
                optimisticMonitorCountUpdate(queryClient, 1);
            }

            handleSave(setDashboardContents(dashboard, updated), {
                onSettled: () =>
                    document
                        .getElementById(`tile-${id}`)
                        ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
            });
        }, [config, dashboard, handleSave, tileId, tileType, queryClient]);

        const handleDeleteTile = useCallback(async () => {
            const contents = getDashboardContents(dashboard);
            const removedTile = contents.find((tile) => tile.i === tileId);
            const updatedContents = contents.filter(({ i }) => i !== tileId);

            if (removedTile && 'monitor' in removedTile.config && removedTile.config.monitor) {
                // optimistically update the number of monitors
                optimisticMonitorCountUpdate(queryClient, -1);
            }

            handleSave(setDashboardContents(dashboard, updatedContents));

            if (tileType === 'image') {
                // Attempt to delete any stored dashboard image (it won't fail if one doesn't exist)
                await DeleteDashboardImage(dashboard.workspaceId, dashboard.id, tileId);
            }
            setDeletingTile(false);
        }, [dashboard, handleSave, tileId, tileType, queryClient]);

        return (
            <>
                <Tile
                    id={tileId}
                    title={config.title}
                    description={config.description}
                    config={config}
                    preview={preview}
                    previewHealth={previewHealth}
                    supportsEditingTitle={supportsEditingTitle}
                    onSave={save}
                    onChange={(updated) => updateTile?.(updated, tileId)}
                    onDelete={supportsDelete ? () => setDeletingTile(true) : undefined}
                    onClone={supportsClone ? () => handleCloneTile() : undefined}
                    onDownloadAsImage={() => downloadTileAsImage(tileId, config.title)}
                    draggable={supportsDragging}
                >
                    <Suspense
                        fallback={
                            <div className='mt-5 text-center'>
                                <LoadingSpinner size='full' />
                            </div>
                        }
                    >
                        <div className='relative first:h-full'>
                            {isValidTile && BaseTile && (
                                <BaseTile tileId={tileId} config={tileConfig} timeframe={tileTimeframe} />
                            )}

                            {isConfigured && !isValidTile && (
                                <div className='flex w-full h-full'>
                                    <pre className='self-center w-full text-center text-statusErrorPrimary'>
                                        Error: Invalid tile configuration JSON
                                    </pre>
                                </div>
                            )}
                        </div>
                    </Suspense>
                </Tile>

                {deletingTile && (
                    <TileDeleteModal
                        tileConfig={tileConfig}
                        close={(deleteConfirmed) => (deleteConfirmed ? handleDeleteTile() : setDeletingTile(false))}
                    />
                )}
            </>
        );
    }
);

export default RenderTile;
