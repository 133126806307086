import { Portal } from '@/components/Portal';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faChevronRight, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOverflowing from 'lib/useOverflowing';
import PropTypes from 'prop-types';
import { FC, MouseEventHandler, useState } from 'react';
import Button from './button';
import Tooltip from './tooltip/Tooltip';

export type ModalCloseReason = 'exit' | 'outside' | 'cancel' | 'submit' | 'back';

// Details describes any extra data the modal might return when it closes e.g. the created plugin config id
export type CloseHandler = (reason?: ModalCloseReason | undefined, details?: unknown) => void;

interface ModalProps {
    title: React.ReactNode;
    description: string | React.ReactNode;
    headerContent: string | React.ReactNode;
    fullScreen: boolean;
    fullWidth: boolean;
    fullHeight: boolean;
    maxHeight: string;
    maxWidth: string;
    children: React.ReactNode;
    preHeader: React.ReactNode;
    container?: HTMLElement | null;
    disableClickOutside?: boolean;
    className?: string;
    headerBorder?: boolean;
    modalClassName: string;
    close: CloseHandler;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

interface ModalButtonProps {
    children: React.ReactNode;
    hideTopMargin?: boolean;
    isFooter?: boolean;
}

/**
 * Runs the close handler only if the user does not click outside the modal
 * @param f Close Handler
 */
export const ifNotOutside = (f: CloseHandler): CloseHandler => {
    return (reason) => {
        if (reason !== 'outside') {
            return f(reason);
        }
    };
};

/**
 * Modal
 * Displays a modal in the center of the page, with a dark overlay.
 *
 * @example
 * ```
 * const fn = () => {};
 * return (<Modal
 *  title='I am a modal title'
 *  close={fn}
 *  >
 *  This is my body.
 * </Modal>)
 * ```
 */
function Modal({
    title,
    description,
    headerContent,
    fullScreen,
    fullWidth,
    fullHeight,
    maxHeight,
    maxWidth,
    children,
    preHeader,
    disableClickOutside,
    container,
    className,
    modalClassName,
    headerBorder,
    close,
    onClick
}: Partial<ModalProps>) {
    const [overflowRef, overflowing] = useOverflowing<HTMLDivElement>();

    const handleOnClose = (reason: ModalCloseReason) => {
        if (disableClickOutside && reason === 'outside') {
            return;
        }
        close?.(reason);
    };

    const modalStyles = cn(
        'relative flex flex-col bg-backgroundSecondary border-2 border-modalOutline shadow-lg z-[15] animate-enter modal overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary',
        fullScreen && 'flex flex-col w-full h-full',
        fullHeight && 'h-full',
        fullWidth && 'w-full',
        maxHeight,
        maxWidth,
        modalClassName
    );

    return (
        <Portal
            id='modal'
            className={cn(
                'fixed inset-0 flex items-center justify-center w-full z-[15] h-full p-4 pointer-events-auto text-textPrimary backdrop-filter backdrop-blur-md',
                className
            )}
            tabIndex={-1}
            data-testid='modal'
            container={container ?? document.body}
        >
            <div className='fixed inset-0 bg-modalBackground' onClick={() => handleOnClose('outside')} />
            <div onClick={onClick} className={modalStyles} style={{ maxHeight: 'min(1280px, 90vh)' }} role='dialog'>
                {(title || preHeader || headerContent) && (
                    <div
                        className={cn('flex-shrink-0 px-8 pt-4 overflow-hidden', {
                            'border-dividerPrimary border-b': headerBorder
                        })}
                    >
                        {preHeader}
                        <span className='flex items-center mb-4'>
                            <Tooltip className='flex-1 min-w-0' title={title} disabled={!overflowing}>
                                <Text.H2
                                    ref={overflowRef}
                                    className='truncate text-textPrimary'
                                    data-testid='modalHeader'
                                >
                                    {title}
                                </Text.H2>
                            </Tooltip>
                            {headerContent
                                ? headerContent
                                : close && (
                                      <FontAwesomeIcon
                                          icon={faXmark}
                                          className='ml-auto text-xl cursor-pointer text-tertiaryButton hover:text-tertiaryButtonHover'
                                          onClick={() => handleOnClose('exit')}
                                          data-testid='closeModal'
                                      />
                                  )}
                        </span>
                        {description && <div className='text-textSecondary'>{description}</div>}
                    </div>
                )}

                {children}
            </div>
        </Portal>
    );
}

export const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen((prev) => !prev);
    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);

    return {
        isOpen,
        toggle,
        open,
        close
    };
};

export function ModalButtons({ children, hideTopMargin, isFooter }: ModalButtonProps) {
    const classes = cn(
        'flex items-center justify-end flex-shrink-0 min-h-[68px] px-8 shrink-0 py-4 space-x-8 bg-modalFooter',
        isFooter && 'sticky inset-x-0 bottom-0',
        !hideTopMargin && 'mt-12'
    );
    return <div className={classes}>{children}</div>;
}

interface FullWidthModalButtonProps {
    onClick: () => void;
    icon?: React.ReactNode;
    title?: string;
}

/**
 * A full width button with right arrow for use in modals
 */
export const FullWidthModalButton: FC<FullWidthModalButtonProps> = ({ onClick, icon, children, title }) => (
    <Button
        type='button'
        onClick={onClick}
        variant='secondary'
        className='flex items-center w-full font-normal'
        aria-label={title}
        icon={icon}
    >
        <span>{children}</span>
        <FontAwesomeIcon icon={faChevronRight} fixedWidth className='ml-auto' />
    </Button>
);

Modal.propTypes = {
    /**
     * Content to be displayed as the title of the modal
     */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     * Text to be displayed as the description of the modal
     */
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
     * Click handler for the close action (X icon) - only needed if no cancelAction is supplied.
     */
    close: PropTypes.func
};

export default Modal;
