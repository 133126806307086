import { cn } from '@/lib/cn';
import { defaultTimeframeEnum } from '@squaredup/timeframes';
import { useNetworkBackground } from 'components/map/context/NetworkMapStoreContext';
import { PinnableNodeData } from 'components/map/data/types';
import { useStoreHealthStateNode } from 'components/map/hooks/useStoreHealthStateNode';
import { TruncatedText } from 'components/TruncatedText';
import { healthStateTextColors } from 'constants/state';
import { scaleOrdinal } from 'd3-scale';
import useDataStream from 'dashboard-engine/hooks/useDataStream';
import { getHealthState } from 'dashboard-engine/util/getHealthStateFromDatastream';
import { mapBackgroundColors } from 'dashboard-engine/visualisations/Network/utils/mapColors';
import { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { useHover } from 'ui/hooks/useHover';
import { NodeToolbar } from '../../nodeToolbar/NodeToolbar';

export const OBJECT_NODE = 'objectNode';
export const OBJECT_NODE_SIZE = 28;

const palette = scaleOrdinal([
    '#1C98C6',
    '#3ABACD',
    '#A64E88',
    '#7423B2',
    '#622CE8',
    '#842497',
    '#844DCE',
    '#7A65FA',
    '#994ABB',
    '#DB96B7'
]);

interface ObjectNodeInternalProps extends PinnableNodeData {
    id: string;
}

export const ObjectNodeInternal: React.FC<ObjectNodeInternalProps> = memo(
    ({
        id,
        label,
        pinned,
        expanded,
        fixedPosition,
        type,
        sourceType,
        initialState,
        hiddenConnections,
        isCanonical
    }) => {
        const background = useNetworkBackground();
        const { isHovered, ...hoverProps } = useHover();

        const backgroundColor = palette(type ?? '');
        const typeLabel = type || sourceType;

        /**
         * We know if there is a dashId that it is not a SqUp monitor but an object based monitor,
         * e.g. from Pingdom, therefore we only load dataStream data if there is no dashId
         **/
        const { data: healthData } = useDataStream(
            {
                dataStreamId: 'datastream-health',
                timeframe: defaultTimeframeEnum,
                scope: [id],
                options: {
                    // Node could be from any workspace we have access to
                    accessControlType: 'directOrAnyWorkspaceLinks'
                }
            },
            undefined,
            {
                enabled: Boolean(initialState)
            }
        );

        const healthState = initialState && healthData ? getHealthState(healthData, id, isCanonical) : undefined;
        useStoreHealthStateNode(id, healthState);

        return (
            <div
                className={cn('relative rounded-full', pinned && 'p-2')}
                style={{ width: OBJECT_NODE_SIZE, height: OBJECT_NODE_SIZE }}
                {...hoverProps}
            >
                <NodeToolbar
                    id={id}
                    isVisible={isHovered}
                    isPinned={pinned}
                    isFixed={fixedPosition}
                    isExpanded={expanded}
                    label={label}
                    hiddenConnections={hiddenConnections}
                    url={`/drilldown/node/${id}`}
                />

                {(pinned || healthState) && (
                    <svg viewBox='0 0 100 100' className={cn('absolute w-full z-10 inset-0')}>
                        <circle
                            cx='50%'
                            cy='50%'
                            r='44'
                            vectorEffect='non-scaling-stroke'
                            strokeWidth={3}
                            className={cn(
                                'stroke-current fill-transparent',
                                healthState ? healthStateTextColors[healthState] : 'text-textPrimary'
                            )}
                            {...(!pinned && { strokeDasharray: '1 1' })}
                        />
                    </svg>
                )}

                <div style={{ backgroundColor }} className={cn('absolute inset-2 rounded-full')} />

                <Handle
                    type='target'
                    position={Position.Top}
                    className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
                />
                <Handle
                    type='source'
                    position={Position.Bottom}
                    className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
                />
                <span className='sr-only'>{label}</span>

                {label && (
                    <div className='text-primary flex flex-col items-center font-bold leading-tight text-[8px] max-w-[100px] absolute top-[calc(100%+4px)] left-1/2 -translate-x-1/2 text-center min-w-0'>
                        <TruncatedText
                            title={label}
                            className={cn('rounded-sm px-1 py-0.5 max-w-full', mapBackgroundColors[background])}
                        >
                            {label}
                        </TruncatedText>
                        {typeLabel && (
                            <TruncatedText
                                title={typeLabel}
                                className={cn(
                                    'max-w-[120px] text-textSecondary rounded-sm px-1 py-0.5 -mt-0.5',
                                    mapBackgroundColors[background]
                                )}
                            >
                                {typeLabel}
                            </TruncatedText>
                        )}
                    </div>
                )}
            </div>
        );
    }
);
