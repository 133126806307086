import clsx from 'clsx';
import { Pill } from 'components/pill/Pill';
import Tooltip from 'components/tooltip/Tooltip';
import useOverflowing from 'lib/useOverflowing';
import PluginIcon from 'pages/scope/PluginIcon';
import {
    createRef,
    CSSProperties,
    forwardRef,
    RefObject,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from 'react';
import { Link } from 'react-router-dom';
import { StatusBlockPlugin } from './StatusBlock';

function useResizeObserver<T extends HTMLElement>(callback: (target: T, entry: ResizeObserverEntry) => void) {
    const ref = useRef<T>(null);

    useLayoutEffect(() => {
        const element = ref?.current;

        if (!element) {
            return;
        }

        const observer = new ResizeObserver((entries) => {
            callback(element, entries[0]);
        });

        observer.observe(element);
        return () => {
            observer.disconnect();
        };
    }, [callback, ref]);

    return ref;
}

const TruncatedPill = forwardRef<HTMLDivElement, { type: 'tag' | 'type'; text: string; style: CSSProperties }>(
    ({ type, text, style }, ref) => {
        const [overflowingRef, overflowing] = useOverflowing<HTMLDivElement>();

        return (
            <Tooltip title={text} disabled={!overflowing} asChild>
                <Pill
                    variant={type as 'tag' | 'type'}
                    key={text}
                    ref={ref}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    style={style}
                    className={'inline-block cursor-default truncate'}
                >
                    <span ref={overflowingRef} className='min-w-[22px] block truncate text-center'>
                        {text}
                    </span>
                </Pill>
            </Tooltip>
        );
    }
);

export function Footer({
    plugins,
    typeAndTags = [],
    showPlugins,
    showTypesAndTags
}: {
    plugins?: StatusBlockPlugin[];
    showPlugins?: boolean;
    showTypesAndTags?: boolean;
    typeAndTags?: { type: string; text: string }[];
}) {
    const pluginRefs = useRef<RefObject<HTMLAnchorElement>[]>([]);

    const typeAndTagRefs = useRef<RefObject<HTMLDivElement>[]>([]);
    const typeAndTagSpacerRef = useRef<HTMLDivElement>(null);
    const hiddenTypeAndTagsCountRef = useRef<HTMLDivElement>(null);

    const [hiddenPluginsCount, setHiddenPluginsCount] = useState(0);

    const [hiddenTypeAndTagsCount, setHiddenTypeAndTagsCount] = useState(0);

    function getTypeAndTagRef(index: number) {
        const ref = typeAndTagRefs.current[index] ?? createRef();
        typeAndTagRefs.current[index] = ref;

        return ref;
    }

    function getPluginRef(index: number) {
        const ref = pluginRefs.current[index] ?? createRef();
        pluginRefs.current[index] = ref;

        return ref;
    }

    const onResizeIcons = useCallback(
        (target: HTMLDivElement) => {
            // We need to check if the right side of the container is close to any of the icons
            // If it is, we need to hide the icons that are just before & after the rhs
            const { right: containerRight } = target.getBoundingClientRect();

            let hiddenCount = 0;

            pluginRefs.current.forEach((ref, i) => {
                if (!ref.current) {
                    return;
                }
                const { right: iconRight } = ref.current.getBoundingClientRect();

                if (
                    i > 1 &&
                    pluginRefs.current.length > 2 &&
                    (hiddenTypeAndTagsCount || iconRight > containerRight - 24)
                ) {
                    // We always want to show at least 1 plugin icon & the +n icon
                    ref.current!.classList.add('opacity-0');

                    if (!hiddenCount && pluginRefs.current[i - 1]) {
                        pluginRefs.current[i - 1]?.current?.classList.add('opacity-0');
                        hiddenCount++;
                    }

                    hiddenCount++;
                } else {
                    ref.current!.classList.remove('opacity-0');
                }
            });

            setHiddenPluginsCount(hiddenCount);
        },
        [hiddenTypeAndTagsCount]
    );

    const pluginContainerRef = useResizeObserver(onResizeIcons);

    const onResizeTypeAndTags = useCallback((target: HTMLDivElement) => {
        // We need to check if the right side of the container is close to any of the type & tag names
        // If it is, we need to hide the type & tag names that are just before & after the rhs
        const { right: containerRight } = target.getBoundingClientRect();

        let hiddenCount = 0;

        typeAndTagRefs.current.forEach((ref, i) => {
            if (!ref.current) {
                return;
            }

            const { left: iconLeft } = ref.current.getBoundingClientRect();

            if (
                i > 0 &&
                iconLeft >
                    containerRight - 46 - (hiddenTypeAndTagsCountRef?.current?.getBoundingClientRect().width ?? 0) &&
                (typeAndTagSpacerRef.current?.getBoundingClientRect().width ?? 47) < 47
            ) {
                // We always want to show at least 1 type & tag name
                // The 46 width is the size of the type before the browser truncation stops,
                //  thus we need to hide it before that
                hiddenCount++;
                ref.current.classList.add();
                ref.current.classList.add('opacity-0', '!w-0', '!p-0', '!border-none');
            } else {
                ref.current.classList.remove('!w-0', 'opacity-0', '!p-0', '!border-none');
            }
        });

        setHiddenTypeAndTagsCount(hiddenCount);
    }, []);

    const typeAndTagsContainerRef = useResizeObserver(onResizeTypeAndTags);

    useEffect(() => {
        if (typeAndTagsContainerRef?.current) {
            onResizeTypeAndTags(typeAndTagsContainerRef.current);
        }
        if (pluginContainerRef?.current) {
            onResizeIcons(pluginContainerRef.current);
        }
    }, [
        onResizeTypeAndTags,
        typeAndTagsContainerRef,
        hiddenTypeAndTagsCount,
        showPlugins,
        showTypesAndTags,
        typeAndTags,
        plugins,
        pluginContainerRef,
        onResizeIcons
    ]);

    return (
        <div
            className={clsx(
                'flex space-x-4 h-12 shrink-0 justify-between py-2 px-5 relative overflow-hidden border-t border-dividerPrimary z-0',
                (plugins?.length ?? 0) === 0 && 'pl-2'
            )}
        >
            {showPlugins && (plugins?.length ?? 0) > 0 && (
                <div
                    ref={pluginContainerRef}
                    style={{
                        gap: 2,
                        // A very large number forces this to shrink before shrinking the tags/types
                        flexShrink: 1_000_000_000
                    }}
                    className={clsx(
                        'flex overflow-hidden items-center grow-0 z-10 relative',
                        // Stop plugins from expanding when we're still truncating tags & typeAndTags
                        hiddenTypeAndTagsCount && 'w-10',
                        plugins?.length === 1 && 'min-w-[2.5rem]',
                        (plugins?.length ?? 0) >= 2 && 'min-w-[5rem]'
                    )}
                >
                    {plugins?.map((plugin, i) => (
                        <Tooltip
                            disabled={i >= plugins.length - hiddenPluginsCount}
                            asChild
                            className='cursor-pointer aspect-square shrink-0 mr-2'
                            title={plugin.displayName}
                            key={plugin.id}
                        >
                            <Link
                                data-testid='pluginicon'
                                to={
                                    i >= plugins.length - hiddenPluginsCount
                                        ? `/workspace/${plugin.workspaceId}`
                                        : `/datasource/${plugin.id}?space=${plugin.workspaceId}`
                                }
                                ref={getPluginRef(i)}
                                className='w-7 max-w-[1.75rem] shrink-0'
                            >
                                <PluginIcon className='' pluginName={plugin.name} />
                            </Link>
                        </Tooltip>
                    ))}
                    <span className='w-7 h-7 max-w-[1.75rem] shrink-0 opacity-0'></span>

                    {Boolean(hiddenPluginsCount) && (
                        <Tooltip
                            asChild
                            title={
                                <ul className='flex flex-col space-y-2'>
                                    {plugins?.slice(plugins.length - hiddenPluginsCount).map((plugin) => (
                                        <li key={plugin.id} className='flex items-center space-x-2'>
                                            <PluginIcon
                                                className='h-full shrink-0 w-5 aspect-square'
                                                pluginName={plugin.name}
                                            />
                                            <span className='truncate'>{plugin.displayName}</span>
                                        </li>
                                    ))}
                                </ul>
                            }
                        >
                            <span
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                style={{
                                    // We need to take into account the gap between the icons
                                    transform: `translateX(${((plugins?.length ?? 0) - hiddenPluginsCount) * 2.4}rem)`
                                }}
                                className={
                                    'border left-0 cursor-default font-mono text-sm/5 py-[2px] absolute right-0 text-textSecondary w-fit px-1 border-cardTypeOutline font-normal rounded-md'
                                }
                            >
                                +{hiddenPluginsCount}
                            </span>
                        </Tooltip>
                    )}
                </div>
            )}

            {showTypesAndTags && (
                <div className={clsx('grow overflow-hidden items-center flex space-x-4', showPlugins && 'justify-end')}>
                    <div className='w-full'>
                        <div
                            ref={typeAndTagsContainerRef as any}
                            className={clsx(
                                'm-0 p-0 grow flex shrink-1 overflow-hidden relative',
                                showPlugins && 'justify-end'
                            )}
                        >
                            <span
                                ref={typeAndTagSpacerRef}
                                hidden={!hiddenTypeAndTagsCount}
                                className={clsx('grow max-w-[47px]', !showPlugins && 'order-10')}
                            />
                            {typeAndTags.map(({ text, type }, i) => (
                                <TruncatedPill
                                    key={i}
                                    text={text}
                                    type={type as 'tag' | 'type'}
                                    ref={getTypeAndTagRef(i)}
                                    style={{
                                        flexShrink: i >= typeAndTags.length - 1 - hiddenTypeAndTagsCount ? 1 : 0,
                                        marginRight:
                                            i >= typeAndTags.length - hiddenTypeAndTagsCount ||
                                            i === typeAndTags.length - 1
                                                ? 0
                                                : 8
                                    }}
                                />
                            ))}

                            {Boolean(hiddenTypeAndTagsCount) && (
                                <Tooltip
                                    asChild
                                    title={
                                        <ul className='flex flex-col space-y-2'>
                                            {typeAndTags
                                                ?.slice(typeAndTags.length - hiddenTypeAndTagsCount)
                                                .map(({ text }, i) => (
                                                    <li key={i} className='truncate'>
                                                        {text}
                                                    </li>
                                                ))}
                                        </ul>
                                    }
                                >
                                    <span
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        hidden={!hiddenTypeAndTagsCount}
                                        ref={hiddenTypeAndTagsCountRef}
                                        className={
                                            'border cursor-default font-mono text-sm shrink-0 flex items-center justify-center text-textSecondary w-fit px-1 border-cardTypeOutline font-normal rounded-md'
                                        }
                                    >
                                        +{hiddenTypeAndTagsCount}
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
