import { useLayoutEffect, useState } from 'react';
import useSize from '@react-hook/size';

/**
 * Checks if the element is overflowing, (it's scroll width is greater than it's size)
 *
 * Returns [ref, boolean], add the ref to the elements props you want to check
 *
 * Note: are you here because you are unit testing something that uses this component
 * and got an error about window.ResizeObserver  being undefined? If so, add this line
 * to your unit tests:
 *  window.ResizeObserver = class { observe(){}; unobserve(){}; disconnect(){}; };
 */
const useOverflowing = <E extends HTMLElement>(): [(element: E) => void, boolean] => {
    const [element, ref] = useState<E | null>(null);
    const [width] = useSize(element);
    const [overflowing, setOverflowing] = useState(false);

    useLayoutEffect(() => {
        const scrollWidth = element?.scrollWidth || 0;

        setOverflowing(Math.round(width) < scrollWidth);
    }, [element, width, element?.textContent]);

    return [ref, overflowing];
};

export default useOverflowing;