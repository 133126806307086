import { cn } from '@/lib/cn';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Serialised } from '@squaredup/ids';
import clsx from 'clsx';
import LoadingSpinner from 'components/LoadingSpinner';
import Form from 'components/forms/form/Form';
import Input from 'components/forms/input/Input';
import type { ProjectedPlugin } from 'dynamo-wrapper';
import trackEvent from 'lib/analytics';
import { debounce } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Auth from 'services/Auth';
import { GetLatestMajorVersions } from 'services/PluginService';
import { PluginSourceConfig } from 'services/SourceConfigService';
import { ConfigCard } from './ConfigCard';
import { PluginCard } from './PluginCard';
import { SuggestedPluginsPage } from './SuggestedDatasources';
import { SuggestionFooter } from './SuggestionFooter';
import { gridResponsiveClassName } from './common';
import { LinkImportCancelModal } from './components/LinkImportCancelModal';
import { useDefaultDashboardCreation } from './components/useDefaultDashboardCreation';
import { useWorkspaceLinking } from './components/useWorkspaceLinking';
import { ShowAll } from './ShowAll';
import { filterObjects } from '../../lib/filterObjects';

export const ALL_PLUGINS = 'allplugins';

const debouncedQueryTracking = debounce((query) => trackEvent('Plugin Searched For', { query }), 1000);

const firstCategories = ['One-click Data Sources'];

function categorySearch(a: string, b: string) {
    if (firstCategories.includes(a)) {
        return -1;
    }
    if (firstCategories.includes(b)) {
        return 1;
    }
    return a.localeCompare(b);
}

interface LinkPluginProps {
    workspaceId: string;
    modalPlugin?: PluginSourceConfig;
    setModalPlugin: (plugin?: PluginSourceConfig) => void;
}

function LinkPlugin({ workspaceId, setModalPlugin, modalPlugin }: LinkPluginProps) {
    const navigate = useNavigate();
    const { mutateAsync: createDashboards } = useDefaultDashboardCreation(true);
    const { linkConfigToWorkspace, invalidateAfterLinkingConfig } = useWorkspaceLinking(workspaceId);

    const linkPlugin = async (addDashboards: boolean) => {
        const invalidate = false; // Do afterwards so we don't block creation of dashboards.
        await linkConfigToWorkspace(modalPlugin?.id ?? '', invalidate);
        try {
            if (addDashboards) {
                await createDashboards({
                    configId: modalPlugin?.id ?? '',
                    pluginId: modalPlugin?.plugin?.pluginId ?? '',
                    workspaceId
                });
            } else {
                navigate(`/datasource/${modalPlugin?.id}`);
            }
        } finally {
            invalidateAfterLinkingConfig(); // Don't block with await - can happen async
        }
    };

    return (
        <>
            {modalPlugin && (
                <LinkImportCancelModal
                    dataSourceName={modalPlugin.displayName ?? ''}
                    sourcePluginId={modalPlugin?.plugin?.pluginId ?? ''}
                    onSubmit={linkPlugin}
                    onClose={() => setModalPlugin(undefined)}
                />
            )}
        </>
    );
}

const isInternal = (plugin: ProjectedPlugin | Serialised<ProjectedPlugin>) =>
    plugin.category !== 'SquaredUp Internal' ||
    Auth?.user?.name.endsWith('@squaredup.com') ||
    Auth?.user?.name.endsWith('@squaredup.cloud');

// Filters our existing and new plugins based on the search query and category filter
function useFilteredDataSources(
    dataSources: Serialised<ProjectedPlugin>[],
    existing: PluginSourceConfig[],
    categoryFilter: string,
    searchQuery: string
) {
    const filteredNewPlugins = useMemo(() => {
        const filteredInternalPlugins = dataSources
            .filter(isInternal)
            .map((p) => ({ ...p, type: p.onPrem ? 'on-premise' : 'cloud' }));

        return (
            (categoryFilter
                ? filteredInternalPlugins?.filter((plugin) => plugin.category === categoryFilter)
                : filteredInternalPlugins) || []
        );
    }, [categoryFilter, dataSources]);

    const filteredExistingPlugins = useMemo(() => {
        const filteredExisting = categoryFilter
            ? existing?.filter((plugin) => filteredNewPlugins?.find(({ id }) => id === plugin.plugin?.pluginId))
            : existing;

        return (
            filteredExisting?.map((pluginData) => ({
                plugin: filteredNewPlugins?.find(({ id }) => id === pluginData.plugin?.pluginId),
                pluginData
            })) || []
        );
    }, [categoryFilter, existing, filteredNewPlugins]);

    const filteredNew: Serialised<ProjectedPlugin>[] = useMemo(() => {
        if (searchQuery.length === 0) {
            return filteredNewPlugins.sort((a, b) => categorySearch(a.displayName || '', b.displayName || ''));
        }
        return filterObjects(filteredNewPlugins, searchQuery, [
            'displayName',
            'description',
            'author',
            'category',
            'keywords',
            'type'
        ]).sort((a, b) => categorySearch(a.displayName || '', b.displayName || ''));
    }, [searchQuery, filteredNewPlugins]);

    const filteredExisting = useMemo(() => {
        if (searchQuery.length === 0) {
            return filteredExistingPlugins.sort((a, b) =>
                (a.pluginData?.displayName || '').localeCompare(b.pluginData?.displayName || '')
            );
        }
        return filterObjects(filteredExistingPlugins, searchQuery, [
            'plugin.displayName',
            'pluginData.displayName',
            'plugin.description',
            'plugin.author',
            'plugin.category',
            'plugin.keywords',
            'plugin.type'
        ]).sort((a, b) => (a.pluginData?.displayName || '').localeCompare(b.pluginData?.displayName || ''));
    }, [searchQuery, filteredExistingPlugins]);

    const isFiltered = searchQuery || categoryFilter;

    return {
        isFiltered,
        filteredNew,
        filteredExisting
    };
}

interface PluginsProps {
    existing: PluginSourceConfig[];
    workspaceId?: string;
    empty: boolean;
    modal?: boolean;
    background?: string;
    onSelect?: (plugin: Serialised<ProjectedPlugin>) => void;
}

function getFilterText(categoryFilter: string, searchFilter: string) {
    if (categoryFilter && searchFilter) {
        return `Results for "${searchFilter}" in ${categoryFilter}`;
    }
    if (categoryFilter) {
        return `Results in ${categoryFilter}`;
    }
    if (searchFilter) {
        return `Results for "${searchFilter}"`;
    }
    return '';
}

export default function Plugins({ existing, workspaceId, empty, modal, background = 'bg-backgroundPrimary', onSelect }: PluginsProps) {
    const [selectedExisting, setSelectedExisting] = useState<PluginSourceConfig | undefined>();

    const topRef = useRef<HTMLDivElement>(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const searchQuery = searchParams.get('search') || '';
    const filterQuery = searchParams.get('filter') || '';

    const setSearchQuery = (query: string) => {
        setSearchParams({ search: query, filter: filterQuery });
        debouncedQueryTracking(query);
    };

    const setFilterQuery = (query: string) => {
        setSearchParams({ filter: query, search: searchQuery });
        topRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        trackEvent('Plugin Filtered For', { query });
    };

    const { isLoading, data: dataSources } = useQuery(ALL_PLUGINS, async () =>
        GetLatestMajorVersions().then((data) => data.sort((a, b) => a.displayName!.localeCompare(b.displayName!)))
    );

    const { isFiltered, filteredExisting, filteredNew } = useFilteredDataSources(
        dataSources || [],
        existing,
        filterQuery,
        searchQuery
    );

    // We need to extract the unique categories from the data sources
    const categories = useMemo(() => {
        const categorySet = new Set<string>();

        dataSources?.forEach((dataSource) => {
            if (isInternal(dataSource)) {
                categorySet.add(dataSource.category);
            }
        });

        return [...categorySet].sort(categorySearch);
    }, [dataSources]);

    return (
        <>
            {/** Used as an anchor to scroll to when we change filters */}
            <div ref={topRef} />
            <div className='relative flex'>
                {isLoading ? (
                    <div className='flex justify-center'>
                        <LoadingSpinner />
                    </div>
                ) : (
                    <>
                        {/** Filter sidebar */}
                        <aside className={cn('flex flex-col w-full max-w-[12rem] space-y-1 sticky top-24 self-start text-textSecondary', modal && 'top-0')}>
                            <h3 className='my-4 text-2xl font-semibold text-textPrimary'>Filters</h3>
                            <button
                                onClick={() => {
                                    setFilterQuery('');
                                }}
                                className={clsx(
                                    'hover:text-textPrimary text-left',
                                    !filterQuery && 'text-textPrimary font-bold'
                                )}
                            >
                                All
                            </button>
                            {categories?.map((category) => (
                                <button
                                    key={category}
                                    onClick={() => {
                                        setFilterQuery(category);
                                    }}
                                    className={clsx(
                                        'hover:text-textPrimary text-left truncate',
                                        filterQuery === category && 'text-textPrimary font-bold'
                                    )}
                                >
                                    {category}
                                </button>
                            ))}
                        </aside>

                        {/** Main content */}
                        <div className='relative w-full pb-24 ml-12'>
                            {/** Search bar */}
                            <div className={cn(
                                'sticky z-10 flex items-center justify-center w-full pl-2 top-24', 
                                modal && 'top-0',
                                background
                            )}>
                                <div className='relative w-full py-4'>
                                    <Form
                                        defaultValues={{
                                            searchDatasources: searchQuery
                                        }}
                                    >
                                        <Input
                                            autoFocus
                                            onChange={(e: any) => {
                                                setSearchQuery(e.target.value);
                                            }}
                                            name='searchDatasources'
                                            placeholder='Search data sources'
                                            prepend={
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                    fixedWidth
                                                    className='w-6 primaryButtonText shrink-0'
                                                />
                                            }
                                            autoComplete='off'
                                        />
                                    </Form>
                                </div>
                            </div>
                            <div className='z-0 flex flex-col pl-2 mt-4' aria-label='Data Source Grid'>
                                {/** Suggestions, show if no data sources available to the user or workspace */}
                                {!isFiltered && empty && (!existing || existing.length === 0) && (
                                    <SuggestedPluginsPage plugins={dataSources || []} onSelect={onSelect} />
                                )}

                                {/** Data sources not linked to workspace */}
                                {!isFiltered && existing && existing.length > 0 && (
                                    <div>
                                        <h4 className='pb-2 text-2xl font-semibold'>Data sources in my organization</h4>
                                        <ShowAll>
                                            {existing.map((plugin) => (
                                                <li className='h-24' key={plugin?.id.toString()}>
                                                    <ConfigCard
                                                        onClick={() => setSelectedExisting(plugin)}
                                                        plugin={plugin as any}
                                                    />
                                                </li>
                                            ))}
                                        </ShowAll>
                                    </div>
                                )}

                                {/** Data sources not linked to workspace */}
                                {(searchQuery.length > 0 || filterQuery) && (
                                    <h4 className='mb-6 text-2xl italic text-textSecondary'>
                                        {getFilterText(filterQuery, searchQuery)}
                                    </h4>
                                )}

                                {/** Searched / filtered data source not linked to workspace */}
                                {isFiltered && filteredExisting?.length > 0 && (
                                    <section>
                                        <h4 className='pb-2 text-2xl font-semibold'>Data sources in my organization</h4>
                                        <ol
                                            key={searchQuery}
                                            className={gridResponsiveClassName}
                                        >
                                            {filteredExisting?.map((dataSource) => {
                                                return (
                                                    <li className='h-24' key={dataSource.plugin?.id}>
                                                        <ConfigCard
                                                            onClick={() => setSelectedExisting(dataSource.pluginData)}
                                                            plugin={dataSource.pluginData as any}
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </ol>
                                    </section>
                                )}

                                {/** New data sources the user can add */}
                                {filteredNew.length > 0 && (
                                    <section className={cn('my-14', modal && 'mt-0')}>
                                        {(filteredExisting.length > 0 ||
                                            (!isFiltered && empty && (!existing || existing.length === 0))) && (
                                            <h4 className='pb-2 text-2xl font-semibold'>All data sources</h4>
                                        )}
                                        <ol className={gridResponsiveClassName}>
                                            {filteredNew?.map((plugin) => (
                                                <li className='h-36' key={plugin.id.toString()}>
                                                    <PluginCard plugin={plugin} onClick={onSelect} />
                                                </li>
                                            ))}
                                        </ol>
                                    </section>
                                )}
                                {/** The search/filter yields no results */}
                                {filteredExisting?.length === 0 && filteredNew?.length === 0 && (
                                    <>
                                        <div className='flex justify-center mb-6'>
                                            <p className='text-textIncomplete'>No data sources found</p>
                                        </div>
                                    </>
                                )}

                                <SuggestionFooter
                                    plugins={dataSources}
                                    onSelect={onSelect}
                                    excludedPlugins={filteredNew}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
            
            {workspaceId && (
                <LinkPlugin 
                    setModalPlugin={setSelectedExisting} 
                    modalPlugin={selectedExisting} 
                    workspaceId={workspaceId} 
                />
            )} 
        </>
    );
}
