import { buildQuery, Node } from '@squaredup/graph';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import stringify from 'fast-json-stable-stringify';
import { useInfiniteQuery } from 'react-query';
import { Query } from 'services/GraphService';
import { FilterQueryParameters } from '../../utilities/getObjectFilters';

interface useObjectFilterObjectsProps {
    scopeBaseQuery: string;
    queryParams: FilterQueryParameters;
    isFilterQueryReady: boolean;
}

export const OBJECTS_PER_PAGE = 50;

/**
 * Gets objects (object data) for a data stream based on filtered sources, types, properties, and pagination
 */
export const useObjectFilterObjects = ({
    scopeBaseQuery,
    queryParams,
    isFilterQueryReady
}: useObjectFilterObjectsProps) => {
    const { workspace, isGlobal } = useDataStreamWorkspaceContext();

    const {
        data: pagedData,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        isLoading,
        fetchNextPage
    } = useInfiniteQuery(
        ['pagedObjects', scopeBaseQuery, workspace, stringify(queryParams)], 
        {
            queryFn: async ({ pageParam = 0 }: { pageParam?: number }) => {
                const startOfRange = pageParam * OBJECTS_PER_PAGE;
                const endOfRange = (pageParam + 1) * OBJECTS_PER_PAGE;

                const objectsQuery =
                    scopeBaseQuery +
                        `.order()
                            .by("__configId")
                            .by("__search")
                            .valueMap(true)
                                .fold()
                        .as("objects", "count")
                        .select("objects", "count")
                            .by(range(local, ${startOfRange}, ${endOfRange}))
                            .by(count(local))`;

                const { gremlinQueryResults } = await Query(
                    buildQuery(queryParams, objectsQuery), 
                    isGlobal ? 'directOrAnyWorkspaceLinks' : undefined
                );

                const { objects, count } = gremlinQueryResults[0];

                return {
                    objects: objects as Node[],
                    count: count as number,
                    nextPage: pageParam + 1
                };
            },
            getNextPageParam: (lastPage) => (
                lastPage.objects.length >= OBJECTS_PER_PAGE ? lastPage.nextPage : undefined
            ),
            enabled: isFilterQueryReady,
            keepPreviousData: true,
            cacheTime: 120_000,
            staleTime: 120_000
        }
    );

    const flatResults = pagedData?.pages.flatMap((v) => v.objects);

    return {
        objects: flatResults,
        isFetchingObjects: isFetching,
        isFetchingNextObjectsPage: isFetchingNextPage,
        isLoadingObjects: isLoading,
        hasNextObjectsPage: hasNextPage,
        count: pagedData?.pages[0]?.count,
        fetchNextObjectsPage: fetchNextPage
    };
};
