import PropTypes from 'prop-types';
import { RegisterOptions } from 'react-hook-form';
import Input, { InputProps } from '../../input/Input';
import Field, { FormFieldProps } from '../Field';

type FieldInputProps = FormFieldProps &
    InputProps & {
        name: string;
        validation?: RegisterOptions;
    };

/**
 * Field.Input
 * Helper for creating an <Input> within a <Field>
 * Automatically sets required attributes and wires up the label to the input (for).
 *
 * @example
 * <Field.Input label='Name' name='name' validation={{ required: true, maxLength: {value: 5} }}
 * placeholder='e.g. Order Processing' autoFocus />`
 */
function FieldInput({
    name,
    label,
    description,
    help,
    validation,
    children,
    helpAlign,
    descriptionAbove,
    ...rest
}: FieldInputProps) {
    return (
        <Field
            label={label}
            description={description}
            required={Boolean(validation?.required)}
            help={help}
            helpAlign={helpAlign}
            descriptionAbove={descriptionAbove}
            htmlFor={name}
        >
            <>
                {children}
                <Input name={name} id={name} validation={validation} label={label} {...rest} />
            </>
        </Field>
    );
}

FieldInput.propTypes = {
    /**
     * Name of the input, used in output data.
     */
    name: PropTypes.string.isRequired,
    /**
     * Text to be displayed as field label
     */
    label: PropTypes.string,
    /**
     * Text to be displayed underneath input
     */
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     * Text to display as the tooltip
     */
    title: PropTypes.string,
    /**
     * Text to displayed as help icon tooltip
     */
    help: PropTypes.string,
    /**
     * Validation settings - see React Hook Form
     */
    validation: PropTypes.object,
    /**
     * Children to render before input
     */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

export default FieldInput;
