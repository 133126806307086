import { cn } from '@/lib/cn';
import useSize from '@react-hook/size';
import { stateStrings } from '@squaredup/monitoring';
import clsx from 'clsx';
import { TruncatedText } from 'components/TruncatedText';
import { clamp } from 'lodash';
import { useRef } from 'react';
import { BlockData } from '../types';
import { LinkWrapper } from 'components/LinkWrapper';
import { BlockSublabel } from './BlockSublabel';

const iconBackground = {
    success: 'bg-statusHealthySecondary',
    error: 'bg-statusErrorSecondary',
    warning: 'bg-statusWarningSecondary',
    unknown: 'bg-statusUnknownSecondary'
};

const blockBackground = {
    success: 'bg-statusHealthyPrimary',
    error: 'bg-statusErrorPrimary',
    warning: 'bg-statusWarningPrimary',
    unknown: 'bg-statusUnknownPrimary'
};

interface BlockProps {
    block: BlockData;
    fixedTitleSize?: number;
    fixedLabelSize?: number;
    fixedBarWidth?: number;
    fixedPaddingX?: number;
    fixedPaddingY?: number;
    limitHeight?: boolean;
    wrapLabels?: boolean;
}

export const Block: React.FC<BlockProps> = ({
    block,
    fixedTitleSize,
    fixedLabelSize,
    fixedBarWidth,
    fixedPaddingX,
    fixedPaddingY,
    limitHeight = true,
    wrapLabels = false
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [, containerHeight] = useSize(containerRef);

    const { name, state, link, sublabel, sublabelDetail } = block;

    const titleSize = fixedTitleSize ?? clamp(containerHeight * 0.15, 12, 31);
    const tableSize = fixedLabelSize ?? clamp(containerHeight * 0.1, 12, 18);
    const barWidth = fixedBarWidth ?? clamp(containerHeight * 0.4, 30, 60);
    const paddingX = fixedPaddingX ?? clamp(containerHeight * 0.1, 16, 24);
    const paddingY = fixedPaddingY ?? clamp(containerHeight * 0.1, 6, 24);

    return (
        <LinkWrapper link={link}>
            <div
                ref={containerRef}
                className={cn(
                    'relative flex h-full overflow-hidden font-semibold',
                    limitHeight && 'max-h-[310px]',
                    iconBackground[state || 'unknown'],
                    Boolean(link) && 'status-block-hover'
                )}
                data-testid='block'
                data-teststate={state}
                role='listitem'
            >
                <div
                    style={{ width: barWidth }}
                    className={clsx('shrink-0 flex justify-center', blockBackground[state])}
                />
                <div
                    style={{ padding: `${paddingY}px ${paddingX}px` }}
                    className={cn('flex flex-col flex-1 min-w-0', state === stateStrings.unknown && 'text-textPrimary')}
                >
                    <span style={{ fontSize: titleSize }} className='leading-normal'>
                        {wrapLabels ? (
                            <h6 className='overflow-hidden break-words text-ellipsis'>{name}</h6>
                        ) : (
                            <TruncatedText
                                title={name}
                                element='h6'
                                className={cn('block overflow-hidden font-semibold')}
                            />
                        )}
                    </span>
                    {sublabel && (
                        <BlockSublabel
                            sublabel={sublabel}
                            sublabelDetail={sublabelDetail ?? sublabel}
                            fontSize={tableSize}
                            wrapLabels={wrapLabels}
                        />
                    )}
                </div>
            </div>
        </LinkWrapper>
    );
};
