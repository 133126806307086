import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'components/LoadingSpinner';
import Tooltip from 'components/tooltip/Tooltip';
import { WorkspaceStateIndicator } from 'components/ui/state/WorkspaceStateIndicator';
import { useAppContext } from 'contexts/AppContext';
import useOverflowing from 'lib/useOverflowing';
import { useOrgTitle } from 'queries/hooks/useOrgTitle';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { NavLink } from 'react-router-dom';

export const WorkspaceDropdownLink: React.FC = () => {
    const { currentWorkspaceID } = useAppContext();
    const { data: { id, displayName } = {}, isLoading: isLoadingCurrentWorkspace } = useWorkspace(currentWorkspaceID);
    const { data: orgTitle, isLoading: isLoadingOrgTitle } = useOrgTitle();

    const [ref, overflowing] = useOverflowing<HTMLHeadingElement>();

    if (isLoadingCurrentWorkspace || isLoadingOrgTitle) {
        return <LoadingSpinner size={16} className='my-auto mr-auto ml-sm' />;
    }

    return (
        <Tooltip
            title={displayName ?? orgTitle}
            className='w-56 overflow-hidden grow'
            disabled={!overflowing}
        >
            <NavLink
                data-testid='nav-overview-btn'
                to={id ? `/workspace/${id}/overview` : '/status'}
                className='flex items-center h-full px-4 overflow-hidden text-base font-semibold transition outline-none cursor-pointer group ring-0 hover:text-textPrimary hover:bg-secondaryButtonBackground focus:bg-secondaryButtonBackground'
            >
                <span className='flex items-center overflow-hidden text-textPrimary'>
                    {id ? (
                        <WorkspaceStateIndicator
                            id={id}
                            className='flex-none ml-[1px]'
                            data-testid='current-workspace-health'
                        />
                    ) : (
                        <FontAwesomeIcon icon={faGlobe} fixedWidth size='lg' className='text-[16px]' />
                    )}
                    <Text.H4
                        className={cn('truncate', displayName ? 'ml-[7px]' : 'ml-[9px]')} 
                        ref={ref}
                    >
                        {displayName ?? orgTitle}
                    </Text.H4>
                </span>
            </NavLink>
        </Tooltip>
    );
};
