import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Editor, { Monaco } from '@monaco-editor/react';
import Color from 'color';
import { debounce } from 'lodash';
import { editor } from 'monaco-editor';
import { promLanguageDefinition } from 'monaco-promql';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export type CodeEditorArgs = {
    content: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    stretchToFitContent?: boolean;
    readOnly?: boolean;
    language?: string;
    heightConstraints?: { min: number; max?: number };
    contentValidator?: (content?: string) => string | boolean;
    onValidUpdatedContent?: Dispatch<SetStateAction<any>>; // eslint-disable-line @typescript-eslint/no-explicit-any
    /**
     * JSON schema for validation
     */
    schema?: object;
    theme?: object;
    handleError?: (hasError: boolean) => void;
    options?: editor.IStandaloneEditorConstructionOptions;
    onMount?: (monacoEditor: Monaco) => void;
    /**
     * Used to set the URI of the monaco editor model
     */
    modelPath?: string;
    [prop: string]: unknown;
};

const loading = <span>Loading editor...</span>;
const wrapperProps = { className: 'min-h-[inherit]' };

export const CodeEditor: React.FC<CodeEditorArgs> = ({
    content,
    schema,
    theme,
    readOnly,
    language = 'json',
    heightConstraints = { min: 200 },
    contentValidator,
    onValidUpdatedContent,
    handleError,
    onMount,
    modelPath,
    options
}) => {
    const editorBackground = Color(
        getComputedStyle(document.body).getPropertyValue('--componentBackgroundSecondary')
    ).hex();

    const [configError, setConfigError] = useState(false); // Errors in our schema
    const [languageError, setLanguageError] = useState(''); // Language errors

    const handleEditorWillMount = (monacoEditor: Monaco) => {
        if (schema && language === 'json') {
            monacoEditor.languages.json.jsonDefaults.setDiagnosticsOptions({
                validate: true,
                schemas: [
                    {
                        uri: 'https://app.squaredup.com/schema.json', // not used
                        fileMatch: ['*'],
                        schema
                    }
                ]
            });
        }

        if (language === 'promql') {
            const languageId = promLanguageDefinition.id;
            monacoEditor.languages.register(promLanguageDefinition);
            monacoEditor.languages.onLanguage(languageId, () => {
                promLanguageDefinition.loader().then((mod) => {
                    monacoEditor.languages.setMonarchTokensProvider(languageId, mod.language);
                    monacoEditor.languages.setLanguageConfiguration(languageId, mod.languageConfiguration);
                    monacoEditor.languages.registerCompletionItemProvider(languageId, mod.completionItemProvider);
                });
            });
        }

        monacoEditor.editor.defineTheme('squpTheme', {
            base: document.body.dataset.theme === 'dark' ? 'vs-dark' : 'vs',
            inherit: true,
            rules: [{ background: editorBackground } as { background: string; token: string }],
            colors: {
                'editor.background': editorBackground,
                'scrollbar.shadow': '#ffffff00'
            },
            ...(theme || {})
        });

        onMount?.(monacoEditor);
    };

    // Watch config/language errors and report up if there is a change
    useEffect(() => {
        handleError && handleError(Boolean(configError) || Boolean(languageError));
    }, [configError, languageError, handleError]);

    const handleContentChange = debounce((changedContent: string | undefined) => {
        if (languageError) {
            setConfigError(false);
        }

        // Attempt to parse the editorValue into an object:
        try {
            if (language !== 'json') {
                onValidUpdatedContent?.(changedContent);
                setConfigError(false);
                return;
            }

            const updatedContent = JSON.parse(changedContent || '');

            // Allow the editor consumer chance to validate the content:
            const contentErrors = contentValidator && contentValidator(updatedContent);
            if (contentErrors) {
                // @ts-ignore
                setConfigError(contentErrors);
                return;
            }

            setConfigError(false);
            onValidUpdatedContent?.(updatedContent);
            // eslint-disable-next-line no-empty
        } catch {
            // Ignore
        }
    }, 500);

    const handleEditorValidation = (markers: { message: string; endLineNumber: number }[]) => {
        if (markers.length > 0) {
            setLanguageError(`${markers[0].message} (Line ${markers[0].endLineNumber})`);
        } else {
            setLanguageError('');
        }
    };

    const { min: minHeight, max: maxHeight } = heightConstraints || {};

    return (
        <>
            <div
                className='relative flex-1 w-full h-full p-1 rounded '
                data-testid='jsonEditor'
                style={{
                    minHeight,
                    ...(maxHeight && { maxHeight: maxHeight })
                }}
            >
                <>
                    <Editor
                        language={language}
                        theme='squpTheme'
                        wrapperProps={wrapperProps}
                        loading={loading}
                        defaultValue={language === 'json' ? JSON.stringify(content, null, 2) : content}
                        onChange={handleContentChange}
                        onValidate={handleEditorValidation}
                        beforeMount={handleEditorWillMount}
                        path={modelPath ? encodeURIComponent(modelPath) : undefined}
                        options={{
                            scrollBeyondLastLine: false,
                            minimap: { enabled: false },
                            renderLineHighlight: 'none',
                            padding: { top: 10 },
                            automaticLayout: true,
                            readOnly: readOnly,
                            ...options
                        }}
                    />
                </>
            </div>
            {(configError || languageError) && (
                <div className='flex border-t-[1.5px] border-outlinePrimary py-2'>
                    <div>
                        <FontAwesomeIcon icon={faCircleExclamation} className='ml-2 mr-2 text-statusErrorPrimary' />
                    </div>
                    <div>
                        <span className='text-sm text-textSecondary'>{`Configuration error: ${
                            configError || languageError
                        }`}</span>
                    </div>
                </div>
            )}
        </>
    );
};
