import GenericRepo from './GenericRepo';

export interface Tile {
    tile: string;
    displayName: string;
    icon?: string | Array<string>;
    isHidden?: boolean;
}

const tileRepo = new GenericRepo<Tile>({
    'starwars-name-text': { tile: 'starwars-name-text', displayName: 'Starwars Name Text' },
    'starwars-manufacturer-text': { tile: 'starwars-manufacturer-text', displayName: 'Starwars Manufacturer Text' },
    'starwars-cost-text': { tile: 'starwars-cost-text', displayName: 'Starwars Cost Text' },
    'graph-nodes-text': { tile: 'graph-nodes-text', displayName: 'Graph Nodes Text', isHidden: true },
    'graph-node-text': { tile: 'graph-node-text', displayName: 'Graph Node Text', isHidden: true },
    'graph-node-as-blocks': { tile: 'graph-node-as-blocks', displayName: 'Graph Node As Blocks', icon: 'th' },
    'graph-node-as-table': { tile: 'graph-node-as-table', displayName: 'Graph Node As Table', icon: ['far', 'table'] },
    'graph-nodes-as-blocks': { tile: 'graph-nodes-as-blocks', displayName: 'Graph Nodes As Blocks', icon: 'shapes' },
    'graph-as-network': { tile: 'graph-as-network', displayName: 'Graph As Network', icon: ['far', 'chart-network'] },
    'workspaces-as-network': {
        tile: 'workspaces-as-network',
        displayName: 'Workspaces As Network',
        icon: ['far', 'chart-network']
    },
    'graph-custom-as-network': {
        tile: 'graph-custom-as-network',
        displayName: 'Graph Custom As Network',
        icon: ['far', 'chart-network']
    },
    iframe: { tile: 'iframe', displayName: 'Embed', icon: 'browser' },
    image: { tile: 'image', displayName: 'Image', icon: 'image' },
    text: { tile: 'text', displayName: 'Text', icon: 'align-left' },
    new: { tile: 'new', displayName: 'New', isHidden: true },
    custom: { isHidden: true, displayName: 'custom tile', tile: 'custom' },
    'data-stream': { tile: 'data-stream', displayName: 'Data Stream', icon: 'rocket-launch' },
    script: { tile: 'script', displayName: 'Script', icon: 'code' }
});

export default tileRepo;
