import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { flattenDashboardIdOrder } from 'queries/utils/dashboardSorted';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { DATA_STREAM_DEFINITIONS_FOR_WORKSPACE } from 'services/DataStreamDefinitionService';
import { CreateDefaultDashboards } from 'services/PluginService';
import { Workspace } from 'services/WorkspaceService';
import { useSetFolderOpen } from 'ui/nav/components/dashboards/useFoldersOpen';

export const useDefaultDashboardCreation = (navigateOnCreate: boolean = false) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const setFoldersOpen = useSetFolderOpen();

    return useMutation(
        ({ configId, pluginId, workspaceId }: { configId: string; pluginId: string; workspaceId: string }) =>
            CreateDefaultDashboards(configId, pluginId, workspaceId),
        {
            onSuccess: (dashboards, { workspaceId }) => {
                queryClient.invalidateQueries(dashboardQueryKeys.all);
                queryClient.invalidateQueries(workspaceQueryKeys.all);
                queryClient.removeQueries(scopeQueryKeys.all);
                queryClient.removeQueries([DATA_STREAM_DEFINITIONS_FOR_WORKSPACE]);

                const folder = dashboards[0]?.rootFolder;

                const spaces = queryClient.getQueryData(workspaceQueryKeys.list) as Workspace[] | undefined;

                queryClient.setQueryData(
                    workspaceQueryKeys.list,
                    spaces?.map((space) =>
                        space.id === workspaceId
                            ? ({
                                  ...space,
                                  data: {
                                      ...space.data,
                                      properties: {
                                          ...space.data.properties,
                                          dashboardIdOrder: [...(space.data.properties?.dashboardIdOrder || []), folder]
                                      }
                                  }
                              } as Workspace)
                            : space
                    )
                );

                if (navigateOnCreate && dashboards.length) {
                    const ids = flattenDashboardIdOrder(folder?.dashboardIdOrder || []);

                    const firstDashboard =
                        ids.find(({ id }) => dashboards.some((dash) => dash.id === id)) || dashboards[0];

                    navigate(`/dashboard/${firstDashboard.id}?force_open_folder=true`);
                } else if (folder) {
                    setFoldersOpen([{ itemId: folder.id, open: true }]);
                }
            }
        }
    );
};
