import { TruncatedText } from 'components/TruncatedText';
import { useAppContext } from 'contexts/AppContext';
import PluginIcon from 'pages/scope/PluginIcon';
import { useDatasourceConfigsForWorkspace } from 'queries/hooks/useDatasourceConfigsForWorkspace';
import { NavLink } from '../NavLink';
import { NavSection } from '../NavSection';
import { AddDatasourceButton } from '../datasources/AddDatasourceButton';

export const DatasourceSelect: React.FC = () => {
    const { currentWorkspaceID } = useAppContext();
    const { data: linkedDatasources = [], isLoading } = useDatasourceConfigsForWorkspace(
        currentWorkspaceID, { suspense: true }
    );

    return (
        <NavSection 
            title='Data Sources' 
            data-testid='navbar-menu-datasources'
            action={<AddDatasourceButton />}
        >
            {linkedDatasources.map((plugin) => (
                <NavLink to={`/datasource/${plugin.id}`} key={plugin.id}>
                    <PluginIcon
                        className='h-[16px] aspect-square mx-[6px] shrink-0'
                        pluginName={plugin.plugin?.name || ''}
                    />
                    <TruncatedText className='mr-auto truncate shrink pl-xxxs' title={plugin.displayName || ''}>
                        {plugin.displayName}
                    </TruncatedText>
                </NavLink>
            ))}
            
            {!isLoading && linkedDatasources.length === 0 && <p className='mr-2 pl-[31px]'>No data sources</p>}
        </NavSection>
    );
};
