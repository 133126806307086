import { useQuery, useQueryClient } from 'react-query';
import PluginIcon from 'pages/scope/PluginIcon';
import { DataSourceNodeCount, PER_DATA_SOURCE_OBJECTS_COUNT_KEY } from 'services/UsageService';
import { PluginSourceConfig } from 'services/SourceConfigService';
import useOverflowing from 'lib/useOverflowing';
import Tooltip from 'components/tooltip/Tooltip';
import { fiveMinutes } from 'queries/constants';
interface ConfigCardProps {
    plugin: PluginSourceConfig;
    onClick?: (plugin: PluginSourceConfig) => void;
}

export function ConfigCard({ plugin, onClick }: ConfigCardProps) {
    const { data } = useQuery(
        [...PER_DATA_SOURCE_OBJECTS_COUNT_KEY, plugin.id], 
        async () => {
            if (plugin.plugin?.importNotSupported === true) {
                return 0;
            } else {
                return DataSourceNodeCount([plugin.id]).then(([groupCountResult]) => groupCountResult?.count ?? 0);
            }
        },
        {
            select: (count: number) => {
                if (count === 0 && plugin.plugin?.importNotSupported === true) {
                    return ' '; // preserve alignment of data source names (by preserving <p> element below)
                } else if (count === 1) {
                    return '1 object';
                } else {
                    return `${count} objects`;
                }
            },
            staleTime: fiveMinutes,
            cacheTime: fiveMinutes,
            keepPreviousData: true
        }
    );

    const [ref, isOverflowing] = useOverflowing<HTMLHeadingElement>();

    return (
        <div
            onClick={() => onClick && onClick(plugin)}
            data-testid={plugin.id}
            className='relative flex h-full p-4 text-sm border cursor-pointer border-outlinePrimary rounded-input w-items-center group last:mt-0 bg-componentBackgroundSecondary hover:bg-tileBackground'
        >
            <div className='flex items-center min-w-0'>
                <div className='mr-3 w-14 h-14 shrink-0'>
                    <PluginIcon pluginName={plugin.plugin?.name || ''} />
                </div>
                <div className='overflow-hidden'>
                    <Tooltip title={plugin.displayName} disabled={!isOverflowing}>
                        <h3 ref={ref} className='mb-1 font-bold leading-4 truncate whitespace-nowrap'>
                            {plugin.displayName}
                        </h3>
                    </Tooltip>
                    {data && <p className='text-xs font-normal text-textSecondary'>{data}</p>}
                </div>
            </div>
        </div>
    );
}
