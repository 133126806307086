import { cn } from '@/lib/cn';
import { StateIndicator } from 'components/ui/state/StateIndicator';
import { capitalize, toString } from 'lodash';
import { CellProps } from './CellProps';
import { HealthState } from '@squaredup/monitoring';

export const StateCell: React.FC<CellProps> = ({ raw, value, style }) => {
    const rawString = toString(raw);

    const getRawValueNode = (state: string) =>
        rawString === state || (rawString ?? '') === '' ? <></> : <pre className='mt-2'>{rawString}</pre>;

    return (
        <div
            className={cn({
                'text-center': style.align === 'center',
                'text-left': style.align === 'left',
                'text-right': style.align === 'right'
            })}
        >
            <StateIndicator
                state={value as HealthState}
                titleOverride={(state) => (
                    <>
                        {capitalize(state)}
                        {getRawValueNode(state)}
                    </>
                )}
            />
        </div>
    );
};
