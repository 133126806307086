import { HealthState, healthStates, stateStrings } from '@squaredup/monitoring';
import { z } from 'zod';

export const isUnhealthy = (healthState?: HealthState) => {
    return [stateStrings.error, stateStrings.warning].some(unhealthyState => healthState === unhealthyState);
};

export const healthStatesEnum = z.enum(healthStates).catch(stateStrings.unknown);

export const healthStateTextColors: Record<HealthState, string> = {
    [stateStrings.error]: 'text-statusErrorPrimary',
    [stateStrings.warning]: 'text-statusWarningPrimary',
    [stateStrings.success]: 'text-statusHealthyPrimary',
    [stateStrings.unknown]: 'text-statusUnknownPrimary'
};

export const healthStateBGColors: Record<HealthState, string> = {
    [stateStrings.error]: 'bg-statusErrorPrimary',
    [stateStrings.warning]: 'bg-statusWarningPrimary',
    [stateStrings.success]: 'bg-statusHealthyPrimary',
    [stateStrings.unknown]: 'bg-statusUnknownPrimary'
};
