import type { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { DashboardId } from '@squaredup/ids';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import { Button } from 'components/button/Button';
import Field from 'components/forms/field/Field';
import Tooltip from 'components/tooltip/Tooltip';
import { ResultMessage } from 'pages/dashboard/DashboardCopyToModal';
import { FormProvider } from 'react-hook-form';
import { CREATE_NEW_DASHBOARD, useSaveToDashboardForm } from './hooks/useSaveToDashboardForm';

type SaveToDashboardModalProps = {
    config: DataStreamBaseTileConfig;
    currentDashboardId?: DashboardId['value'];
    workspace?: string;
    container?: HTMLElement | null;
    className?: string;
    title?: string;
    buttonText?: string;
    isGlobal?: boolean;
    close: () => void;
};

/**
 * Modal flow allowing a given tile config to be saved to a target workspace and dashboard
 * Defaults to current workspace and a new dashboard
 */
export const SaveToDashboardModal: React.FC<SaveToDashboardModalProps> = ({
    config,
    currentDashboardId,
    workspace,
    container,
    className,
    title,
    buttonText,
    isGlobal,
    close
}) => {    
    const {
        isLoading,
        isLoadingDashboards,
        isLoadingLinkedPluginConfigs,
        isSubmitting,
        isDisabled,
        isSaving,
        form,
        workspaces,
        selectedWorkspace,
        selectedWorkspaceDisplayName,
        dashboards,
        areDatasourcesLinked,
        areDatasourcesPermitted,
        formHandler
    } = useSaveToDashboardForm(config, currentDashboardId, workspace, isGlobal);

    const isWorkspaceScope = 'workspace' in (config.scope ?? {});

    if (config?.variables?.length) {
        return (
            <Modal title='Copy tile' close={close} fullWidth maxWidth='max-w-3xl'>
                <hr className='border-dividerPrimary' />
                <div className='px-8 py-4'>
                    <ResultMessage
                        state='error'
                        title='Unable to copy this tile'
                        body='Copying a tile that uses a dashboard variable is not supported at this time.'
                        className='mt-8'
                    />
                </div>
                <ModalButtons>
                    <Button type='button' variant='tertiary' onClick={() => close()}>
                        Close
                    </Button>
                </ModalButtons>
            </Modal>
        );
    }

    return (
        <Modal
            title={title ?? 'Copy tile'}
            close={close}
            fullWidth
            maxWidth='max-w-3xl'
            container={container}
            data-testid='copyToDialog'
            className={className}
        >
            {isLoading ? (
                <div className='flex justify-center pb-8 my-2'>
                    <LoadingSpinner />
                </div>
            ) : (
                <FormProvider {...form}>
                    <form onSubmit={formHandler}>
                        <hr className='border-dividerPrimary' />
                        <div className='px-8 py-4'>
                            <Tooltip 
                                title='This tile can only be copied to the current workspace as it uses a workspace collection'
                                disabled={!isWorkspaceScope}
                            >
                                <Field.Input
                                    name='targetWorkspace'
                                    type='autocomplete'
                                    label='Destination workspace'
                                    placeholder='Search or select a workspace'
                                    options={workspaces}
                                    isMulti={false}
                                    validation={{ required: true }}
                                    isDisabled={isWorkspaceScope || isLoading || isSubmitting}
                                    noOptionsMessage={() => 'No workspaces available.'}
                                    selectOptionsAs={'valueString'}
                                />
                            </Tooltip>

                            <Field.Input
                                name='targetDashboard'
                                type='autocomplete'
                                label='Destination dashboard'
                                placeholder='Search or select a dashboard'
                                options={[
                                    {
                                        label: 'New',
                                        options: [
                                            {
                                                label: 'Create new dashboard',
                                                value: CREATE_NEW_DASHBOARD,
                                                isNew: true
                                            }
                                        ]
                                    },
                                    {
                                        label: 'Existing',
                                        options: dashboards
                                    }
                                ]}
                                isMulti={false}
                                validation={{ required: true }}
                                isDisabled={isLoading || isSubmitting || isLoadingDashboards || !selectedWorkspace}
                                noOptionsMessage={() => 'No dashboards available.'}
                                selectOptionsAs={'valueString'}
                            />
                            {selectedWorkspace &&
                                !isLoadingLinkedPluginConfigs &&
                                !areDatasourcesLinked &&
                                !areDatasourcesPermitted && (
                                    <ResultMessage
                                        state='error'
                                        title={`Unable to copy this tile to “${selectedWorkspaceDisplayName}” workspace`}
                                        body={
                                            'Your account does not have enough rights to add the data source used by this tile to the selected workspace. Please contact your administrator.'
                                        }
                                        className='mt-8'
                                    />
                                )}
                        </div>

                        <ModalButtons>
                            <Button type='button' variant='tertiary' onClick={() => close()}>
                                Cancel
                            </Button>
                            <Button type='submit' disabled={isDisabled} data-testid='confirmCopyTo'>
                                {isSaving ? 
                                    <LoadingSpinner size={18} /> : 
                                    buttonText ?? 'Copy'
                                }
                            </Button>
                        </ModalButtons>
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};
