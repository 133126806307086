import { DashboardIdRegex } from '@squaredup/ids';
import { stateStrings } from '@squaredup/monitoring';
import { DashboardWithHealth } from 'deprecated/helpers';
import { oneMinute } from 'queries/constants';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { useQuery, UseQueryOptions } from 'react-query';
import { DashboardsHealth, ListDashboardHealthByIds } from 'services/HealthService';
import { useDashboards } from './useDashboards';

export const useDashboardsWithHealth = <T>({
    select,
    enabled,
    ...options
}: Omit<UseQueryOptions<DashboardsHealth, unknown, T, string[]>, 'select'> & {
    select?: (data: DashboardWithHealth[]) => T;
} = {}) => {
    const dashboards = useDashboards({ select: (data) => data.filter(({ id }) => id.match(DashboardIdRegex)) });

    const query = useQuery(
        dashboardQueryKeys.state(dashboards.data?.map(({ id }) => id)),
        async () => ListDashboardHealthByIds(dashboards.data?.map(({ id }) => id) ?? []),
        {
            enabled: !dashboards.isLoading && Boolean(enabled ?? true),
            staleTime: oneMinute,
            cacheTime: oneMinute,
            refetchInterval: oneMinute,
            keepPreviousData: true,
            select: (data) => {
                const dashboardHealthLookup = new Map(data.dashboardStates?.map((d) => [d.dashboardId, d.state]));

                const withHealth =
                    dashboards.data?.map((dashboard) => ({
                        ...dashboard,
                        link: `/dashboard/${dashboard.id}`,
                        state: dashboardHealthLookup.get(dashboard.id) ?? stateStrings.unknown
                    })) ?? [];

                return select ? select(withHealth) : (withHealth as T);
            },
            ...options
        }
    );

    return { ...query, isLoading: query.isLoading || dashboards.isLoading };
};
