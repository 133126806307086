import { Toggle } from '@/components/forms/Toggle';
import { SortValue } from './StatusOverview';

export interface WorkspaceSort {
    value: SortValue;
    direction: 'asc' | 'desc';
}

export interface DownstreamToggleProps {
    checked: boolean;
    onChecked: (checked: boolean) => void;
}

export default function DownstreamToggle({ checked, onChecked }: DownstreamToggleProps) {
    return (
        <label className='flex items-center space-x-4'>
            <span>Show downstream dependencies</span>
            <Toggle className='grow' checked={checked} onCheckedChange={() => onChecked(!checked)} />
        </label>
    );
}
