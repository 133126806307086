import { cn } from '@/lib/cn';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TimeframeEnumValue, getTimeframeLabel, timeframeNames } from '@squaredup/timeframes';
import DropdownMenu from 'components/dropdownMenu';
import Tooltip from 'components/tooltip/Tooltip';
import DashboardContext from 'contexts/DashboardContext';
import { groupBy } from 'lodash';
import { useContext } from 'react';

type ConditionalTimeframeProps =
    | {
          allowNullSelection?: false;
          onChange: (timeframe: TimeframeEnumValue) => void;
      }
    | {
          allowNullSelection?: true;
          onChange: (timeframe: TimeframeEnumValue | null) => void;
      };

type CommonTimeframeProps = {
    currentTimeframe?: TimeframeEnumValue;
    disabled?: boolean;
    forceSelection?: boolean;
    forceSelectionKPI?: boolean;
    buttonClassName?: string;
    alignment?: 'end' | 'start' | 'center';
    title?: string;
    supportedTimeframes?: boolean | string[];
    container?: HTMLElement | null;
    modal?: boolean;
};

type TimeframeProps = ConditionalTimeframeProps & CommonTimeframeProps;

const TimeframeChooser: React.FC<TimeframeProps> = ({
    currentTimeframe,
    allowNullSelection,
    disabled = false,
    forceSelection = false,
    forceSelectionKPI = false,
    alignment = 'end',
    title = 'Current dashboard timeframe',
    supportedTimeframes = true,
    container,
    modal = true,
    onChange
}) => {
    const { timeframe: inheritedTimeframe } = useContext(DashboardContext);

    const groupedTimeframes = groupBy(timeframeNames, 'group');
    const timeframeLabel = currentTimeframe
        ? getTimeframeLabel(currentTimeframe, 'nonrelative')
        : `Use dashboard timeframe (${getTimeframeLabel(inheritedTimeframe, 'nonrelative')})`;

    return (
        <DropdownMenu modal={modal}>
            <DropdownMenu.Button
                icon={<FontAwesomeIcon data-testid='icon' icon={faClock} />}
                disabled={disabled}
                data-testid='timeframeChooser'
                className='data-[state=open]:bg-secondaryButtonBackgroundHover'
                title={title}
            >
                {timeframeLabel}
            </DropdownMenu.Button>

            <DropdownMenu.Menu
                align={alignment}
                className='w-[320px] bg-backgroundPrimary'
                container={container}
            >
                {allowNullSelection && (
                    <DropdownMenu.CheckItem
                        onSelect={() => onChange(null)}
                        key='defaultTimeframe'
                        checked={!currentTimeframe}
                    >
                        Use dashboard timeframe ({getTimeframeLabel(inheritedTimeframe, 'nonrelative')})
                    </DropdownMenu.CheckItem>
                )}

                {(forceSelection || forceSelectionKPI) && (
                    <>
                        <DropdownMenu.CheckItem disabled={true}>
                            Use dashboard timeframe
                            <div key='timeframeMessage' className='pt-1 text-xs text-textDisabled'>
                                {forceSelection
                                    ? 'Unavailable: Monitoring is enabled.'
                                    : 'Unavailable: KPI is enabled.'}
                            </div>
                        </DropdownMenu.CheckItem>
                    </>
                )}

                {(allowNullSelection || forceSelection || forceSelectionKPI) && <DropdownMenu.Separator />}

                {Object.entries(groupedTimeframes).map(([timeframeGroup, timeframes], index) => {
                    return (
                        <DropdownMenu.Group key={timeframeGroup}>
                            <p className='font-semibold px-xs py-xxxs text-textSecondary'>{timeframeGroup}</p>

                            {timeframes.map((timeframeInstance) => {
                                const { name: timeframe, label, getResolvedTimeRange } = timeframeInstance;
                                const isActive = currentTimeframe === timeframe;

                                const isSupportedTimeframe = Array.isArray(supportedTimeframes)
                                    ? supportedTimeframes?.includes(timeframe)
                                    : true;

                                return (
                                    <Tooltip
                                        title='Timeframe not available for this data stream.'
                                        placement='right'
                                        disabled={isSupportedTimeframe}
                                        key={timeframe}
                                    >
                                        <DropdownMenu.Item
                                            disabled={!isSupportedTimeframe}
                                            onSelect={() => onChange(timeframe as TimeframeEnumValue)}
                                            className={cn(
                                                'flex justify-between',
                                                isActive && 'bg-dividerPrimary font-semibold'
                                            )}
                                        >
                                            <span data-testid={timeframe} className='block mr-6 whitespace-nowrap'>
                                                {label}
                                            </span>

                                            <span
                                                data-testid={`resolvedTimeframe-${timeframe}`}
                                                className='block font-normal whitespace-nowrap text-textSecondary'
                                            >
                                                {getResolvedTimeRange()}
                                            </span>
                                        </DropdownMenu.Item>
                                    </Tooltip>
                                );
                            })}

                            {index !== Object.keys(groupedTimeframes).length - 1 && <DropdownMenu.Separator />}
                        </DropdownMenu.Group>
                    );
                })}
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
};

export default TimeframeChooser;
