import type { OrganisationHomeConfig } from 'dynamo-wrapper';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GetTenantUserProperties, SetUserForTenantProperty } from 'services/UserService';

export const STATUS_CONFIG = 'STATUS_CONFIG';

export const defaultGlobalViewConfig: OrganisationHomeConfig = {
    filter: [],
    sort: {
        value: 'state',
        direction: 'desc'
    },
    type: 'space',
    tags: [],
    kpiTypeIds: [],
    workspaceTypes: [],
    viewSettings: {
        avatar: true,
        type: true,
        tags: true,
        sublabel: true,
        dataSources: true,
        kpis: true,
        state: true,
        columnCount: 3
    }
};

const convertOldViewOptions = (config: OrganisationHomeConfig) => {
    // Converts the old views object to the new viewSettings object
    // This is done to maintain backwards compatibility
    // At some point in the future we can probably remove this code

    if (!config.views || Boolean(config.viewSettings)) {
        return;
    }

    const viewSettings: Record<string, boolean | number> = {};
    for (const view of config.views['tile']['space']) {
        if (view.type === 'separator') {
            continue;
        }
        viewSettings[view.name] = view.value;
    }

    config.viewSettings = viewSettings as OrganisationHomeConfig['viewSettings'];
};

export const useStatusConfig = () =>
    useQuery(
        [STATUS_CONFIG],
        ({ signal }) =>
            GetTenantUserProperties(signal).then((data) => data?.organisationHomeConfig ?? defaultGlobalViewConfig),
        {
            select: (config) => {
                if (config?.views) {
                    convertOldViewOptions(config);
                    delete config.views;
                }
                return {
                    ...defaultGlobalViewConfig,
                    ...config,
                    viewSettings: { ...defaultGlobalViewConfig.viewSettings, ...config.viewSettings }
                };
            },
            staleTime: Infinity,
            cacheTime: Infinity
        }
    );

export const useSaveStatusConfig = () => {
    const queryClient = useQueryClient();
    return useMutation((config: OrganisationHomeConfig) => SetUserForTenantProperty('organisationHomeConfig', config), {
        async onMutate(config) {
            await queryClient.cancelQueries([STATUS_CONFIG]);

            const previousConfig = queryClient.getQueryData([STATUS_CONFIG]) as OrganisationHomeConfig | undefined;

            queryClient.setQueryData([STATUS_CONFIG], config);

            return { previousConfig };
        },
        onError(_err, _config, context) {
            queryClient.setQueryData([STATUS_CONFIG], context?.previousConfig);
        },
        onSettled() {
            queryClient.invalidateQueries([STATUS_CONFIG]);
        }
    });
};
