import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { hasProperty } from '@squaredup/utilities';
import { isDefaultValid, isRequiredValid } from 'components/forms/jsonForms/DisplayJsonUi';
import { getIsDataStreamConfigured } from 'dashboard-engine/util/getIsDataStreamConfigured';
import { useDataStreamDefinitionsForWorkspace } from 'services/DataStreamDefinitionService';

const getDataStreamConfigErrorString = (dataStream: DataStreamBaseTileConfig['dataStream']): string => {
    if (dataStream == null || Object.keys(dataStream).length === 0) {
        return 'No data stream configured';
    }

    if ('id' in dataStream) {
        return 'The specified data stream was not found.';
    }

    if ('name' in dataStream) {
        return `No data stream named ${dataStream?.name}`;
    }

    return `No data stream for config: ${JSON.stringify(dataStream)}`;
};

export const useDataStreamFromTileConfig = (config: DataStreamBaseTileConfig) => {
    const {
        data: streamDefinitions = [],
        isLoading: streamDefinitionLoading,
        isFetched: streamDefinitionLoaded,
        error: streamDefinitionError
    } = useDataStreamDefinitionsForWorkspace();
    const dataStreamConfig = config.dataStream ?? {};

    let dataStreamDefinition;

    if (hasProperty(dataStreamConfig, 'id')) {
        dataStreamDefinition = streamDefinitions.find((d) => d.id === dataStreamConfig?.id);
    }

    if (!dataStreamDefinition && hasProperty(dataStreamConfig, 'name')) {
        dataStreamDefinition = streamDefinitions.find((d) => d.definition.name === dataStreamConfig?.name);
    }

    const errorMessage =
        streamDefinitionLoaded && dataStreamDefinition == null
            ? new Error(getDataStreamConfigErrorString(config?.dataStream))
            : undefined;

    const isConfigured = getIsDataStreamConfigured(dataStreamDefinition, config);
    const hasRequiredFields =
        Boolean(dataStreamDefinition) &&
        ((Boolean(dataStreamConfig.dataSourceConfig) &&
            isRequiredValid(dataStreamConfig.dataSourceConfig, dataStreamDefinition?.template)) ||
            isDefaultValid(dataStreamDefinition?.template));

    // This is only true if all required fields have a value.
    // TODO: make isConfigured strict by default when all datastream's required fields are actually required
    const isConfiguredStrict = isConfigured && hasRequiredFields;

    return {
        isConfigured,
        isConfiguredStrict,
        errorMessage,
        dataStreamDefinition,
        streamDefinitionLoading,
        streamDefinitionError
    };
};
