import { OverviewColumn } from './DisplayComponents';
import { usePlugin } from 'pages/settings/plugins/components/usePlugin';
import { OverviewDataStreamDetails } from './OverviewDataStreamDetails';
import { OverviewObjectDetails } from './OverviewObjectDetails';
import { useOverviewDataStreams } from './useOverviewDataStreams';

export const OverviewData = ({
    configId,
    pluginId,
    importStatus
}: {
    configId: string;
    pluginId: string;
    importStatus: string;
}) => {
    const { data: plugin, isLoading: loadingPlugin } = usePlugin(pluginId);
    const { data: dataStreams, isLoading: isLoadingDataStreams } = useOverviewDataStreams(pluginId);

    if (loadingPlugin || isLoadingDataStreams) {
        return <></>; // Should be short, don't need spinner
    }

    return (
        <div className='flex flex-1 w-full min-h-0 gap-2.5 pb-4'>
            {!plugin?.importNotSupported && (<OverviewColumn key={configId}>
                <OverviewObjectDetails configId={configId} importStatus={importStatus} />
            </OverviewColumn>)}
            {dataStreams.length > 0 && (<OverviewColumn key={pluginId}>
                <OverviewDataStreamDetails dataStreamDetails={dataStreams} />
            </OverviewColumn>)}
        </div>
    );
};
