import {
    CommonShapeConfig,
    Shape,
    ShapeName,
    boolean,
    currency,
    dataUnitShapes,
    date,
    getShape,
    getValueShapeOf,
    guid,
    json,
    milliseconds,
    minutes,
    number,
    percent,
    seconds,
    state,
    string,
    toShapeName,
    url
} from '@squaredup/data-streams';
import { hasProperty, isDefined } from '@squaredup/utilities';
import { CurrencyShapeConfigFields } from './ShapeConfigFields/CurrencyShapeConfigFields';
import { DateShapeConfigFields } from './ShapeConfigFields/DateShapeConfigFields';
import { NumberShapeConfigFields } from './ShapeConfigFields/NumberShapeConfigFIelds';
import type { ShapeConfigFieldsComponent } from './ShapeConfigFields/ShapeConfigFields';
import { StateShapeConfigFields } from './ShapeConfigFields/StateShapeConfigFields';
import { TimeShapeConfigFields } from './ShapeConfigFields/TimeShapeConfigFields';
import { UrlShapeConfigFields } from './ShapeConfigFields/UrlShapeConfigFields';

type MetadataEditorShape<
    T = unknown,
    C extends CommonShapeConfig = CommonShapeConfig,
    VC extends CommonShapeConfig = CommonShapeConfig
> = Shape<T, C, VC> & {
    shapeConfigFields: ShapeConfigFieldsComponent | undefined;
    /**
     * Values to apply when resetting the form and no override is set.
     *
     * This only needs to be set when the value the form uses to represent
     * 'no value' is not `undefined`. For example, number inputs need an
     * empty string set, otherwise they default to zero,
     */
    defaultFormValues?: Record<string, unknown>;
    /**
     * Customises how the defaults are merged with the configured overrides.
     * If this is not set the overrides are shallow-copied over the top of the defaults,
     * i.e. `{ ...defaults, ...overrides }`
     */
    mergeFormValues?(
        defaultFormValues: Record<string, unknown>,
        overrides: Record<string, unknown>
    ): Record<string, unknown>;
};

const editorShape = <
    T = unknown,
    C extends CommonShapeConfig = CommonShapeConfig,
    VC extends CommonShapeConfig = CommonShapeConfig
>(
    baseShape: Shape<T, C, VC>,
    extensions: {
        shapeConfigFields: ShapeConfigFieldsComponent | undefined;
        defaultFormValues?: Record<string, unknown>;
        mergeFormValues?(
            defaultFormValues: Record<string, unknown>,
            overrides: Record<string, unknown>
        ): Record<string, unknown>;
    }
): MetadataEditorShape<T, C, VC> => {
    return {
        ...baseShape,
        ...extensions
    };
};

/**
 * Shapes enabled for use in the metadata editor.
 * Some shapes are deliberately excluded from this list as they
 * don't make sense in the editor, e.g. 'unknown' or 'nullish'.
 *
 * The order of the shapes in their groups is the order that they appear in the UI.
 */
export const metadataEditorShapes: { groupName: string; shapes: MetadataEditorShape<any, any, any>[] }[] = [
    {
        groupName: 'Basic',
        shapes: [
            editorShape(string, {
                shapeConfigFields: undefined
            }),
            editorShape(number, {
                defaultFormValues: { decimalPlaces: 2 },
                shapeConfigFields: NumberShapeConfigFields
            }),
            editorShape(date, {
                shapeConfigFields: DateShapeConfigFields
            }),
            editorShape(boolean, {
                shapeConfigFields: undefined
            }),
            // Data shapes inherit their value shape's config fields, so we don't
            // need to include the decimal places field on shapes based on numbers.
            editorShape(percent, {
                shapeConfigFields: undefined
            }),
            editorShape(currency, {
                defaultFormValues: { code: null },
                shapeConfigFields: CurrencyShapeConfigFields
            })
        ]
    },
    {
        groupName: 'Data',
        shapes: dataUnitShapes.map((s) =>
            editorShape(s, {
                shapeConfigFields: undefined
            })
        )
    },
    {
        groupName: 'Data Rate',
        shapes: (['shape_bitspersecondmetric', 'shape_bytesperseconddecimal'] as ShapeName[])
            .map((shapeName) => getShape(shapeName))
            .filter(isDefined)
            .map((s) => editorShape(s, { shapeConfigFields: undefined }))
    },
    {
        groupName: 'Data Rate (Binary)',
        shapes: (['shape_bitspersecondbinary', 'shape_bytespersecondbinary'] as ShapeName[])
            .map((shapeName) => getShape(shapeName))
            .filter(isDefined)
            .map((s) => editorShape(s, { shapeConfigFields: undefined }))
    },
    {
        groupName: 'Health',
        shapes: [
            editorShape(state, {
                defaultFormValues: {
                    map: {
                        success: [],
                        warning: [],
                        error: [],
                        unknown: []
                    }
                },
                /* We need this custom merge function to merge the values
                 * within the `map` property as by default we only do a shallow merge
                 * and we want to always have a value for each of the properties inside `map`
                 */
                mergeFormValues: (defaults, overrides) => {
                    if (
                        hasProperty(defaults, 'map') &&
                        typeof defaults.map === 'object' &&
                        hasProperty(overrides, 'map') &&
                        typeof overrides.map === 'object'
                    ) {
                        return { ...defaults, ...overrides, map: { ...defaults.map, ...overrides?.map } };
                    }

                    return { ...defaults, ...overrides };
                },
                shapeConfigFields: StateShapeConfigFields
            })
        ]
    },
    {
        groupName: 'Utility',
        shapes: [
            editorShape(guid, {
                shapeConfigFields: undefined
            }),
            editorShape(json, {
                shapeConfigFields: undefined
            }),
            editorShape(url, {
                shapeConfigFields: UrlShapeConfigFields
            })
        ]
    },
    {
        groupName: 'Time',
        shapes: [
            ...[milliseconds, seconds, minutes].map((s) =>
                editorShape(s, {
                    defaultFormValues: { formatDuration: s === milliseconds ? false : true },
                    shapeConfigFields: TimeShapeConfigFields
                })
            )
        ]
    }
];

/**
 * Shapes enabled for use in the metadata editor.
 * Some shapes are deliberately excluded from this list as they
 * don't make sense in the editor, e.g. 'unknown' or 'nullish'
 */
export const metadataEditorShapesByName = new Map(
    metadataEditorShapes.flatMap((g) => g.shapes).map((s) => [s.name, s as MetadataEditorShape])
);

/**
 * Determine if the given shape name belongs to a shape the metadata editor can work with.
 */
export const isMetadataEditorShape = (shapeName: string) => metadataEditorShapesByName.has(toShapeName(shapeName));

export const hasShapeConfigFields = (shapeName: string): boolean => {
    const shape = getShape(toShapeName(shapeName));

    if (shape == null) {
        return false;
    }

    if (metadataEditorShapesByName.get(shape.name)?.shapeConfigFields != null) {
        return true;
    }

    const valueShape = shape?.isPrimitive ? undefined : getValueShapeOf(shape);

    if (valueShape == null) {
        return false;
    }

    return metadataEditorShapesByName.get(valueShape.name)?.shapeConfigFields != null;
};
