import NoDataPlaceholder from 'components/NoDataPlaceholder';
import { clamp } from 'lodash';
import { DataStreamVisualisation } from '../../types/Visualisation';
import { DataStreamBlocksConfig } from './Config';
import { Blocks } from './components/Blocks';
import { getRawBlocksData } from './dataUtils';

const DataStreamBlocks: DataStreamVisualisation<DataStreamBlocksConfig> = ({ data, config }) => {
    const maxColumns = config.columns || 3;
    const blockHeight = config.blockHeight;
    const limitHeight = config.limitHeight ?? true;
    const wrapLabels = config.wrapLabels ?? false;

    const blocksData = getRawBlocksData(data, config)?.filter(Boolean);

    const resolvedColumns = Array.isArray(blocksData) ? clamp(blocksData.length, 1, maxColumns) : maxColumns;

    // We don't want to sort automatically
    if (!config?.orderBy) {
        config.orderBy = ' ';
    }

    return (
        <div className='flex flex-col w-full h-full' data-visualization='data-stream-blocks'>
            <div className='flex-1 tile-scroll-overflow'>
                {blocksData ? (
                    <Blocks
                        blocks={blocksData}
                        columns={resolvedColumns}
                        blockHeight={blockHeight}
                        limitHeight={limitHeight}
                        wrapLabels={wrapLabels}
                    />
                ) : (
                    <NoDataPlaceholder />
                )}
            </div>
        </div>
    );
};

DataStreamBlocks.config = 'DataStreamBlocksConfig';

export default DataStreamBlocks;
