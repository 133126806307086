import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { TimeframeEnumValue, getTimeframeLabel, timeframeNames } from '@squaredup/timeframes';
import Tooltip from 'components/tooltip/Tooltip';

interface TileTimeframeProps {
    config: DataStreamBaseTileConfig;
}

interface TimeframePillProps {
    label: string;
    tooltipTitle: string;
}

const TimeframePill: React.FC<TimeframePillProps> = ({ label, tooltipTitle }) => (
    <div data-testid='timeframePill' className='bg-outlinePrimary rounded py-0.5 px-2'>
        <Tooltip title={tooltipTitle} placement='bottom-start'>
            <p className='text-xs font-semibold text-textSecondary whitespace-nowrap'>{label}</p>
        </Tooltip>
    </div>
);

const getTimeframeLabelWithPrefix = (timeframe: TimeframeEnumValue) => {
    const { label: timeframeName, group } = timeframeNames.find(({ name }) => timeframe === name) || { label: '' };

    return `${group === 'Relative' || group === 'Last' ? 'last' : 'current'} ${timeframeName.toLowerCase()}`;
};

export const TileTimeframe: React.FC<TileTimeframeProps> = ({ config }) => {
    if (config.dataStream?.id === 'datastream-sql') {
        const hasDashboardTimeframe = config.dataStream?.dataSourceConfig?.tables?.some((t) => !t?.config?.timeframe);
        if (hasDashboardTimeframe) {
            return null;
        }

        const timeframesSet = config.dataStream?.dataSourceConfig?.tables?.reduce(
            (acc, cur) => acc.add(cur?.config?.timeframe as TimeframeEnumValue),
            new Set<TimeframeEnumValue>()
        );

        if (!timeframesSet) {
            return null;
        }

        const timeframes = [...timeframesSet];

        if (timeframes.length === 1) {
            const timeframeLabel = getTimeframeLabel(timeframes[0], 'nonrelative');
            const timeframeLabelWithPrefix = getTimeframeLabelWithPrefix(timeframes[0]);
            return (
                <TimeframePill 
                    label={timeframeLabel} 
                    tooltipTitle={`Fixed timeframe: ${timeframeLabelWithPrefix}`} 
                />
            );
        } else {
            const timeframeLabels = timeframes.map((t) => getTimeframeLabelWithPrefix(t));

            return (
                <TimeframePill
                    label='Fixed'
                    tooltipTitle={`Multiple fixed timeframes: ${timeframeLabels.join(', ')}`}
                />
            );
        }
    }

    if (!config.timeframe) {
        return null;
    }

    const timeframeLabel = getTimeframeLabel(config.timeframe, 'nonrelative');
    const timeframeLabelWithPrefix = getTimeframeLabelWithPrefix(config.timeframe);

    return <TimeframePill label={timeframeLabel} tooltipTitle={`Fixed timeframe: ${timeframeLabelWithPrefix}`} />;
};
